import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { IscontrolComponent } from './../iscontrol.component';
import { FormControl } from '@angular/forms';
import {
  ComponentsService,
  Outlet2Service,
  UpdateProperty,
} from '@ic-builder/is-base';
import { Subscription } from 'rxjs';
import { Store } from '@ngxs/store';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'isiconoption',
  templateUrl: './isiconoption.component.html',
  styleUrls: ['./isiconoption.component.scss'],
})
export class IsiconoptionComponent
  extends IscontrolComponent
  implements OnInit, OnDestroy
{
  @Input() label: string;
  @Input() icons: any[];
  @Input() initialValue: string;

  private subscriptions: Array<Subscription> = [];

  controlid: number;
  formControl: FormControl;

  constructor(
    comp: ComponentsService,
    public outlet: Outlet2Service,
    private compserv: ComponentsService,
    public store: Store,
    public NgZone: NgZone,
    public dialog: MatDialog,
    public el: ElementRef,
    public cd: ChangeDetectorRef
  ) {
    super(comp, store, outlet, NgZone, dialog, el, cd);
  }

  ngOnInit(): void {
    if (!this.controlid) {
      this.controlid = this.outlet.uniqueId;
      this.store.dispatch(
        new UpdateProperty({
          compid: this.id,
          path: 'config.controlid',
          value: this.controlid,
        })
      );
    }

    this.formControl = new FormControl(this.initialValue);
    this.compserv.formcontrols.set(this.controlid.toString(), {
      name: this.controlname,
      formcontrol: this.formControl,
    });
    // super.addControls([this.controlname], null)

    this.subscriptions.push(
      this.formControl.valueChanges.subscribe((a) => {
        this.cd.detectChanges();
      })
    );
  }

  ngOnDestroy() {
    // super.destroyControls([this.controlname])
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}
