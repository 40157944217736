import { ComponentsService } from '@ic-builder/is-base';
import { IsGridRegistry } from './../../isgrid.service';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ischeckboxcel',
  templateUrl: './ischeckboxcel.component.html',
  styleUrls: ['./ischeckboxcel.component.scss'],
})
export class IscheckboxcelComponent implements AfterViewInit {
  constructor(
    private el: ElementRef,
    private grid: IsGridRegistry,
    private comp: ComponentsService,
    private cd: ChangeDetectorRef
  ) {}

  //checkedVal = true;

  gridid: number;
  rowId: any;
  rownumber: number;

  name: string;
  selection: boolean;

  control: FormControl = new FormControl({ value: false , disabled:false}, Validators.requiredTrue);
  subscription: Subscription = null;

  updateValue() {}

  set checkedVal(val: boolean) {
    this.control.setValue(val);
  }

  ngAfterViewInit(): void {
    const grid = this.grid.get(this.gridid);

    //let input = this.el.nativeElement.querySelector('input')
    const clicked = false;

    /*input.addEventListener('click',(ev) => {
      clicked = true;
    })*/

    /*this.el.nativeElement.parentElement.addEventListener('click',() => {
      if(clicked){
        this.checkedVal = !this.checkedVal;
        grid.data[grid.scrolledItems+grid.selectedRowId][grid.columns[grid.selectedColumnId].name] = this.checkedVal
        //!grid.data[grid.scrolledItems+grid.selectedRowId][grid.columns[grid.selectedColumnId].name]
        clicked = false;
      }

    })*/
    if (this.rowId) {
      //this.checkedVal = this.rowId[this.name];
      //this.control.setValue(this.checkedVal)
      this.cd.detectChanges();
    }

    // this.subscription = this.control.valueChanges.subscribe(
    //   v => {
    //     this.rowId[this.name]=v;
    //   }
    // )
  }

  check() {
    const grid = this.grid.get(this.gridid);
    let selected = grid.datasets.get('selected');

    //this.checkedVal = !this.checkedVal;

    if (this.selection) {
      if (selected?.includes(this.rowId)) {
        selected = selected.filter((val) => val != this.rowId);

        if (grid.data.length - 1 === selected.length) {
          console.log('draw square...');

          grid.headerCompRef.select.location.nativeElement.querySelector('iscustomcheckbox').classList.remove('check');
          grid.headerCompRef.select.location.nativeElement
            .querySelector('iscustomcheckbox')
            .classList.add('notallcheck');
        }
      } else {
        selected = selected == null ? [] : selected;
        selected.push(this.rowId);
        if (grid.data.length === selected.length) {
          grid.headerCompRef.select.location.nativeElement
            .querySelector('iscustomcheckbox')
            .classList.remove('notallcheck');
          grid.headerCompRef.select.location.nativeElement.querySelector('iscustomcheckbox').classList.add('check');
        }
        if (grid.gridref.afterclickselectbox) {
          grid.gridref.afterclickselectbox(this.rowId);
        }
      }

      grid.datasets.set('selected', selected);
    } else {
      //this.checkedVal = !this.checkedVal;
      //selected = [this.rowId];

      grid.data[grid.scrolledItems + this.rownumber][this.name] = this.control.value;
      // selected[this.name] = this.checkedVal;
    }

    //grid.setSelectedData(selected);

    //this.comp.formcontrols.get(this.gridid.toString() + '.' + this.name).formcontrol.setValue(selected);

    this.cd.detectChanges();

    //if (this.rowId) {
    //  this.rowId[this.name] = this.checkedVal;
    //}
  }

  // ngOnDestroy() {
  //   //this.subscription.unsubscribe();
  // }
}
