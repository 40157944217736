import { ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { ComponentsService, Outlet2Service } from '@ic-builder/is-base';
import { IscontrolComponent } from '../iscontrol/iscontrol.component';
import { Expression } from './expression.model';
import { ExpressionFormData } from './isexpression/isexpression.component';
import { LeafExpressionFormData } from './isleafexpression/isleafexpression.component';

class ExpressionInput {
  form: FormGroup;
  open: boolean;
}

@Component({
  selector: 'isfilter',
  templateUrl: './isfilter.component.html',
  styleUrls: ['./isfilter.component.scss'],
})
export class IsfilterComponent extends IscontrolComponent implements OnInit {
  expressions: ExpressionInput[] = [];

  fieldOptions = ['regio','werknemer'];
  operatorOptions = ['!=', '<=', '>=', '=='];
  expression: string;

  form: FormGroup;

  controlID: number;
  controlFielddefs: string;
  controlExpression;

  formControlID: FormControl;
  @Input() formControlFielddefs: FormControl;
  @Input() formControlExpression: FormControl;

  subscriptions = [];

  constructor(
    public comp: ComponentsService,
    public store: Store,
    public outlet: Outlet2Service,
    public NgZone: NgZone,
    public dialog: MatDialog,
    public el: ElementRef,
    public cd: ChangeDetectorRef
  ) {
    super(comp, store, outlet, NgZone, dialog, el, cd);
  }

  ngOnInit(): void {
    // if (this.formInput) {
    if (this.id) {
      // this.controlID = super.initControl(this.id.toString()+'.id', null, 'id');
      this.controlFielddefs = super.initControl(this.id.toString() + '.fielddefs', null, 'fielddefs');
      this.controlExpression = super.initControl(this.id.toString() + '.expression', null, 'expression');

      // this.formControlID = super.getFormControl(this.controlID);
      this.formControlFielddefs = super.getFormControl(this.controlFielddefs);
      this.formControlExpression = super.getFormControl(this.controlExpression);
    } else {
      this.formControlFielddefs = new FormControl(null);
      this.formControlExpression = new FormControl(null);
    }

    this.form = new FormGroup({ expression: new FormControl(null) });
    this.form.valueChanges.subscribe((values) => {
      this.formControlExpression.setValue(values);
      this.cd.detectChanges();
    });
  }

  ngAfterViewInit() {
    this.formControlFielddefs.valueChanges.subscribe((a) => {
      this.fieldOptions = [];
      a.forEach((field) => {
        this.fieldOptions.push(field.name);
      });
    });
  }

  add() {
    this.form.get('expression').setValue({
      leftExpression: null,
      operator: null,
      rightExpression: null,
    });
    this.cd.detectChanges();
  }

  addLeaf() {
    this.form.get('expression').setValue({
      field: this.fieldOptions.length == 1 ? this.fieldOptions[0] : null,
      operator: null,
      value: null,
    });

    this.cd.detectChanges();
  }

  isLeaf(expression: LeafExpressionFormData | ExpressionFormData): expression is LeafExpressionFormData {
    return (expression as LeafExpressionFormData).field !== undefined;
  }

  remove() {
    this.form.removeControl('expression');
    this.form.addControl('expression', new FormControl(null));
    this.cd.detectChanges();
  }

  updateFormValues() {
    const expList: Expression[] = [];
    this.expressions.forEach((exp) => {
      const expression: Expression = {
        leftExpression: exp.form.get('field').value,
        operator: exp.form.get('operator').value,
        rightExpression: exp.form.get('value').value,
      };
      expList.push(expression);
    });
    console.log(JSON.stringify(expList));
  }

  splitExp() {
    const values = this.form.value;
    this.remove();
    console.log(values);
    this.form.get('expression').setValue({
      leftExpression: values.expression,
      operator: null,
      rightExpression: null,
    });
    this.cd.detectChanges();
  }
}
