import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IsyearplannerService {
  statesMap: Map<number, any[]> = new Map<number, any[]>();

  public stateColors = {
    movedFrom: {
      background: 'transparent',
      color: 'black',
      border: '1px solid gray',
      radius: '50%',
    },
    planItems: { background: 'green', color: 'white' },
    startedItems: { background: 'red', color: 'white' },
    finishedItems: { background: 'blue', color: 'white' },
    holidays: { background: 'gray', color: 'white' },
    moveFrom: { background: 'purple', color: 'white' },
    moveTo: { background: 'yellow', color: 'black' },
    selectDragItems: { background: 'orange', color: 'black' },
    _selectItems: { background: 'orange', color: 'black' },
  };

  updateItems: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  _selectItems: any = [];
  selectDragItems: any = [];

  planItems: any = [
    { day: 15, month: 8, year: 2020 },
    { day: 20, month: 8, year: 2020 },
    { day: 20, month: 8, year: 2021 },
  ];
  startedItems: any = [{ day: 15, month: 7, year: 2020 }];
  finishedItems: any = [{ day: 22, month: 7, year: 2020 }];
  holidays: any = [{ day: 1, month: 10, year: 2020 }];
  moveFrom: any = [];
  moveTo: any = [];
  movedFrom: any = [];

  firstDay: any;
  endDay: any;
  isMouseDown = false;
  isShiftDown = false;

  constructor() {}

  mousedown(val, ev) {
    if (ev.shiftKey) {
      this.isShiftDown = true;
    }
    this.isMouseDown = true;
    this.firstDay = val;
  }

  mouseup(val, ev) {
    this.isMouseDown = false;
    if (ev.shiftKey) {
      this.movePlanned();
    } else {
      this.endDay = val;
      this.dragSelect();
      this.selectDragItems.forEach((item) => {
        const temp = this._selectItems.filter(
          (it) => JSON.stringify(it) == JSON.stringify(item)
        );
        if (temp.length == 0) {
          this._selectItems.push(item);
        }
      });
      this.selectDragItems = [];
      this.updateItems.next(true);
    }
  }

  mouseenter(val, ev) {
    if (ev.shiftKey) {
      this.endDay = val;
    } else if (this.isMouseDown) {
      this.endDay = val;
      this.dragSelect();
    }
  }

  keydownEvent(ev) {
    if (ev.key == 'Escape') {
      this.clearSelectItems();
    }
    if (
      ev.getModifierState &&
      ev.getModifierState('Control') &&
      ev.keyCode === 65
    ) {
      ev.preventDefault();
      this.planItems.forEach((item) => {
        this.moveFrom.push(item);
      });
      this.planItems = [];
      this.updateItems.next(true);
    }
  }

  clearSelectItems() {
    this._selectItems = [];
    this.selectDragItems = [];

    this.moveFrom.forEach((item) => {
      this.planItems.push(item);
    });
    this.moveFrom = [];
    this.moveTo = [];

    this.updateItems.next(true);
  }

  aanmaken() {
    this.moveFrom.forEach((item) => {
      this.movedFrom.push(item);
    });
    this.moveFrom = [];
    this.moveTo.forEach((item) => {
      this.planItems.push(item);
    });
    this.moveTo = [];
    this.updateItems.next(true);
  }

  deleteMovedFrom() {
    this.movedFrom = [];
    this.updateItems.next(true);
  }

  addSelectItem(day) {
    const item = this._selectItems.filter(
      (item) =>
        item.day == day.day && item.month == day.month && item.year == day.year
    );
    if (item.includes(day)) {
      this._selectItems.filter((item) => item != day);
    } else {
      this._selectItems.push({
        day: day.day,
        month: day.month,
        year: day.year,
      });
    }
    this.updateItems.next(true);
  }

  movePlanned() {
    const firstDate = new Date(
      this.firstDay.year,
      this.firstDay.month,
      this.firstDay.day
    );
    const newDate = new Date(
      this.endDay.year,
      this.endDay.month,
      this.endDay.day
    );

    let dTime;
    if (newDate >= firstDate) {
      dTime = newDate.getTime() - firstDate.getTime();
    } else {
      dTime = firstDate.getTime() - newDate.getTime();
    }
    const dDays = dTime / (3600 * 24 * 1000);

    this.moveFrom.forEach((item) => {
      const date = new Date(item.year, item.month, item.day);
      if (newDate >= firstDate) {
        date.setDate(date.getDate() + dDays);
      } else {
        date.setDate(date.getDate() - dDays);
      }

      this.moveTo.push({
        day: date.getDate(),
        month: date.getMonth(),
        year: date.getFullYear(),
      });
    });
    this.updateItems.next(true);
  }

  dragSelect() {
    if (this.firstDay == this.endDay) {
      const planned = this.planItems.filter(
        (item) => JSON.stringify(item) == JSON.stringify(this.firstDay)
      );
      const from = this.moveFrom.filter(
        (item) => JSON.stringify(item) == JSON.stringify(this.firstDay)
      );
      const selected = this._selectItems.filter(
        (item) => JSON.stringify(item) == JSON.stringify(this.firstDay)
      );
      if (planned.length > 0) {
        this.moveFrom.push(this.firstDay);
        this.planItems = this.planItems.filter(
          (item) => JSON.stringify(item) != JSON.stringify(this.firstDay)
        );
      } else if (from.length > 0) {
        this.planItems.push(this.firstDay);
        this.moveFrom = this.moveFrom.filter(
          (item) => JSON.stringify(item) != JSON.stringify(this.firstDay)
        );
      } else if (selected.length > 0) {
        this._selectItems = this._selectItems.filter(
          (item) => JSON.stringify(item) != JSON.stringify(this.firstDay)
        );
      } else {
        this._selectItems.push(this.firstDay);
      }
      this.updateItems.next(true);
      return;
    }

    let dates = [],
      currentDate = new Date(
        this.firstDay.year,
        this.firstDay.month,
        this.firstDay.day
      ),
      endDate = new Date(this.endDay.year, this.endDay.month, this.endDay.day),
      addDays = function (days) {
        const date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      };

    if (currentDate <= endDate) {
      while (currentDate <= endDate) {
        dates.push(JSON.parse(JSON.stringify(currentDate)));
        currentDate = addDays.call(currentDate, 1);
      }
    } else {
      while (endDate <= currentDate) {
        dates.push(JSON.parse(JSON.stringify(endDate)));
        endDate = addDays.call(endDate, 1);
      }
    }

    this.selectDragItems = [];

    dates.forEach((date) => {
      const dateObject = {
        day: date.getDate(),
        month: date.getMonth(),
        year: date.getFullYear(),
      };
      const temp = this.selectDragItems.filter(
        (item) => JSON.stringify(item) == JSON.stringify(dateObject)
      );
      if (temp.length == 0) {
        this.selectDragItems.push(dateObject);
      }
    });

    this.updateItems.next(true);
  }

  initState(id) {
    this.statesMap.set(id, []);
  }

  onClick(day, id, multiselect) {
    if (day.day) {
      this.addDaySelected(day, id);
    } else if (day.weeknr) {
      const startDate = this.getDateOfISOWeek(day.weeknr, day.year);
      const endDate = new Date(Number(startDate));
      endDate.setDate(startDate.getDate() + 6);
      const start = {
        year: startDate.getFullYear(),
        month: startDate.getMonth(),
        day: startDate.getDate(),
      };
      const end = {
        year: endDate.getFullYear(),
        month: endDate.getMonth(),
        day: endDate.getDate(),
      };
      const list = this.datesFromRange(start, end);

      const bool = list.every((l) =>
        this.statesMap
          .get(id)
          .some(
            (li) => li.year == l.year && li.month == l.month && li.day == l.day
          )
      );
      if (bool) {
        this.statesMap.set(
          id,
          this.statesMap
            .get(id)
            .filter(
              (l) =>
                !list.some(
                  (li) =>
                    li.year == l.year && li.month == l.month && li.day == l.day
                )
            )
        );
      } else {
        if (!multiselect) {
          this.statesMap.set(id, list);
        } else {
          list.forEach((l) => {
            this.statesMap.get(id).push(l);
          });
        }
      }
    }
    this.updateItems.next(null);
  }

  addDaySelected(day, id) {
    this.statesMap.get(id).push(day);
  }

  onMouseDown(day, id, event, multiselect) {
    if (day.day) {
      if (!multiselect) {
        this.clearSelected(id);
      }

      if (event.shiftKey) {
        this.isShiftDown = true;
      }
      this.isMouseDown = true;
      this.firstDay = day;
    }
    if (day.weeknr) {
      this.isMouseDown = true;
      const date = this.getDateOfISOWeek(day.weeknr, day.year);
      this.firstDay = {
        year: date.getFullYear(),
        month: date.getMonth(),
        day: date.getDate(),
      };
    }
  }

  setCurrentWeek() {
    const date = new Date();
    this.firstDay = {
      year: date.getFullYear(),
      month: date.getMonth(),
      day: date.getDate(),
    };
    this.endDay = {
      year: date.getFullYear(),
      month: date.getMonth(),
      day: date.getDate() + 6,
    };
  }

  clearSelected(id) {
    this.statesMap.set(id, []);
    this.updateItems.next(null);
  }

  onMouseUp(day, id, event) {
    if (day.day) {
      this.isMouseDown = false;

      if (event.shiftKey) {
        //moveplanned
      } else {
        this.endDay = day;
      }
    }
    if (day.weeknr) {
      this.isMouseDown = false;
    }
  }

  onMouseEnter(day, id, event, multiselect) {
    if (day.day) {
      if (this.isMouseDown) {
        this.endDay = day;
        this.addList(id, multiselect);
      }
    }
    if (day.weeknr) {
      if (this.isMouseDown) {
        const date = this.getDateOfISOWeek(day.weeknr, day.year);
        date.setDate(date.getDate() + 6);
        this.endDay = {
          year: date.getFullYear(),
          month: date.getMonth(),
          day: date.getDate(),
        };
        this.addList(id, multiselect);
      }
    }
  }

  addList(id, multiselect) {
    const list = this.datesFromRange(this.firstDay, this.endDay);
    if (!multiselect) {
      this.statesMap.set(id, list);
    } else {
      list.forEach((l) => {
        this.statesMap.get(id).push(l);
      });
    }
    this.updateItems.next(null);
  }

  datesFromRange(firstDay, endDay) {
    const dts = [];
    let currentDate = new Date(firstDay.year, firstDay.month, firstDay.day);
    let endDate = new Date(endDay.year, endDay.month, endDay.day);

    const addDays = function (days) {
      const date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };

    if (currentDate <= endDate) {
      while (currentDate <= endDate) {
        dts.push(currentDate);
        currentDate = addDays.call(currentDate, 1);
      }
    } else {
      while (endDate <= currentDate) {
        dts.push(endDate);
        endDate = addDays.call(endDate, 1);
      }
    }

    const dates = [];
    dts.forEach((date) => {
      const dateObject = {
        day: date.getDate(),
        month: date.getMonth(),
        year: date.getFullYear(),
      };
      const temp = dates.filter(
        (item) => JSON.stringify(item) == JSON.stringify(dateObject)
      );
      if (temp.length == 0) {
        dates.push(dateObject);
      }
    });

    return dates;
  }

  getDateOfISOWeek(w, y) {
    const simple = new Date(y, 0, 1 + (w - 1) * 7);
    const dow = simple.getDay();
    const ISOweekStart = simple;
    if (dow <= 4) {
      ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    } else {
      ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    }
    return ISOweekStart;
  }

  getWeek(dt) {
    const today = new Date(dt);
    const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
    const pastDaysOfYear =
      (today.valueOf() - firstDayOfYear.valueOf()) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
  }

  onKeydown(id, event) {
    if (event.key == 'Escape') {
      this.statesMap.set(id, []);
      this.updateItems.next(null);
    }
  }
}
