<div *ngIf="label" istag="label" class="content label" id="labeltxt">
  {{ label }}
</div>

<select
  istag="select"
  class="content select"
  [formControl]="formControl"
  *ngIf="enumerationlist$ | async as enumeration"
>
  
  <option istag="option" *ngFor="let enum of enumeration" [value]="enum.id" class="option" >
    {{ enum.description }}
  </option>
  <option value="iscel" >iscel</option>
</select>
<mat-icon istag="error" *ngIf="!valid" style="color: red">error</mat-icon>
<mat-icon
  istag="warning"
  *ngIf="!warning"
  style="color: orange"
  (mouseenter)="openwarning($event)"
  (mouseleave)="closewarning()"
  >warning</mat-icon
>

<ng-template #warningMes>
  <div class="warning">
    <span *ngFor="let warn of warningMessages">
      {{ warn }}
    </span>
  </div>
</ng-template>
