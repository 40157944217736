import { Store } from '@ngxs/store';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import {
  ComponentsService,
  FormGroupListItem,
  IIsComponent,
  IsFormGroup,
  Outlet2Service,
  UpdateProperty,
} from '@ic-builder/is-base';
import { FormGroup } from '@angular/forms';
import { LoadEnumlist } from '../isenum/enum.action';
import { EnumState } from '../isenum/enum.state';
import { take, tap } from 'rxjs/operators';
import { IsCloudService } from '@ic-builder/data-access-iscloud';

@Component({
  selector: 'iswizard',
  templateUrl: './iswizard.component.html',
  styleUrls: ['./iswizard.component.scss'],
})
export class IswizardComponent implements OnInit, AfterViewInit, IsFormGroup {
  id: number;
  index = 0;
  overlayRef: any;
  content: any;
  contextRef: any;
  prevIndex = [];
  children: IIsComponent[];
  wizFormGroup: FormGroup = new FormGroup({});
  definition: any = {};
  loadFunction: string;
  saveFunction: string;
  saveURL: string;
  loadURL: string;
  afterviewinit: Function | null = null;
  data: any;
  newdata: any;
  command: string;
  useTriggercounter = false;
  currentPage: ComponentRef<any>;
  Pages: Component[] = [];
  onclose: any;
  oncancel: any;
  title: string;
  formFormGroup: FormGroup = new FormGroup({});
  saveFormGroups: Array<FormGroupListItem> = [];
  formgroupdef: any[];
  btnIcons: boolean = false;
  @ViewChild('wizardoutlet', { read: ViewContainerRef }) wizardoutlet: ViewContainerRef;
  @ViewChild('wizardtitle', { read: ViewContainerRef }) wizardtitle: ViewContainerRef;
  @ViewChild('wizcancel', { read: ElementRef }) wizcancel: ElementRef;
  @ViewChild('wizback', { read: ElementRef }) wizback: ElementRef;
  @ViewChild('wiznext', { read: ElementRef }) wiznext: ElementRef;
  @ViewChild('wiztitle', { read: ComponentRef }) wiztitle: ElementRef;

  constructor(
    private comp: ComponentsService,
    private cd: ChangeDetectorRef,
    private store: Store,
    private outlet: Outlet2Service,
    private ngZone: NgZone,
    private el: ElementRef,
    private ic: IsCloudService
  ) {}

  ngOnInit() {
    this.store.dispatch(new LoadEnumlist(''));
  }

  ngAfterViewInit() {}

  lastpage_icon() {
    return this.index == this.children.length - 1 ? 'save' : 'keyboard_arrow_right';
  }

  lastpage_text() {
    if (this.children.length > 1) {
      //return this.index == this.children.length - 1 ? 'Terminer' : 'Suivant';
      return this.index == this.children.length - 1 ? 'Voltooien' : 'Volgende';
    } else {
      return 'OK';
    }
  }

  lastpage_backtext() {
    //return this.index > 0 ? 'PrÃ©cÃ©dent' : '';
    return this.index > 0 ? 'Vorige' : '';
  }

  loadenumfields(items) {
    let enumitems = '';
    items.forEach((item) => {
      const temp = item.controlname.split('.');
      if (temp.length > 1) {
        const id = JSON.parse(temp[0]);
        const component: any = this.comp.widgets.get(id);
        if (component) {
          if (component.instance.constructor.name == 'Isenum2Component') {
            enumitems = enumitems + (enumitems.length == 0 ? component.instance.enum : ',' + component.instance.enum);
          }
        }
      }
    });
    //console.log('Loadenumlist wizard : load ');
    //this.store.dispatch({ type: '[LoadEnumlist]', payload: { payload: enumitems } });

    return enumitems;
  }

  newwizFormGroup(items, load?: boolean) {
    this.wizFormGroup = new FormGroup({});

    if (this.useTriggercounter) {
      const sub = this.store.select(EnumState['triggercounter']).pipe(
        tap((item) => {
          console.log('Loadenumlist wizard : ', item);

          items.forEach((item) => {
            const control = this.comp.formcontrols.get(item.controlname);
            if (this.data && control) {
              control.formcontrol.setValue(this.data[item.key]);
            }
            if (control) {
              this.wizFormGroup.addControl(item.controlname, control.formcontrol);
              control.formcontrol['iscontrol'] = control;
            }
          });
          this.definition = items;
          this.setData(this.data);
        })
      );
    } else {
      items.forEach((item) => {
        const control = this.comp.formcontrols.get(item.controlname);
        if (this.data && control) {
          control.formcontrol.setValue(this.data[item.key]);
        }
        if (control) {
          this.wizFormGroup.addControl(item.controlname, control.formcontrol);
          control.formcontrol['iscontrol'] = control;
        }
      });
      this.definition = items;
      if (this.data) {
        this.setData(this.data);
      }
      this.cd.detectChanges();
    }

    const enumitems = this.loadenumfields(items);
    this.store.dispatch(new LoadEnumlist(enumitems));

    //const subscription = sub.subscribe((item) => {});

    if (!load) {
      this.definition = items;
      this.store.dispatch({
        type: '[FormDefinition.UpdateWidgetPath]',
        payload: { id: this.id, path: ['config', 'definition'], value: items },
      });
      // this.store.dispatch(new UpdateProperty({ compid: this.id, path: 'config.definition', value: this.definition }));
    }
  }

  afterwizardReady() {
    if (Array.isArray(this.definition)) {
      this.newwizFormGroup(this.definition, true);
      this.comp.newFormGroup(this, null);
    }
    let idx = 0;
    this.children.forEach((item) => {
      this.Pages[idx] = this.comp.widgets.get(item.id).instance;
      idx++;
    });
    if (this.children.length > 0) {
      this.changeVal(0);
    }
    if (this.afterviewinit) {
      this.afterviewinit();
    }
  }

  afterformReady() {
    if (Array.isArray(this.definition)) {
      this.newwizFormGroup(this.definition, true);
      this.comp.newFormGroup(this, null);
    }
    let idx = 0;
    this.children.forEach((item) => {
      this.Pages[idx] = this.comp.widgets.get(item.id).instance;
      idx++;
    });
    if (this.children.length > 0) {
      this.changeVal(0);
    }
    if (this.afterviewinit) {
      this.afterviewinit();
    }
  }

  setData(data) {
    this.data = data;
    if (this.definition && this.data && this.wizFormGroup) {
      this.wizFormGroup = new FormGroup({});
      Object.values(this.definition).map((val) => {
        const controlname = (val as any).controlname;
        const control = this.comp.formcontrols.get(controlname);
        if (control) {
          this.wizFormGroup.addControl(controlname, control.formcontrol);
          control.formcontrol.setValue(this.data[(val as any).key]);
          // if(control.formcontrol['owner  ']){
          //   (control.formcontrol['owner'] as any).setDesc(this.data[(val as any).key])
          // }
        }
      });
    }
    if (this.children.length > 0) {

      const setD = (child: IIsComponent) => {
        const cmpRef = this.comp.widgets.get(child.id);
        cmpRef.instance.data = this.data;
        if (cmpRef.instance.aftersetData){
          cmpRef.instance.aftersetData();
        }
        cmpRef.changeDetectorRef.detectChanges();
      
        if (child.children) {
          child.children.forEach((c) => setD(c));
        }
      }

      this.children.forEach((c) => {
        setD(c);        
        // const cmpRef = this.comp.widgets.get(c);
        // cmpRef.instance.data = this.data;
        // cmpRef.changeDetectorRef.detectChanges();
      });

      this.changeVal(0);
    }
  }

  onaftercomponentadd(component) {
    this.changeVal(this.children.length - 1);
  }

  update() {
    this.changeVal(this.index);
  }

  cancel() {
    if (this.oncancel) {
      this.oncancel();
    }
    this.closewizard();
  }

  readrecord() {
    const newdata = {};
    this.definition.map((item) => {
      const splitted = item.key.split('.');
      let temp = newdata;
      for (let i = 0; i < splitted.length - 1; i++) {
        if (!temp[splitted[i]]) {
          temp[splitted[i]] = {};
        }
        temp = temp[splitted[i]];
      }

      const controlname = (item as any).controlname;
      const control = this.comp.formcontrols.get(controlname)?.formcontrol;
      if (control) {
        temp[splitted[splitted.length - 1]] = control.value;
      }
    });
    return newdata;
  }

  nextrecord(grid) {
    grid.view.s;
  }

  resetcontrols(){
    this.definition.map((item) => {
      const controlname = (item as any).controlname;
      const control = this.comp.formcontrols.get(controlname)?.formcontrol;
      if (control) {
        control.reset();
      }
    });    
  }

  save() {
    //const val = this.wizFormGroup.value;

    this.newdata = {};
    this.definition.map((item) => {
      const splitted = item.key.split('.');
      let temp = this.newdata;
      for (let i = 0; i < splitted.length - 1; i++) {
        if (!temp[splitted[i]]) {
          temp[splitted[i]] = {};
        }
        temp = temp[splitted[i]];
      }

      const controlname = (item as any).controlname;
      const control = this.comp.formcontrols.get(controlname)?.formcontrol;
      if (control) {
        temp[splitted[splitted.length - 1]] = control.value;
      }

    });
    /* fields in definition that have no formfields should return data that is set on creation if data is passed */
    // if (this.data) {
    //   Object.entries(this.newdata).forEach(([k, v]) => {
    //     if (v == null) {
    //       this.newdata[k] = this.data[k];
    //     }
    //   });
    // }
    // update data internally in page from where the wizard was started
    if (this.data) {
      Object.entries(this.newdata).forEach(([k, v]) => {
        this.data[k] = this.newdata[k] ? this.newdata[k] : this.data[k];
        this.data[k] = this.data[k] == undefined ? null : this.data[k];
      });
    }

    // update data externaly to make it persistent
    let promise;
    
    if (this['savedata']) {
      promise =  this['savedata'](this.data, this.onvalidated);
    } else {
      if (this.command) {
        this.store.dispatch({
          type: '[Execute]',
          payload: { command: this.command, data: this.data, validated: this.onvalidated },
        });
      }
    }

    if (this.onclose) {
      this.onclose(this.definition, this.data, this);
    }

    promise = promise ? promise : new Promise(((resolve) => resolve(null)));
    
    // new Promise((resolve, reject) => reject(new Error()))

    promise.then(() => this.closewizard())
      .catch((error: Error) => {
        console.error('something went wrong ',error)
      }
      );
  }

  onvalidated() {
    if (this.newdata) {
      Object.entries(this.newdata).forEach(([k, v]) => {
        if (v == null) {
          this.data[k] = this.newdata[k];
        }
      });
    }
  }

  closewizard() {
    this.ngZone.run(() => {
      if (this.content.length > 0) {
        this.content.detach();
      }
      this.resetcontrols();
      //this.contextRef.detach();
      this.overlayRef.detach();
    });
  }

  changeVal(idx, prevnext?: string) {
    if (this.wizardoutlet.length > 0) {
      if (this.currentPage) {
        if (this.currentPage.instance.onleavePage) {
          this.currentPage.instance.onleavePage();
        }
      }
      this.wizardoutlet.detach(0);
    }
    const cmpRef = this.comp.widgets.get(this.children[idx].id);
    this.currentPage = cmpRef;
    this.wizardoutlet.insert(cmpRef.hostView);
    this.index = idx;
    this.cd.detectChanges();

    const insertDom = (widget: ComponentRef<any>) => {
      widget.instance.afterInsertInDom ? widget.instance.afterInsertInDom(true) : null;

      widget['ischildren']?.forEach((item: any) => {
        insertDom(item);
      });
    };
    insertDom(cmpRef);

    if (cmpRef.instance.onselectPage) {
      cmpRef.instance.onselectPage();
    }

    if (prevnext == 'next') {
      const onnextPage = (widget: ComponentRef<any>) => {
        widget.instance.onnextPage ? widget.instance.onnextPage(true) : null;

        widget['ischildren']?.forEach((item: any) => {
          onnextPage(item);
        });
      };

      onnextPage(cmpRef);
    }

    if (prevnext == 'prev') {
      const onprevPage = (widget: ComponentRef<any>) => {
        widget.instance.onprevPage ? widget.instance.onprevPage(true) : null;

        widget['ischildren']?.forEach((item: any) => {
          onprevPage(item);
        });
      };

      onprevPage(cmpRef);
    }

    cmpRef.changeDetectorRef.detectChanges();
  }

  pagebyId(id,force?:boolean) {
    const oldindex = this.index;
    this.index = this.children.findIndex((a) => a.id == id);
    if ((this.children[oldindex].id != id) || force) {
      if (this.wizardoutlet.length > 0) {
        this.wizardoutlet.detach(0);
      }
      const cmpRef = this.comp.widgets.get(id);
      this.wizardoutlet.insert(cmpRef.hostView);

      this.cd.detectChanges();

      const insertDom = (widget: ComponentRef<any>) => {
        widget.instance.afterInsertInDom ? widget.instance.afterInsertInDom(true) : null;

        widget['ischildren']?.forEach((item: any) => {
          insertDom(item);
        });
      };

      insertDom(cmpRef);
    }
    return this.index;
  }

  pageIndex(page) {
    this.children.indexOf(page);
  }

  next() {
    //console.log(this.comp.formcontrols.get('12372.input').formcontrol.valid)

    const pages = this.children.map((c) => {
      if (c.type) {
        return c.id;
      }
    });

    const cont = this.comp.widgets.get(pages[this.index]).instance.valid();

    if (!cont) {
      return;
    }

    const next = this.comp.widgets.get(pages[this.index]).instance.next();
    if (this.index < this.children.length - 1) {
      this.prevIndex.push(this.index);
      this.changeVal(this.index + next, 'next');
    }

    this.wizback.nativeElement.removeAttribute('hidden');
  }

  back() {
    if (this.index > 0) {
      //this.index--;
      if (this.prevIndex.length > 0) {
        const newIdx = this.prevIndex[this.prevIndex.length - 1];
        this.prevIndex.pop();
        this.changeVal(newIdx, 'prev');
      }
      this.wizback.nativeElement.removeAttribute('hidden');
    } else {
      this.wizback.nativeElement.setAttribute('hidden', true);
    }
  }
}
