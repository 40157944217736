<input
  type="radio"
  istag="radio"
  class="content radio"
  [name]="groupname"
  [value]="value"
  [formControl]="formControl"
  [ngModel]="groupvalue"
/>

<div istag="label" class="content label" id="labeltxt">
  {{ label }}
</div>
<mat-icon istag="error" *ngIf="!valid" style="color: red">error</mat-icon>
<mat-icon
  istag="warning"
  *ngIf="!warning"
  style="color: orange"
  (mouseenter)="openwarning($event)"
  (mouseleave)="closewarning()"
  >warning
</mat-icon>

<ng-template #warningMes>
  <div class="warning">
    <span *ngFor="let warn of warningMessages">
      {{ warn }}
    </span>
  </div>
</ng-template>
