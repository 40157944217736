import { ComponentPortal } from '@angular/cdk/portal';
import { Overlay } from '@angular/cdk/overlay';
import { FormControl, FormGroup } from '@angular/forms';
import { Outlet2Service } from '@ic-builder/is-base';
import { debounceTime } from 'rxjs/operators';
import { IsGridRegistry, IsGridService } from './isgrid.service';
import { IsApplicationState } from './../isapplication/isapplication.state';
import { AppError, RunQuery } from './../isapplication/isapplication.action';
import { Store } from '@ngxs/store';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { IscontrolComponent } from '../iscontrol/iscontrol.component';
import { ComponentsService } from '@ic-builder/is-base';
import { IsheaderComponent } from './isheader/isheader.component';
import { IsbodyComponent } from './isbody/isbody.component';
import { IsfooterComponent } from './isfooter/isfooter.component';
import { FieldselectorComponent } from './isheader/fieldselector/fieldselector.component';
import { HttpClient } from '@angular/common/http';
import { IsCloudService } from '@ic-builder/data-access-iscloud';
import { MatDialog } from '@angular/material/dialog';
import { WidgetsRegistryService } from '@ic-builder/widgets-registry';
import { Expression } from '../isfilter/expression.model';
import { IsfilterpanelComponent } from './isfilterpanel/isfilterpanel.component';
import { CvcolorPipe } from '../color/color-helpers.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'isgrid',
  templateUrl: './isgrid.component.html',
  styleUrls: ['./isgrid.component.scss'],
})
export class IsgridComponent extends IscontrolComponent implements OnInit, AfterViewInit {
  @Input()
  public dsname: string;
  #sql = '';
  inithasrun = false;

  classdefs: any;
  originalSql = '';
  loadcount = 0;

  #dataLoaded = false;
  _selectCheckbox = false;
  _selectHeadervisible = true;
  _data: any;
  _account: string;
  _active: boolean;
  name: string;
  pivot: boolean;
  rowrenderfunc: any;
  cellrenderfunc: any;
  reducers: any = null;
  fixparams: any;
  controlids = [];
  cacheEnabled = false;
  masterformGroup: FormGroup = null;
  pivots: any[];
  ddlonly = false;

  alwaysrefresh = false;
  headerclass = null;
  rowclass = null;
  footerclass = null;

  controlID: number;
  controlFielddefs: string;
  controlFilter: Expression;
  controlData: any[];

  formControlID: FormControl;
  formControlFielddefs: FormControl;
  formControlFilter: FormControl;
  formControlData: FormControl;
  public view: IsGridService;
  afterformgroupCreated: Function;
  gridstate = 0;
  usemaster = true;
  rememberSelectedRecord = false;
  debouncetime = 450;
  parentgrid: IsgridComponent;
  parentgridid: number;
  parentgridfield: string;
  

  @Input() data: any;

  @Input() hasFormGroup = true;

  @Input() editmode = false;
  @Input() autoprimary = false;
  @Input() public headervisible: boolean;
  @Input() public removedataset = null;
  @Input() public footervisible: boolean;
  @Input() public columns: any;
  @Input() public fielddefs: any;
  @Input() public rowfunctions: any;
  @Input() public columnkey: any;
  @Input() public virtualscroll = true;
  @Input() public rowheight: number;
  @Input() public minheight: number;
  @Input() public minwidth: number;
  @Input() public disabled = false;
  @Input() public keyfield: string;
  @Input() public updatecommand: string;
  @Input() public deletecommand: string;
  @Input() public insertcommand: string;

  @Input() public selectedRowId = 0;
  @Input() public masterdetail: any = null;
  @Input() public params: any = {};
  @Input() dataLoaded: boolean;
  @Input() loading: boolean = false;
  @Input() removeSubscriptions: boolean = true;
  @Input() keepSortingstate: boolean = false;
  @Input() keepFilterstate: boolean = false;
  @Input() keepFiltermode: boolean = false;
  @Input() keepCurrentrecord: boolean = false;
  @Input() public validators = [];
  @Input() public datamode = 0;
  @Output() afterview: EventEmitter<any> = new EventEmitter();
  @Output() afterdatarendered: EventEmitter<any> = new EventEmitter();
  @Output() init: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(IsheaderComponent, { static: true }) public header: IsheaderComponent;
  @ViewChild(IsbodyComponent, { static: true }) public body: IsbodyComponent;
  @ViewChild(IsfooterComponent, { static: true }) public footer: IsfooterComponent;
  @ViewChild(IsfilterpanelComponent, { static: true }) public filterpanel: IsfilterpanelComponent;

  @Output() mouseMove: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  @Output() mouseWheel: EventEmitter<WheelEvent> = new EventEmitter<WheelEvent>();

  masterSub: Subscription | undefined = null;
  @Input() set active(val: boolean) {
    if (val != this._active) {
      this._active = val;
      if (this.datamode == 1 && this.#sql != '' && this._active) {
        this.setSQL(this.#sql);
      }
    }
  }

  get active() {
    return this._active;
  }


  @Input() set sql(val: string) {
    this.setSQL(val);
  }
  get sql() {
    return this.#sql;
  }

  @HostListener('mousemove', ['$event'])
  moveHandler(event: MouseEvent): void {
    this.mouseMove.emit(event);
  }

  @HostListener('wheel', ['$event'])
  wheelHandler(event: WheelEvent): void {
    this.mouseWheel.emit(event);
  }

  @HostListener('keydown.Backspace')
  backspaceGrid() {
    this.view.filterData(this.view.selectedColumnId, 'Backspace');
    // this.view.filterData('firstname', '');
    // this.view.filterData('name', '');
    // this.view.updateMarkingSearch('name', 0);
  }

  get() {
    return this.#dataLoaded;
  }
  set(val: boolean) {
    this.#dataLoaded = val;
    this.loadData(null);
  }

  @Input() set selectCheckbox(val) {
    this.selectCheckbox_(val);
  }

  @Input() set selectHeadervisible(val) {
    this.selectHeadervisible_(val);
  }

  @Input() set adata(val) {
    this.data = val;
    this.loadData(null);
  }

  @Input() set userowselect(val:boolean){
    if(this.view){
      this.view.userowselect = val;
    }  
  }


  @Input()
  public set account(val: string) {
    if (this.view) {
      this.view.account = val;
    }
    this._account = val;
  }

  constructor(
    public compservice: ComponentsService,
    private isgrid: IsGridRegistry,
    public store: Store,
    private compFactory: ComponentFactoryResolver,
    private widgetregistry: WidgetsRegistryService,
    private appservice: IsApplicationState,
    public cd: ChangeDetectorRef,
    public outlet: Outlet2Service,
    public overlay: Overlay,
    public el: ElementRef,
    private httpClient: HttpClient,
    private iccloud: IsCloudService,
    private renderer: Renderer2,
    public NgZone: NgZone,
    public dialog: MatDialog,
    public colorpipe: CvcolorPipe,
    public route: ActivatedRoute
  ) {
    super(compservice, store, outlet, NgZone, dialog, el, cd);
  }

  ngOnInit(): void {
    if (this.active == null) {
      this._active = true;
    }

    this.controlID = super.initControl(this.id.toString() + '.id', null, 'id');
    this.controlFielddefs = super.initControl(this.id.toString() + '.fielddefs', null, 'fielddefs');
    this.controlFilter = super.initControl(this.id.toString() + '.filter', null, 'filter');
    this.controlData = super.initControl(this.id.toString() + '.data', null, 'data');

    this.formControlID = super.getFormControl(this.controlID);
    this.formControlFielddefs = super.getFormControl(this.controlFielddefs);
    this.formControlFilter = super.getFormControl(this.controlFilter);
    this.formControlData = super.getFormControl(this.controlData);
    this.inithasrun = true;
    this.classDefs();
    this.originalSql = this.#sql;
    this.initview();
  }

  initview() {
    this.view = new IsGridService(
      this.store,
      this.compFactory,
      this.widgetregistry,
      this.appservice,
      this.compservice,
      this.outlet,
      this.httpClient,
      this.iccloud,
      this.renderer,
      this.isgrid,
      this.overlay,
      this.colorpipe,
      this.route,
    );

    this.view.keyfield = this.keyfield;
    this.view.insertcommand = this.insertcommand;
    this.view.updatecommand = this.updatecommand;
    this.view.deletecommand = this.deletecommand;
    this.view.gridid = this.id;
    if (this.removedataset != null) {
      this.view.removedataset = this.removedataset;
    }
    this.view.rowheight = this.rowheight ? this.rowheight : this.view.rowheight;
    this.view.datamode = this.datamode;
    this.view.editmode = this.editmode;
    this.view.autoprimary = this.autoprimary;
    if (this._account) {
      this.view.account = this._account;
    }
    this.view.pivot = this.pivot;
    this.view.hasFormGroup = this.hasFormGroup;

    this.view.virtualscroll = this.virtualscroll ? this.virtualscroll : this.view.virtualscroll;
    this.view.minheight = this.minheight ?? 30;
    this.view.minwidth = this.minheight ?? 100;

    this.view.gridref = this;

    if (this.masterdetail) {
      this.masterdetail.forEach((a) => {
        if (a['detailkey']) {
          this.view.detailkey = a['detailkey'];
        }
      });
    }

    this.isgrid.add(this.id, this.view);

    this.view.selectCheckboxColumn = this._selectCheckbox;
    this.view.selectHeadervisible = this._selectHeadervisible;

    this.header.id = this.id;
    this.header.setHeaderinView();
    if (this.headerclass) {
      this.header.setClass('grid-' + this.id + '-' + this.headerclass);
    }

    this.header.visible = this.headervisible ?? true;
    this.body.id = this.id;
    if (this.rowclass) {
      this.body.setClass('grid-' + this.id + '-' + this.rowclass);
      this.view.rowclass = this.rowclass;
    }
    if (this.pivot) {
      this.body.vertscrollbar = false;
    }
    this.body.setBodyinView();

    this.footer.id = this.id;
    this.footer.setFooterinView();
    this.footer.visible = this.footervisible ?? false;
    if (this.footerclass) {
      this.footer.setClass('grid-' + this.id + '-' + this.footerclass);
    }

    this.view.gridid = this.id;
  }

  classDefs() {
    if (this.classdefs) {
      const tmpDef = this.classdefs;
      delete this.classdefs;
      this.comp.createClassDefinitions('grid-' + this.id, tmpDef);
    }
  }

  ngOnDestroy() {
    this.isgrid.del(this.id);
  }

  deleteSelectedRecord(callbackparam, onRemoved?) {
    const idx = this.view.data.findIndex((item) => {
      return this.view.selectedRecord == item;
    });
    if (this.deletecommand) {
      const data = this.view.data[idx];
      const deldata = {};
      deldata[this.keyfield] = data[this.keyfield];
      const sub = this.store.dispatch({ type: '[Execute]', payload: { command: this.deletecommand, data: deldata } });
      const subscription = sub.subscribe((item) => {
        subscription.unsubscribe();
        this.view.data.splice(idx, 1);
        this.view.refreshview();
        if (onRemoved) {
          onRemoved(data, callbackparam);
        }
      });
    } else {
      this.view.data.splice(idx, 1);
      this.view.refreshview();
    }
  }

  deleteRecord(keyvalue, callbackparam, onRemoved?) {
    const idx = this.view.data.findIndex((item) => {
      return this.view.data[this.keyfield] == item[keyvalue];
    });
    if (this.deletecommand) {
      const data = this.view.data[idx];
      const deldata = {};
      deldata[this.keyfield] = data[this.keyfield];
      const sub = this.store.dispatch({ type: '[Execute]', payload: { command: this.deletecommand, data: deldata } });
      const subscription = sub.subscribe((item) => {
        subscription.unsubscribe();
        this.view.data.splice(idx, 1);
        this.view.refreshview();
        if (onRemoved) {
          onRemoved(data, callbackparam);
        }
      });
    } else {
      this.view.data.splice(idx, 1);
      this.view.refreshview();
    }
  }

  updateRecord(keyvalue, data, callbackparam, onUpdate?) {
    const idx = this.view.data.findIndex((item) => {
      return this.view.data[this.keyfield] == item[keyvalue];
    });
    this.view.data[idx] = data;

    const updatedata = this.removenonupdatefields(data);

    if (this.updatecommand) {
      const sub = this.store.dispatch({
        type: '[Execute]',
        payload: { command: this.updatecommand, data: updatedata },
      });
      const subscription = sub.subscribe((item) => {
        subscription.unsubscribe();
        if (onUpdate) {
          onUpdate(data, callbackparam);
        }
        this.view.refreshview();
      });
    }
  }

  removenonupdatefields(data) {
    const f = this.fielddefs ? this.fielddefs : this.columns;

    if (f) {
      f.forEach((item) => {
        if (item.update == false) {
          delete data[item.name];
        }
      });
    }

    const tmpdata = { ...data };
    Object.entries(tmpdata).forEach(([k]) => {
      const idx = f.findIndex((item) => {
        return k == item.name;
      });
      if (idx < 0) {
        delete data[k];
      }
    });
    return data;
  }

  ngAfterViewInit() {
    this.el.nativeElement.setAttribute('tabindex', -1);
    if (this.pivot) {
      this.el.nativeElement.querySelector('isbody').classList.add('pivot');
    }
    if (this.fielddefs) {
      this.view.fielddefs = this.view.createformfields(this.fielddefs);
    } else {
      this.view.fielddefs = this.view.createformfields(this.columns);
    }

    if (this.datamode == 4) {
      this.createColumns();
    }
    //
  }

  // translate(lang) {
  //   console.log(lang);
  // }

  createColumns() {
    if (this.columns) {
      this.view.createColumns({ columns: this.columns, rowfunctions: this.rowfunctions, columnkey: this.columnkey });
    }
    this.cd.detectChanges();

    this.view.setColumnWidth();

    if (this.datamode === 3) {
      //this.loadData(null);
    }
    this.formControlData.valueChanges.subscribe((data) => {
      if (data) {
        this.view.setData(data);
        if (this.columns) {
          this.view.fillData();
        }
      }
    });
    this.formControlFilter.valueChanges.subscribe((values) => {
      if (values) {
        this.view.execExpressionFilter(values.expression);
      } else {
        this.view.resetExpressionFilter();
      }
    });
    if (this.afterview) {
      this.afterview.emit();
    }
  }

  selectedrecord() {
    if (this.view) {
      return this.view.selectedRow;
    } else {
      return null;
    }
  }

  beforeformReady() {
    if (this.masterdetail && this.columns) {
      // this.newFormGroup(this.masterdetail);
    }
  }

  newFormGroup(info) {
    this.masterformGroup = new FormGroup({});

    let iscontrol;
    info;

    for (const i of info) {
      // if(this.id==15722){
      //   return
      // }

      // if(i.controlname.includes('15720') && this.id==-134){
      //   return
      // }
      //console.log('informatie', info, this.id);

      iscontrol = this.compservice.formcontrols.get(i.controlname);
      if (iscontrol) {
        try {
          iscontrol.formcontrol['iscontrol'] = iscontrol;

          if (info.length == 1) {
            iscontrol.formcontrol.valueChanges.pipe(debounceTime(this.debouncetime)).subscribe((params) => {
              //console.log('params xx ', params);
              let master = null;

              if (iscontrol['master']) {
                master = iscontrol.formcontrol;
              }

              const p = {};
              this.params[i.key] = params;

              this.loadData(this.params, master);
            });
          } else {
            let dummy;

            // if (iscontrol.in_use) {
            dummy = new FormControl(iscontrol.formcontrol.value);
            // if (this.id === 20017) debugger;
            iscontrol.formcontrol.valueChanges.pipe(debounceTime(this.debouncetime)).subscribe((v) => {
              //    if (this.id === 20017) debugger;
              dummy.setValue(v);
            });
            // }
            this.masterformGroup.addControl(i.key, dummy);
            // const control = this.masterformGroup.addControl(i.key, iscontrol.in_use ? dummy : iscontrol.formcontrol);

            iscontrol.in_use = true;
          }
        } catch (err) {
          console.error('newFormGroup ', this.id, 'formcontrolname ', i.controlname, ' ', iscontrol, err);
        }
      }
    }

    //
    if (info.length > 1) {
      this.masterSub?.unsubscribe();
      //cmp?.masterformGroup.valueChanges.subscribe((val) => {debugger})

      this.masterSub = this.masterformGroup.valueChanges.pipe(debounceTime(this.debouncetime)).subscribe((params) => {
        //console.log('sub reached', this.id);
        // try to have cleargrid here it causes flickering when using master detail
        // maybe we need it in some usecases so will keep an reference here to see that it is removed
        //this.isgrid.get(this.id).clearGrid()

        //  this.isgrid.get(this.id).loadData({dsname:this.dsname,params:params,
        //               columns:this.columns,rowrenderfunc:this.rowrenderfunc,cellrenderfunc:this.cellrenderfunc})

        // todo make something to see which params caused the change , so if the master is an grid with data the
        // child data can be cached inside the master

        let master = null;
        Object.values(this.masterformGroup.controls).map((control) => {
          if (control['master']) {
            master = control;
          }
        });

        this.params = params;
        this.loadData(this.params, master);
      });
    }
    if (this.afterformgroupCreated) {
      this.afterformgroupCreated();
    }
  }

  loadData(params, masters?, data?) {
    //console.log('loadData gridcomponent : ',this.id);
    if (!this.datamode || !this.active) {
      return;
    }
    if (this.masterdetail) {
      if (this.masterdetail.length > 0) {
        if (params === null) {
          return;
        }
      }
    }

    if (this.rememberSelectedRecord) {
      this.view.returnToRecord = { ...this.view.selectedRecord };
    }
    if (this.#sql && this.datamode == 1) {
      let cacheddetaildata = null;
      if (this.cacheEnabled) {
        let masterRecord;

        if (masters) {
          if (masters.master[0]) {
            masterRecord = this.isgrid
              .get(masters.master[0])
              .data.find((r) => r[masters.iscontrol.name] === masters.iscontrol.formcontrol.value);
            if (masterRecord) {
              cacheddetaildata = masterRecord['detail_' + this.id];
            }
          }
        }
        if (cacheddetaildata) {
          if (this.isgrid.get(masters.master[0]).checkcachevalid != null) {
            if (!this.isgrid.get(masters.master[0]).checkcachevalid(this.id, cacheddetaildata, params)) {
              delete masterRecord['detail_' + this.id];
              cacheddetaildata = null;
            }
          }
        }
      }

      if (this['afterfirstrefresh']) {
        this.isgrid.get(this.id)['afterload'] = this['afterfirstrefresh'];
        this['afterfirstrefresh'] = null;
      }
      if (cacheddetaildata) {
        this.isgrid.get(this.id).loadCachedData(cacheddetaildata, {
          dsname: this.dsname,
          params: params,
          columns: this.columns,
          fielddefs: this.fielddefs,
          rowfunctions: this.rowfunctions,
          account: this.account,
          masterdetail: this.masterdetail,
          master: masters,
        });
      } else {
        this.isgrid.get(this.id).loadData({
          dsname: this.dsname,
          params: params,
          columns: this.columns,
          fielddefs: this.fielddefs,
          rowfunctions: this.rowfunctions,
          masterdetail: this.masterdetail,
          account: this.account,
          master: masters,
        });
        // dispatch after loadData call - loadData only does setup the observers
        // observers must be setup before data is coming in async

        this.params['dateformat'] = this.isgrid.get(this.id).getDateFormatString();

        if (!this.appservice.checkParams(this.params, this.#sql, this.name, this.id)) {
          this.view.clearGrid();
          return;
        }

        this.loadcount = 1;

        this.store.dispatch(
          new RunQuery({
            dsname: this.dsname,
            sql: this.sql,
            account: this._account,
            params: this.params,
            definition: this.columns?.length ? 0 : 1,
            fixparams: this.fixparams ? this.fixparams : this.defaultfixparams,
            reducer: this['initreducer'] ?? null,
          })
        );
      }
    } else if (this.dsname && (this.datamode == 2 || this.datamode == 5)) {
      this.isgrid.get(this.id).loadData({
        dsname: this.dsname,
        params: params,
        definition: this.columns?.length ? 0 : 1,
        columns: this.columns,
        rowfunctions: this.rowfunctions,
        account: this.account,
        masterdetail: this.masterdetail,
        master: masters,
        fixparams: this.fixparams ? this.fixparams : this.defaultfixparams,
        data: data,
      });
    } else if (this.dsname && this.datamode == 6) {
      this.isgrid.get(this.id).loadData({
        dsname: this.dsname,
        params: params,
        definition: this.columns ? 0 : 1,
        columns: this.columns,
        rowfunctions: this.rowfunctions,
        account: this.account,
        masterdetail: this.masterdetail,
        master: masters,
        role: '6',
        fixparams: this.fixparams ? this.fixparams : this.defaultfixparams,
      });
    } else if (this.dsname && this.datamode == 11) {
      if (this.columns) {
        this.parentgrid = this.comp.gci(this.parentgridid);
        const selrecord = this.parentgrid.isgrid.get(this.parentgridid).selectedRecord;
        if (selrecord) {
          this.isgrid.get(this.id).clearGrid();
          this.isgrid.get(this.id).setData(selrecord[this.parentgridfield]);
          this.isgrid.get(this.id).fillData();
        }
      }
    } else if ((this.data || data) && this.datamode == 3) {
      this.isgrid.get(this.id).clearGrid();
      if (!this.isgrid.get(this.id).columns) {
        this.isgrid.get(this.id).createColumns({ columns: this.columns });
      }
      const d = data ? data : this.data;
      if (Array.isArray(d)) {
        this.isgrid.get(this.id).setData(d);

        if (this.columns) {
          this.isgrid.get(this.id).fillData();
        }
        if (this.dsname) {
          this.store.dispatch({ type: '[SetDataset]', payload: { dsname: this.dsname, data: d } });
        }
      }
    }
  }

  storeupdateddata() {
    this.isgrid.get(this.id).storeupdateddata();
  }

  setupLoadSubscription(name: string) {
    const subscription = this.store.select(IsApplicationState.dataset(name));
    return subscription.subscribe((data) => {
      if (data) {
        //console.log('subscription dataset : ', name, ' data ', data);
        this.loadData(null, null, data);
      }
    });
  }

  newEmptyRecord() {
    if (this.fielddefs) {
      const rec = {};
      this.fielddefs.foreach((item) => (rec[item.name] = null));
      return rec;
    }
    if (this.columns) {
      const rec = {};
      this.columns.foreach((item) => (rec[item.name] = null));
      return rec;
    }
    return {};
  }

  createView() {
    this.initview();
    if (this.columns) {
      this.createColumns();
    }
    this.cd.detectChanges();
    if (this.masterdetail != null) {
      if (this.usemaster == null || this.usemaster) {
        this.newFormGroup(this.masterdetail);
      }
    }
    if (this._active) {
      this.loadData(null);
    }
  }

  removeView() {
    this.view?.removeView();
  }

  setSQL(newVal) {
    if (newVal !== this.#sql || this.alwaysrefresh || this.loadcount == 0) {
      this.inithasrun ? this.view?.clearGrid() : null;
      this.#sql = newVal;
      this.inithasrun ? (this._active ? this.loadData(null) : null) : null;

      //this.inithasrun ? this.createView() : null;
    }
  }

  dsnameValueChanges(newVal) {
    if (newVal !== this.dsname && this.sql === '') {
      this.removeView();
      this.dsname = newVal;
      if (this.active) {
        this.createView();
      }
    }
  }

  selectCheckbox_(val) {
    if (typeof val === 'string') {
      this._selectCheckbox = val.toLowerCase() === 'true';
    } else {
      this._selectCheckbox = val;
    }
  }

  selectHeadervisible_(val) {
    if (typeof val === 'string') {
      this._selectHeadervisible = val.toLowerCase() === 'true';
    } else {
      this._selectHeadervisible = val;
    }
  }

  selectCheckboxValueChanges(newVal) {
    this.removeView();
    this.selectCheckbox_(newVal);
    this.createView();
  }

  paramsValueChanges(newVal) {
    if (this.active) {
      this.isgrid.get(this.id).loadData({
        dsname: newVal,
        params: this.params,
        account: this.account,
        rowrenderfunc: this.rowrenderfunc,
        cellrenderfunc: this.cellrenderfunc,
      });
    }
  }

  defaultfixparams(params) {
    if (this.store) {
      if (params) {
        if (!this.params) {
          this.params = {};
        }
        params.forEach((item) => (this.params[item] = null));
      }
      // this.store.dispatch(new UpdateProperty({ compid: this.id, path: 'config.params', value: this.params }));
    }
  }

  columnsValueChanges(newVal) {
    this.removeView();
    this.columns = newVal;
    this.createView();
  }

  activeValueChanges(newVal) {
    if (newVal != this.active) {
      this.active = newVal;
      if (newVal == false) {
        this.isgrid.get(this.id).clearGrid();
      } else {
        this.paramsValueChanges(this.dsname);
      }
    }
  }

  ddlonlyValuesChanges(newVal) {
    this.ddlonly = newVal;
  }
  /*
    rowrenderfunc(rowdata){
      return {style:'background-color:'+rowdata.taskcolor+';color:'+rowdata.taskfontcolor};
    }

    cellrenderfunc(celRef,key,data){
      // Object.keys(rowdata)
    }
  */

  afterInsertInDom(fromwizard = false) {
    this.view.clearGrid();
    this.removeView();
    this.createColumns();
    this.afterformReady1();
    if (!fromwizard) {
      this.initResizeObserver();
    }
    this.afterInsertInDom = null;
    this.gridstate = 1;
  }

  initResizeObserver() {
    const resizeObserver = new ResizeObserver((entries) => {
      if (this.view?.datax && !this.pivot) {
        this.view.clearGrid();
        this.view.fillData(this.view.datax);
        //console.log('Size changed : ',this.id);
      }
    });

    resizeObserver.observe(this.el.nativeElement);
  }

  afterformReady1() {
    //console.log('after form ready grid ', this.id, this.compservice.formcontrols);

    const validators = [];

    this.comp.validators.get(this.id)?.forEach((val, key) => {
      validators.push(this[key]);
    });

    this.validators.forEach((val, key) => {
      validators.push(val);
    });

    this.view.gridFormGroup.setValidators(validators);
    this.view.gridFormGroup?.updateValueAndValidity();

    /*
    self.filtersubscription = this.isgrid.get(this.id).filtered$.subscribe(
      a => {
       if(a.gridid!=null)
          self.viewPort.setVisibleItems();
          self.cd.detectChanges();
        }
    )*/
    const event = new CustomEvent('init', {
      bubbles: true,
      detail: {
        self: this,
        id: this.id,
        gridservice: this.isgrid.get(this.id),
        view: this.view,
        header: this.header,
        body: this.body,
      },
    });
    this.el.nativeElement.dispatchEvent(event);

    if (!this.masterdetail) {
      this.loadData(null);
    } else {
      if (this.masterdetail.length == 0) {
        this.loadData(null);
      } else {
        if (this.columns) {
          if (this.masterdetail != null) {
            this.isgrid.get(this.id).setcontrolid();
            if (this.usemaster == null || this.usemaster) {
              this.newFormGroup(this.masterdetail);
            }
          }
          this.cd.detectChanges();
        }
      }
    }

    //let func = new Function('this.openmenu(11176,11177,'+this.id.toString()+')')

    //let eventmap = new EventMap('gridheadercontext','contextmenu',func,this.id,this.overlay,this.id,'isheader',this.compservice)
    //this.compservice.setEvent(this.id,eventmap,[this.id])
  }

  handleScroll() {
    const body = this.el.nativeElement.querySelector('isbody');
    //console.log('reached 1');

    if (this.pivot) {
      return;
    }

    this.el.nativeElement.querySelector('isheader').scrollLeft = body.scrollLeft;

    if (this.view.fakeScroll) {
      this.view.fakeScroll.nativeElement.style.left =
        (body.scrollLeft + body.getBoundingClientRect().width - 16).toString() + 'px';
    }
    const columns = body.querySelectorAll('iscolumn');

    // let left = body.scrollLeft;
    // let right = body.scrollLeft + this.el.nativeElement.getBoundingClientRect().width;
    const activecolumns = [];

    for (const c of columns) {
      const measures = c.getBoundingClientRect();
      const gridmeasures = this.el.nativeElement.getBoundingClientRect();

      const gridleft = measures.left - gridmeasures.left;
      const gridright = gridmeasures.right;

      if (
        gridleft >= -measures.width && //-measures.width &&
        measures.left <= gridright //this.el.nativeElement.getBoundingClientRect().width + measures.width
      ) {
        activecolumns.push(c.getAttribute('columnkey'));
      }
    }

    if (this.view.columnsInView != activecolumns) {
      const add = activecolumns.filter((c) => !this.view.columnsInView.includes(c));
      const remove = this.view.columnsInView.filter((c) => !activecolumns.includes(c));

      remove.map((r) => this.view.clearColumn(r));

      for (let i = 0; i < this.view.numberOfRows; i++) {
        add.map((a) => {
          this.view.fillColumn(a, this.view.datax, i, this.view.bodyCompRef[a], null);
        });
      }

      this.view.scrolledItems;
      this.view.gridHeight;

      this.view.columnsInView = activecolumns;
    }
  }

  refresh() {
    this.view.refreshview();
  }

  openFieldselector() {
    //console.log('reached', this.columns);
    let overlayRef;
    let fieldSelector;
    let compRef;

    //overlayConfig = this.overlay.position()

    // const positionStrategy = this.overlay
    //   .position()
    //   .connectedTo(this.el, { originX: 'end', originY: 'bottom' }, { overlayX: 'end', overlayY: 'bottom' });

    // overlayRef = this.overlay.create({
    //   positionStrategy,
    //   //positionStrategy:overlayConfig
    //   //positionStrategy: this.overlay.position().connectedTo(this.el.nativeElement.getPopupConnectionElementRef(), { originX: 'start', originY: 'bottom' }, { overlayX: 'start', overlayY: 'top' })
    // });

    fieldSelector = new ComponentPortal(FieldselectorComponent);

    compRef = overlayRef.attach(fieldSelector);

    compRef.instance.columns = [{ name: 'name', label: 'Omschrijving' }];

    compRef.instance.data = this.columns.map((c) => {
      return { select: c.hidden, name: c.name };
    });
    compRef.instance.overlayRef = overlayRef;
    compRef.instance.gridid = this.id;

    //compRef.location.nativeElement.style.position = 'absolute'
    //compRef.location.nativeElement.style.left = '1000px'//(info.right).toString()+'px';
    //compRef.location.nativeElement.style.top = '200px' //info.bottom.toString()+'px'
  }
}
