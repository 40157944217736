import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ComponentsService, Outlet2Service } from '@ic-builder/is-base';
import { Store } from '@ngxs/store';
import { IscontrolComponent } from '../iscontrol.component';

@Component({
  selector: 'ic-builder-isemail',
  templateUrl: './isemail.component.html',
  styleUrls: ['./isemail.component.scss'],
})
export class IsemailComponent
  extends IscontrolComponent
  implements OnInit, AfterViewInit
{
  @Input() label: string;
  @Input() initialValue = '';
  @Input() frmGrp: FormGroup;
  @Input() master: number = null;
  @Input() fieldname: string = null;

  controlid: number;
  public formControl: FormControl;
  notActive = false;
  multiline = false;

  //@ViewChild('warningMes', { read: TemplateRef }) warningMes: TemplateRef<any>;

  constructor(
    comp: ComponentsService,
    public outlet: Outlet2Service,
    private compserv: ComponentsService,
    public store: Store,
    public NgZone: NgZone,
    public dialog: MatDialog,
    public el: ElementRef,
    public cd: ChangeDetectorRef
  ) {
    super(comp, store, outlet, NgZone, dialog, el, cd);
  }

  ngOnInit(): void {
    if (this.formInput && this.id) {
      try {
        this.controlid = super.initControl(
          this.id.toString() + '.input',
          this.initialValue,
          'input'
        );
        this.formControl = super.getFormControl(this.controlid);
      } catch (err) {
        console.log(err, this);
      }
    } else {
      this.formControl = new FormControl(this.initialValue);
    }
  }

  ngAfterViewInit() {
    this.el.nativeElement.setAttribute('tabindex', 0);
    if (!this.multiline) {
      this.el.nativeElement.querySelector('input').focus();
    } else {
      this.el.nativeElement.querySelector('textarea').focus();
    }
  }

  afterformReady() {
    if (this.formControl) {
      if (this['valueChanges']) {
        this.formControl.valueChanges.subscribe((item) => {
          this['valueChanges'](this.formControl.value);
        });
      }
    }
  }

  controlnameValueChanges(newVal) {
    this.compserv.formcontrols.get(this.controlid.toString()).name = newVal;
  }
}
