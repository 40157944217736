import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ComponentsService, Outlet2Service } from '@ic-builder/is-base';
import { RemoveDataset, RunQuery } from '@ic-builder/iscomp';
import { Store } from '@ngxs/store';
//import { AddFielddefs } from 'libs/is-form-builder/src/lib/isformbuilder.action';
import { IsApplicationState } from '@ic-builder/iscomp';
import { Subscription } from 'rxjs';
//import { TfrxcontrolComponent } from '../tfrxcontrol/tfrxcontrol.component';
import { debounceTime } from 'rxjs/operators';
import { DataSource } from '@angular/cdk/collections';

@Component({
  selector: 'ic-builder-frxquery',
  templateUrl: './tfrxquery.component.html',
  styleUrls: ['./tfrxquery.component.scss'],
})
export class TfrxqueryComponent {
  masterformGroup: FormGroup = null;
  cacheEnabled = true;
  dsname: string;
  account: string;
  @Input() fielddefs: any;
  removedataset = false;
  DataSubscription: Subscription;
  alwaysrefresh = false;
  name: string;
  id: number;
  ondataLoaded: any;
  onLoaded: any;
  attribs: any;
  _active = false;

  @Input() set active(val: boolean) {
    if (val != this._active) {
      this._active = val;
      if (this._active) {
        this.loadData(null);
      }
    }
  }

  @Input() UserName = 'isquery';
  _masterdetail: Array<any> | null = null;
  @Input() set masterdetail(val: Array<any> | null) {
    this._masterdetail = val;
    this.newFormGroup(this._masterdetail);
  }
  #sql = '';
  @Input()
  set sql(val: string) {
    this.setSQL(val);
  }
  get sql() {
    return this.#sql;
  }
  @Input() public params: any = {};

  masterSub: Subscription | undefined = null;

  constructor(
    public compservice: ComponentsService,
    private store: Store,
    public outletservice: Outlet2Service,
    private appservice: IsApplicationState
  ) {
    //super(compservice, outletservice);
  }

  beforeSetclassdefs(){
    this.attribs
  }

  setSQL(newVal) {
    if (newVal !== this.sql || this.alwaysrefresh) {
      this.#sql = newVal;
      // if (this.masterdetail != null) {
      //   this.newFormGroup(this.masterdetail);
      // }
      if (this._active) {
        this.loadData(null);
      }
    }
  }

  // aftercloneReady(self,masterid){
    
  //   const findOrigin = (cmp, id) => {
  //     if (cmp.templateid === id) return cmp.id
  //     for (let child of cmp.children ? cmp.children : []) {
  //       const res =  findOrigin(child, id);
  //       if (res) return res;
  //     }
  //     return null;
  //   }

  //   this._masterdetail = this._masterdetail.map((f) => {
  //     const [id, name] = f.controlname.split('.');
  //     const tempId = findOrigin(this.compservice.gci(masterid), Number(id))
  //     return { ...f, key: `${tempId}.${name}` }
  //   });

  // }

  newFormGroup(info) {
    this.masterformGroup = new FormGroup({});

    let iscontrol;
    info;

    for (const i of info) {
      // if(this.id==15722){
      //   return
      // }

      // if(i.controlname.includes('15720') && this.id==-134){
      //   return
      // }
      //console.log('informatie', info, this.id);

      iscontrol = this.compservice.formcontrols.get(i.controlname);
      if (iscontrol){
      try {
        iscontrol.formcontrol['iscontrol'] = iscontrol;

        if (info.length == 1) {
          iscontrol.formcontrol.valueChanges.pipe(debounceTime(450)).subscribe((params) => {
            //console.log('params xx ', params);
            let master = null;

            if (iscontrol['master']) {
              master = iscontrol.formcontrol;
            }

            const p = {};
            this.params[i.key] = params;
            if (this._active) {
              this.loadData(this.params, master);
            }
          });
        } else {
          let dummy;

          if (iscontrol.in_use) {
            dummy = new FormControl(iscontrol.formcontrol.value);

            iscontrol.formcontrol.valueChanges.pipe(debounceTime(450)).subscribe((v) => {
              dummy.setValue(v);
            });
          }

          const control = this.masterformGroup.addControl(i.key, iscontrol.in_use ? dummy : iscontrol.formcontrol);

          iscontrol.in_use = true;
        }
      } catch (err) {
        console.error('newFormGroup', ' ', i.controlname, ' ', iscontrol, err);
      }
    }
    }

    //
    if (info.length > 1) {
      this.masterSub?.unsubscribe();
      //cmp?.masterformGroup.valueChanges.subscribe((val) => {debugger})

      this.masterSub = this.masterformGroup.valueChanges.pipe(debounceTime(450)).subscribe((params) => {
        console.log('sub reached', this.id);
        // try to have cleargrid here it causes flickering when using master detail
        // maybe we need it in some usecases so will keep an reference here to see that it is removed
        //this.isgrid.get(this.id).clearGrid()

        //  this.isgrid.get(this.id).loadData({dsname:this.dsname,params:params,
        //               columns:this.columns,rowrenderfunc:this.rowrenderfunc,cellrenderfunc:this.cellrenderfunc})

        // todo make something to see which params caused the change , so if the master is an grid with data the
        // child data can be cached inside the master

        let master = null;
        Object.values(this.masterformGroup.controls).map((control) => {
          if (control['master']) {
            master = control;
          }
        });

        this.params = params;
        this.loadData(this.params, master);
      });
    }
  }

  // newFormGroup(info) {
  //   this.masterformGroup = new FormGroup({});

  //   let iscontrol;
  //   for (const i of info) {
  //     iscontrol = this.compservice.formcontrols.get(i.controlname);
  //     try {
  //       iscontrol.formcontrol['iscontrol'] = iscontrol;

  //       if (info.length == 1) {
  //         iscontrol.formcontrol.valueChanges.subscribe((params) => {
  //           console.log('params xx ', params);
  //           let master = null;

  //           if (iscontrol['master']) {
  //             master = iscontrol.formcontrol;
  //           }

  //           const p = {};
  //           p[i.key] = params;
  //           this.params = p;

  //           this.loadData(this.params, master);
  //         });
  //       } else {
  //         const control = this.masterformGroup.addControl(i.key, iscontrol.formcontrol);
  //       }
  //     } catch (err) {
  //       console.log('newFormGroup', ' ', i.controlname, ' ', iscontrol, err);
  //     }
  //   }
  // }

  loadData(params, masters?) {
    if (this.sql) {
      let cacheddetaildata = null;
      if (this.cacheEnabled) {
        let masterRecord;

        //     if (masters) {
        //       if (masters.master[0]) {
        //         masterRecord = this.isgrid
        //           .get(masters.master[0])
        //           .data.find((r) => r[masters.iscontrol.name] === masters.iscontrol.formcontrol.value);
        //         if (masterRecord) {
        //           cacheddetaildata = masterRecord['detail_' + this.id];
        //         }
        //       }
        //     }
        //     if (cacheddetaildata) {
        //       if (this.isgrid.get(masters.master[0]).checkcachevalid != null) {
        //         if (!this.isgrid.get(masters.master[0]).checkcachevalid(this.id, cacheddetaildata, params)) {
        //           delete masterRecord['detail_' + this.id];
        //           cacheddetaildata = null;
        //         }
        //       }
        //     }
        //   }

        //   if (this['afterfirstrefresh']) {
        //     this.isgrid.get(this.id)['afterload'] = this['afterfirstrefresh'];
        //     this['afterfirstrefresh'] = null;
        //   }
        //   if (cacheddetaildata) {
        //     this.isgrid.get(this.id).loadCachedData(cacheddetaildata, {
        //       dsname: this.dsname,
        //       params: params,
        //       columns: this.columns,
        //       fielddefs: this.fielddefs,
        //       rowfunctions: this.rowfunctions,
        //       account: this.account,
        //       masterdetail: this.masterdetail,
        //       master: masters,
        //     });
        //   } else {
        this.registerSubscriptions({
          dsname: this.UserName,
          params: params,
          //   columns: this.columns,
          fielddefs: this.fielddefs,
          //   rowfunctions: this.rowfunctions,
          masterdetail: this.masterdetail,
          //masterdetail: null,
          account: this.account,
          master: masters,
        });
        // dispatch after loadData call - loadData only does setup the observers
        // observers must be setup before data is coming in async

        // this.params['dateformat'] = this.isgrid.get(this.id).getDateFormatString();
        if (!this.appservice.checkParams(this.params, this.sql, this.UserName, this.id)) {
          return;
        }

        this.store.dispatch(
          new RunQuery({
            dsname: this.UserName,
            sql: this.sql,
            account: this.account,
            params: this.params,
            definition: this.fielddefs ? 0 : 0,
            // fixparams: this.fixparams ? this.fixparams : this.defaultfixparams,
            fixparams: false,
            ondataLoaded:this.onLoaded?this.onLoaded:null            
          })
        );
      }
    }
  }

  registerSubscriptions(info) {
    this.removeDatasetSubscrription();
    if (this.removedataset) {
      this.store.dispatch(new RemoveDataset({ dsname: info.dsname }));
    }

    const data = this.store.select(IsApplicationState.dsset(info.dsname));
    this.DataSubscription = data.subscribe((data) => {
      if (data) {
        if (!Array.isArray(data)) {
          if (data.missingParams) {
            const newParams = {};
            data.missingParams.map((item) => (newParams[item] = null));
          }
        } else {
          this.processloadedData(data, info);
        }
      }
    });
  }

  removeDatasetSubscrription() {
    if (this.DataSubscription) {
      this.DataSubscription.unsubscribe();
      this.DataSubscription = null;
    }
  }

  processloadedData(data, info) {

    const datadef = this.store.selectSnapshot(IsApplicationState.datadef(info.dsname));

    if (datadef) this.fielddefs = datadef;

    if (this.ondataLoaded) {
      this.ondataLoaded(data);
    }
  }
}
