import {
  ModuleWithProviders,
  NgModule,
  NgModuleRef,
  Optional,
  SkipSelf,
} from '@angular/core';
import { ClipboardModule } from '@angular/cdk/clipboard';
import {
  provideForRootGuard,
  WidgetsRegistryModule,
  WidgetsRegistryService,
} from '@ic-builder/widgets-registry';
import { Iscontextoverlay2Component } from './iscontextoverlay2/iscontextoverlay2.component';
import { Isform2Component } from './isform2/isform2.component';
import { Outlet2Component } from './outlet2/outlet2.component';
import { NgxsModule } from '@ngxs/store';
import { Outlet2Service } from './outlet2.service';
import { IS_BASE_FORROOT_GUARD } from './symbols';
import { ComponentsService } from './components.service';
import { IspageformComponent } from './ispageform/ispageform.component';
import { CommonModule } from '@angular/common';
import {
  IsCloudService,
  IsCloudState,
  SpeedTestService
} from '@ic-builder/data-access-iscloud';

let instanceNr = 0;

@NgModule({
  imports: [
    CommonModule,
    ClipboardModule,
    NgxsModule.forFeature([IsCloudState, Outlet2Service]),
    WidgetsRegistryModule.forChild({
      module: { ngModule: IsBaseModule },
      components: {
        iscontext2overlay: Iscontextoverlay2Component,
        isform: Isform2Component,
        isoutlet: Outlet2Component,
        ispageform: IspageformComponent,
        ispagecontrol: IspageformComponent,
      },
    }),
  ],
  declarations: [
    Iscontextoverlay2Component,
    Isform2Component,
    Outlet2Component,
    IspageformComponent,
  ],
  exports: [
    Iscontextoverlay2Component,
    Isform2Component,
    Outlet2Component,
    IspageformComponent,
  ],
  providers: [IsCloudService,SpeedTestService],
})
export class IsBaseModule {
  #instanceNr = instanceNr++;

  static forRoot(): ModuleWithProviders<IsBaseModule> {
    return {
      ngModule: IsBaseModule,

      providers: [        
        ComponentsService,
        //Outlet2Service,
        {
          provide: IS_BASE_FORROOT_GUARD,
          useFactory: provideForRootGuard,
          deps: [
            [ ComponentsService,Outlet2Service, new Optional(), new SkipSelf()],
          ],
        },
      ],
    };
  }

  static forChild(): ModuleWithProviders<IsBaseModule> {
    return {
      ngModule: IsBaseModule,
    };
  }

  constructor(
    moduleRef: NgModuleRef<IsBaseModule>,
    widgetsRegistryService: WidgetsRegistryService
  ) {
    widgetsRegistryService.setEagerModuleRef('IsBaseModule', moduleRef);
    // console.trace(
    //   `%cClass: IsBaseModule, Function: constructor(this.#instanceNr): `,
    //   'color: black;',
    //   this.#instanceNr
    // );
  }
}
