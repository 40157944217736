import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  Input,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ComponentsService,
  EventMap,
  Outlet2Service,
} from '@ic-builder/is-base';
import { LoadEnumlist } from '../../isenum/enum.action';
import { EnumerationItem } from '../../isenum/enum.model';
import { EnumState } from '../../isenum/enum.state';
import { IsgridComponent } from '../../isgrid/isgrid.component';
import { IsGridRegistry } from '../../isgrid/isgrid.service';
import { IscontrolComponent } from '../iscontrol.component';

@Component({
  selector: 'isenumgrid',
  templateUrl: './isenumgrid.component.html',
  styleUrls: ['./isenumgrid.component.scss'],
})
export class IsenumgridComponent
  extends IscontrolComponent
  implements OnInit, AfterViewInit
{
  @ViewChild('#grid', { read: ComponentRef })
  grid: ComponentRef<IsgridComponent>;
  @Input() initialValue: string;
  @Input() label: string;
  @Input() enum: string;
  listname: string;
  enumerationlist$: Observable<EnumerationItem[]>;
  enumlist: any;
  private subscriptions: Array<Subscription> = [];

  controlidID: number;
  controlidDesc: number;
  controlEnum: string;
  controlCallback: string;
  controlFormfunctions: number;

  formControlID: FormControl;
  formControlDesc: FormControl;
  formControlEnum: FormControl;
  formControlCallback: FormControl;
  formControlFormfunctions: FormControl;
  formFunctions: FormControl;
  eventmap: EventMap;
  prevEnum: string;

  columns = [
    { name: 'id', label: 'No', width: '25%' },
    { name: 'description', label: 'Libelle', width: '75%' },
  ];

  constructor(
    comp: ComponentsService,
    public outlet: Outlet2Service,
    public store: Store,
    private gridReg: IsGridRegistry,
    public NgZone: NgZone,
    public el: ElementRef,
    public cd: ChangeDetectorRef
  ) {
    super(comp, store, outlet, NgZone, null, el, cd);
  }

  ngOnInit(): void {
    if (this.formInput) {
      this.listname = this.id.toString() + '.enumdesc';
      this.controlidID = super.initControl(
        this.id.toString() + '.enumid',
        null,
        'enumid'
      );
      this.controlidDesc = super.initControl(
        this.id.toString() + '.enumdesc',
        null,
        'enumdesc',
        false
      );
      this.controlEnum = super.initControl(
        this.id.toString() + '.enum',
        null,
        'enum',
        false
      );
      this.controlCallback = super.initControl(
        this.id.toString() + '.callback',
        null,
        'callback',
        false
      );
      this.controlFormfunctions = super.initControl(
        this.id.toString() + '.formfunctions',
        null,
        'formfunctions',
        false
      );

      this.formControlID = super.getFormControl(this.controlidID);
      this.formControlDesc = super.getFormControl(this.controlidDesc);
      this.formControlEnum = super.getFormControl(this.controlEnum);
      this.formControlCallback = super.getFormControl(this.controlCallback);
      this.formControlFormfunctions = super.getFormControl(
        this.controlFormfunctions
      );
    } else {
      this.formControlID = new FormControl(this.initialValue);
      this.formControlDesc = new FormControl();
    }
  }

  ngAfterViewInit() {
    this.formControlID.valueChanges.subscribe((a) => {
      /* set record on value */
      console.log(this.formControlID.value);
    });

    this.enumerationlist$ = this.store.select(EnumState.getenumlist).pipe(
      map((filterFn) => {
        // this.gridReg.get(this.id).clearGrid();
        return filterFn(this.formControlEnum.value);
      })
    );

    this.formControlEnum.valueChanges.subscribe((a) => {
      /* set record on value */
      if (this.prevEnum != this.formControlEnum.value) {
        this.gridReg.get(this.id).clearGrid();
        this.store.dispatch(new LoadEnumlist(this.formControlEnum.value));
        //this.cd.detectChanges();

        this.prevEnum = this.formControlEnum.value;
      }
    });
    this.formControlCallback.valueChanges.subscribe((a) => {
      /* set record on value */
      this.controlCallback = this.formControlCallback.value;
    });
    this.formControlFormfunctions.valueChanges.subscribe((a) => {
      /* set record on value */
      this.controlFormfunctions = this.formControlFormfunctions.value;
    });
  }

  controlnameValueChanges(newVal) {
    this.comp.formcontrols.get(this.controlidID.toString()).name =
      newVal + 'ID';
    this.comp.formcontrols.get(this.controlidDesc.toString()).name =
      newVal + 'Desc';
  }

  enumValueChanges(val) {
    const cmpRef = this.gridReg.get(this.id);
  }

  onLoaded() {
    this.enumerationlist$ = this.store.select(EnumState.getenumlist).pipe(
      map((filterFn) => {
        return filterFn(this.formControlEnum.value);
      })
    );

    if (this.formControlEnum.value) {
      this.store.dispatch(
        new LoadEnumlist(this.formControlEnum.value, this.id)
      );
    }
  }

  onDestroy() {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }
  /*
  gridButtonOK() {
    let gridref = this.gridReg.get(this.id);
    let id = gridref.data[gridref.selectedRowId + gridref.scrolledItems].id;
    let desc = gridref.data[gridref.selectedRowId + gridref.scrolledItems].description;
    this.formControlID.setValue(id);
    this.formControlDesc.setValue(desc);
    //this.setDesc(id);
    this.gridButtonCancel();
  }

  gridButtonCancel() {
    this.NgZone.run(() => {
      this.dialogRef.close();
    });
  }*/

  reset() {
    this.formControlID.setValue(null);
    this.formControlDesc.setValue(null);
  }
}
