<!-- <div [formGroup]="frmGrp" class="row"> -->
<div class="content" id="labeltxt">
  {{ label }}
</div>

<div class="tagsinput">
  <div class="tag" *ngFor="let tag of tags">
    <span>{{ tag }}</span>
    <button class="button" (click)="removeTag(tag)">x</button>
  </div>
  <input
    *ngIf="controlname"
    list="dataList"
    (keydown)="keydown($event)"
    type="text"
    class="tags"
    [formControl]="localGroup.get('tempval')"
  />
  <input
    *ngIf="!controlname"
    list="dataList"
    type="text"
    class="tags"
    disabled
  />
  <datalist id="dataList" tab="0" (onclick)="click()">
    <option *ngFor="let option of options" [value]="option"></option>
  </datalist>
</div>
<mat-icon istag="error" *ngIf="!valid" style="color: red">error</mat-icon>
<mat-icon
  istag="warning"
  *ngIf="!warning"
  style="color: orange"
  (mouseenter)="openwarning($event)"
  (mouseleave)="closewarning()"
  >warning</mat-icon
>
<!-- </div> -->

<ng-template #warningMes>
  <div class="warning">
    <span *ngFor="let warn of warningMessages">
      {{ warn }}
    </span>
  </div>
</ng-template>
