import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { IscontrolComponent } from './../iscontrol.component';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ComponentsService, Outlet2Service } from '@ic-builder/is-base';
import { Store } from '@ngxs/store';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'issizeinput',
  templateUrl: './issizeinput.component.html',
  styleUrls: ['./issizeinput.component.scss'],
})
export class IssizeinputComponent
  extends IscontrolComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() label: string;
  @Input() options: string[];
  @Input() initialValue: string;

  private subscriptions: Array<Subscription> = [];

  values: string[] = [];

  sizeGroup: FormGroup;
  controlid: number;
  formControl: FormControl;

  constructor(
    comp: ComponentsService,
    public outlet: Outlet2Service,
    public store: Store,
    public NgZone: NgZone,
    public dialog: MatDialog,
    public el: ElementRef,
    public cd: ChangeDetectorRef
  ) {
    super(comp, store, outlet, NgZone, dialog, el, cd);
  }

  ngOnInit(): void {
    if (this.formInput) {
      this.controlid = super.initControl(this.controlid, this.initialValue);
      this.formControl = super.getFormControl(this.controlid);
    } else {
      this.formControl = this.formControl
        ? this.formControl
        : new FormControl(this.initialValue);
    }

    this.sizeGroup = new FormGroup({});
    this.updateInputs();

    // if (this.controlname) {
    this.subscriptions.push(
      this.formControl.valueChanges.subscribe((a) => {
        if (a) {
          const values = this.splitSizeValue(a);
          this.sizeGroup.get('value').setValue(values[0]);
          this.sizeGroup.get('unit').setValue(values[1]);
        } else {
          this.sizeGroup.get('value').setValue('');
          this.sizeGroup.get('unit').setValue(this.options[0]);
        }
      })
    );
    // }
  }

  ngAfterViewInit(): void {
    let e = this.el.nativeElement;
    const el = this.el.nativeElement.querySelector('.input');
  }

  afterformReady() {
    if (this.formControl) {
      if (this['valueChanges']) {
        this.formControl.valueChanges.subscribe((item) => {
          this['valueChanges'](this.formControl.value);
        });
      }
    }
  }

  ngOnDestroy() {
    // super.destroyControls([this.controlname])
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  updateInputs() {
    if (this.formControl) {
      if (this.formControl.value) {
        // this.sizeGroup.addControl('combined', new FormControl(this.frmGrp.get(this.controlname).value))
        const values = this.splitSizeValue(this.formControl.value);
        this.sizeGroup.addControl('value', new FormControl(values[0]));
        this.sizeGroup.addControl('unit', new FormControl(values[1]));
      } else {
        this.sizeGroup.addControl('value', new FormControl(''));
        this.sizeGroup.addControl('unit', new FormControl(this.options[0]));
        // this.sizeGroup.addControl('combined', new FormControl('' + this.options[0]))
      }
    } else {
      this.sizeGroup.addControl('value', new FormControl(''));
      this.sizeGroup.addControl('unit', new FormControl(this.options[0]));
      // this.sizeGroup.addControl('combined', new FormControl('' + this.options[0]))
    }
    this.values[0] = this.sizeGroup.get('value').value;
    this.values[1] = this.sizeGroup.get('unit').value;
  }

  splitSizeValue(value: string): string[] {
    if (value.startsWith('calc')) {
      return [value.substr(5, value.length - 6), 'calc'];
    } else {
      let splitIndex;

      for (let i = 0; i < this.options.length; i++) {
        const index = value.lastIndexOf(this.options[i]);
        if (index != -1) {
          splitIndex = index;
          break;
        }
      }

      const val = value.substr(0, splitIndex);
      const unit = value.substr(splitIndex, value.length);

      return [val, unit];
    }
  }

  valueChange() {
    this.sizeGroup
      .get('value')
      .valueChanges.pipe(take(1))
      .subscribe((a) => {
        if (a || a === 0) {
          this.values[0] = a.toString();
        } else {
          this.values[0] = null;
        }
        this.inputChange();
      });
  }

  unitChange() {
    this.values[1] = this.sizeGroup.get('unit').value;
    this.inputChange();
  }

  inputChange() {
    if (this.values[0]) {
      if (this.values[1] != 'calc') {
        this.formControl.setValue(this.values[0].toString() + this.values[1]);
      } else {
        this.formControl.setValue('calc(' + this.values[0].toString() + ')');
      }
    } else {
      this.formControl.setValue(null);
      this.formControl.setValue(this.values[1]);
    }
  }
}
