import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ComponentsService, Outlet2Service } from '@ic-builder/is-base';
import { Store } from '@ngxs/store';
import { IscontrolComponent } from '../iscontrol.component';

@Component({
  selector: 'ic-builder-isradio2',
  templateUrl: './isradio2.component.html',
  styleUrls: ['./isradio2.component.scss'],
})
export class Isradio2Component extends IscontrolComponent implements OnInit, AfterViewInit {
  @Input() label: string;
  @Input() initialValue: any = null;
  @Input() value: any;
  @Input() options: any[] = [];
  @Input() initialOptions = '';
  @Input() usecheckmarks = false;

  controlid: number;
  formControl: FormControl;
  data: any;
  datafield: string;
  optionsfield: string;
  get fc() {
    return this.formControl;
  }

  get fcv() {
    return this.formControl.value;
  }

  constructor(
    comp: ComponentsService,
    public outlet: Outlet2Service,
    public store: Store,
    public NgZone: NgZone,
    public dialog: MatDialog,
    public el: ElementRef,
    public cd: ChangeDetectorRef
  ) {
    super(comp, store, outlet, NgZone, dialog, el, cd);
  }

  ngOnInit(): void {
    if (this.formInput) {
      if (!this.initialOptions) {
        this.initialValue = this.options.filter((item) => {
          if (item.default) return item;
        });
        if ((Array.isArray(this.initialValue)) &&  (this.initialValue.length>0)){
          this.initialValue = this.initialValue[0].value;
        }
      }

      this.controlid = super.initControl(this.id.toString() + '.radio2', this.initialValue, 'radio2');
      this.formControl = super.getFormControl(this.controlid);
    } else {
      this.formControl = new FormControl(this.initialValue);
    }
  }

  ngAfterViewInit() {
    if (this.optionsfield && this.data) {
      if (this.data[this.optionsfield]) {
        this.options = this.data[this.optionsfield].map((item) => {
          if (item.value_) {
            item['value'] = item.value_;
          }
          return item;
        });
        this.cd.detectChanges();
      }
    }
    if (this.optionsfield && !this.data && this.initialValue){
      this.formControl.setValue(this.initialValue);
    }
    if (this.initialOptions) {
      if (this.initialOptions == 'datafield') {
        // this.initialValue = this.options?.filter((item) => {
        //   if (this.data) {
        //     if (item.value == this.data[this.datafield]) {
        //       return item;
        //     }
        //   }
        // });
        this.initialValue = this.options?.find((item) => item.value === this.data[this.datafield])?.value;
        if ((Array.isArray(this.initialValue)) &&  (this.initialValue.length>0)){
          this.initialValue = this.initialValue[0].value;
        }
        if (this.initialValue) {          
          this.formControl.setValue(this.initialValue);
        } 
      }
    }
  }
}
