import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import { ComponentsService } from '@ic-builder/is-base';

@Component({
  selector: 'iswizpage',
  templateUrl: './iswizpage.component.html',
  styleUrls: ['./iswizpage.component.scss'],
})
export class IswizpageComponent implements OnInit, AfterViewInit {
  title: string;
  @Input('id') id: number;
  parentid: number;
  sizes: any = [];
  nextFunction: string;
  validatorFunctions: any[] = [];

  valFunctions: Function[] = [];

  @ViewChildren('children', { read: ViewContainerRef })
  public children: QueryList<ViewContainerRef>;

  next = () => {
    return 1;
  };

  constructor(private comp: ComponentsService, private el: ElementRef) {}

  fakeNativeDrop() {
    this.el.nativeElement.focus();
  }

  ngOnInit() {
    if (this.nextFunction) {
      const func = new Function('pageForm', this.nextFunction);

      this.next = () => {
        const formGroup = this.comp.widgets.get(this.parentid).instance
          .wizFormGroup.value;
        return Number(func(formGroup));
      };
    }
    if (this.validatorFunctions) {
      this.validatorFunctions.map((val) => {
        this.valFunctions.push(new Function('pageForm', val.code));
      });
    }
  }

  valid() {
    const changed = [];
    let valid = true;
    const formGroup = this.comp.widgets.get(this.parentid).instance.wizFormGroup
      .value;

    for (let i = 0; i < this.valFunctions.length; i++) {
      if (!this.valFunctions[i](formGroup)) {
        valid = false;
        const regex = new RegExp(/pageForm\[.*?\]/g);
        const found = this.validatorFunctions[i].code.match(regex);

        found.map((f) => {
          const id = Number(f.substring(10).split('.')[0]);
          this.comp.widgets.get(id).instance.warning = false;

          if (
            !this.comp.widgets
              .get(id)
              .instance.warningMessages.includes(
                this.validatorFunctions[i].errormessage
              )
          ) {
            this.comp.widgets
              .get(id)
              .instance.warningMessages.push(
                this.validatorFunctions[i].errormessage
              );
            changed.push(Number(f.substring(10).split('.')[0]));
          }
        });
      } else {
        const regex = new RegExp(/pageForm\[.*?\]/g);
        const found = this.validatorFunctions[i].code.match(regex);

        found.map((f) => {
          const tochange = Number(f.substring(10).split('.')[0]);
          if (!changed.includes(tochange)) {
            this.comp.widgets.get(tochange).instance.warning = true;
          }
        });
      }
    }

    this.sizes.forEach((c) => {
      const component = this.comp.widgets.get(c.id); //.instance;
      if (component.instance.validators) {
        let control;
        if (component.instance.type === 'isgrid') {
          control = this.comp.formcontrols.get(
            component.instance.id.toString() + '.' + 'select'
          );
        } else {
          control = this.comp.formcontrols.get(
            component.instance.id.toString() +
              '.' +
              component.instance.controlname
          );
        }
        if (control) {
          /* if grid has a selection set */
          if (control.formcontrol ? !control.formcontrol.valid : false) {
            valid = false;
            component.instance.valid = false;
            component.location.nativeElement.classList.add('invalid');
          } else {
            component.instance.valid = true;
            component.location.nativeElement.classList.remove('invalid');
          }
        } else {
          /* grid without selectionset */
        }
        component.changeDetectorRef.detectChanges();
      }
    });
    return valid;
  }

  ngAfterViewInit() {
    this.el.nativeElement.addEventListener('dragover', function (ev) {
      ev.preventDefault();
    });
  }

  nextFunctionValueChanges(newVal) {
    const func = new Function('pageForm', newVal);

    this.next = () => {
      const formGroup = this.comp.widgets.get(this.parentid).instance
        .wizFormGroup.value;
      return Number(func(formGroup));
    };
  }

  validatorFunctionsValueChanges(newVal) {
    this.valFunctions.push(new Function('pageForm', newVal.code));
    //this.validatorFunctions.push(newVal)
    return true;
  }

  saveInfo() {}
}
