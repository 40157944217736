<span class="header">
  <span class="material-icons" (click)="toggleView()">{{ toggled ? 'chevron_right' : 'expand_more' }}</span>
  <span class="sql-statement">{{ toggled ? formControl.value : '' }}</span>
  <button (click)="applyFilter()" *ngIf="topLevel">{{lblExecute}}</button>
  <span class="material-icons" (click)="addNode()">add</span>
  <span class="material-icons" *ngIf="!topLevel" (click)="triggerRemoveNode()">remove</span>
</span>
<div class="container" [style.display]="toggled ? 'none' : 'flex'">
  <ng-template #container></ng-template>
</div>
