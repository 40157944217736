import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import {
  addEnumFromInterclean,
  addSelectionEnum,
  ClearEnumList,
  clearSelectionEnum,
  LoadEnumlist,
  setSelectionEnum,
} from './enum.action';
import { Enumeration, EnumerationItem } from './enum.model';
import { Injectable } from '@angular/core';
import { IsCloudService } from '@ic-builder/data-access-iscloud';
import { ComponentsService } from '@ic-builder/is-base';
import { IsApplicationState } from '../isapplication/isapplication.state';
import { db } from '@ic-builder/is-base';
import { from } from 'rxjs';

export interface EnumStateModel {
  loading: boolean;
  enumeration: Enumeration[];
  firstLoad: Array<boolean>;
  triggercounter: number;
}

@State<EnumStateModel>({
  name: 'enumstate',
  defaults: {
    enumeration: [],
    loading: false,
    firstLoad: [true, true, true, true, true],
    triggercounter: 0,
  },
})
@Injectable()
export class EnumState {
  constructor(
    private ic: IsCloudService,
    private store: Store,
    private comp: ComponentsService
  ) {}

  @Selector()
  static getenumlist(state: EnumStateModel) {
    return (group: string) => {
      const e = state.enumeration.filter((a) => a.group === group);
      if (e !== null) {
        if (e.length > 0) {
          return e[0].items;
        }
      }
    };
  }

  @Selector()
  static getenum(state: EnumStateModel) {
    return (group: string) => {
      const e = state.enumeration.filter((a) => a.group === group);
      if (e !== null) {
        if (e.length > 0) {
          return e[0];
        }
      }
    };
  }

  @Selector()
  static triggercounter(state: EnumStateModel) {
    return state.triggercounter;
  }

  @Selector()
  static getenumselection(state: EnumStateModel) {
    return (group: string) => {
      return state.enumeration.filter((a) => a.group === group)[0].selection;
    };
  }

  static enumlist(group: string) {
    return createSelector([EnumState], (state: EnumStateModel[]) => {
      return state.filter((s) => {
        //filter?
        //  s.enumeration.filter((a) => a.group === group).items.filter((a => a.data === filter)):
        s.enumeration.filter((a) => a.group === group);
      });
    });
  }

  static enumlistfilter(group: string) {
    return createSelector([EnumState], (state: EnumStateModel[]) => {
      return state.filter((s) => {
        //filter?
        //  s.enumeration.filter((a) => a.group === group).items.filter((a => a.data === filter)):
        s.enumeration.filter((a) => a.group === group);
      });
    });
  }

  static enumlistselection(group: string) {
    return createSelector([EnumState], (state: EnumStateModel[]) => {
      return state.filter((s) => {
        s.enumeration.filter((a) => a.group === group)[0].selection;
      });
    });
  }

  @Selector()
  public static loading(state: EnumStateModel) {
    return state.loading;
  }

  @Action(ClearEnumList)
  clearEnumList({ getState, patchState }: StateContext<EnumStateModel>) {
    const temp = [true, true, true, true, true, true];
    patchState({
      firstLoad: temp,
      enumeration: [],
    });
  }

  processenum(state, patchState, id, grp, temp, enums) {
    if (!Array.isArray(enums)) {
      return;
    }
    let groep = '';
    const e = state.enumeration;
    let i, j: number;
    i = e.length - 1;
    if (enums.length > 0) {
      if (!enums[0].group_) {
        this.store.dispatch({
          type: '[AppError]',
          payload: {
            code: 1000,
            source: 'enumlist',
            message: 'Enumlist has no group_ field ',
            level: 'error',
          },
        });
      }
    }

    for (const x of enums) {
      if (x.group_ !== groep) {
        if (e[i]?.items) {
          const x = this.storeDatasetLocal(e[i].group, e[i].items);
        }
        i++;

        e[i] = new Enumeration();
        e[i].group = x.group_;
        j = 0;
        e[i].items = new Array<EnumerationItem>();
        e[i].items[j++] = x;
        groep = x.group_;
      } else {
        e[i].items[j++] = x;
      }
    }

    if (id != null) {
      this.comp.widgets.get(id)?.instance?.enumValueChanges?.(grp);
    }

    patchState({
      enumeration: e,
      loading: false,
      firstLoad: temp,
    });
  }

  async loadenum(state, patchState, id, grp, acc, temp, loadoffline, type) {
    const dslocalExists =
      !window.navigator.onLine || loadoffline
        ? await db.enumerations.get({ name: grp })
        : null;

    const x = dslocalExists
      ? from(
          db.enumerations.get(grp).then((c) => {
            return c?.records;
          })
        )
      : type == 'number'
      ? this.ic.getData('enumeration', { role: '1', group: grp, account: acc })
      : this.ic.getData('enumeration_str', {
          role: '1',
          group: grp,
          account: acc,
        });

    x.subscribe((enums) => {
      this.processenum(state, patchState, id, grp, temp, enums);
      if (!dslocalExists) {
        const x = this.storeDatasetLocal(grp, enums);
      }
    });
  }

  async storeDatasetLocal(datasetname: string, records: any[]) {
    try {
      await db.enumerations.put({
        name: datasetname,
        records: records,
      });
    } catch (error) {
      console.log('fout schrijven cache enum ', datasetname, ' ', error);
    }
  }

  @Action(LoadEnumlist)
  getEnumList(
    { getState, patchState }: StateContext<EnumStateModel>,
    { payload, id, account, loadoffline, typeenum }: LoadEnumlist
  ) {
    const state = getState();
    if (!typeenum){typeenum='number'};
    if (state.firstLoad[4] === true || payload !== '') {
      // temp[4] = false;
      //patchState({
      //  firstLoad: [...state.firstLoad].splice(4, 1, false),
      //});
      const temp = getState().firstLoad;

      const enums: EnumerationItem[] = [];
      const acc = account ?? this.store.selectSnapshot(IsApplicationState.getaccount());

      if (payload === '') {
        this.ic.getData('enumlist', { role: '1', account: acc }).subscribe((enums) => {
          this.processenum(state, patchState, id, payload, temp, enums);

          // if (!Array.isArray(enums)) {
          //   return;
          // }
          // let groep = '';
          // const e = state.enumeration;
          // let i, j: number;
          // i = e.length - 1;
          // for (const x of enums) {
          //   if (x.groep !== groep) {
          //     i++;
          //     e[i] = new Enumeration();
          //     e[i].group = x.groep;
          //     j = 0;
          //     e[i].items = new Array<EnumerationItem>();
          //     e[i].items[j++] = x;
          //     groep = x.groep;
          //   } else {
          //     e[i].items[j++] = x;
          //   }
          // }

          // patchState({
          //   enumeration: e,
          //   loading: false,
          //   firstLoad: temp,
          // });
        });
      } else {
        let groups = payload.split(',');
        let grp = '';

        groups.map((group) => {
          const e = state.enumeration.filter((a) => {
            return a.group === group;
          });
          if (e.length === 0) {
            grp = grp + (grp.length == 0 ? group : ',' + group);
          }
        });

        groups = payload.split(',');
        if (groups.length > 1) {
          groups.map((item) => this.loadenum(state, patchState, id, item, acc, temp, loadoffline, typeenum));
        } else {
          if (grp != '') {
            this.loadenum(state, patchState, id, grp, acc, temp, loadoffline, typeenum);
          } else {
            patchState({
              triggercounter: state.triggercounter++,
            });
          }
        }
      }
    }
  }

  @Action(setSelectionEnum)
  setSelectionEnum(
    { getState, patchState }: StateContext<EnumStateModel>,
    { payload }: setSelectionEnum
  ) {
    const state = getState();
    const e = state.enumeration.filter((a) => a.group === payload[0].groep);
    if (e.length === 1) {
      state.enumeration[state.enumeration.indexOf(e[0])].selection = payload;
      patchState({ ...state, enumeration: state.enumeration });
    }
  }

  @Action(addSelectionEnum)
  addSelectionEnum(
    { getState, patchState }: StateContext<EnumStateModel>,
    { payload }: addSelectionEnum
  ) {
    const state = getState();
    const e = state.enumeration.filter((a) => a.group === payload[0].groep);
    if (e.length === 1) {
      state.enumeration[state.enumeration.indexOf(e[0])].selection = [
        ...payload,
      ];
      patchState({ ...state, enumeration: state.enumeration });
    }
  }

  @Action(clearSelectionEnum)
  clearSelectionEnum(
    { getState, patchState }: StateContext<EnumStateModel>,
    { payload }: clearSelectionEnum
  ) {
    const state = getState();
    const e = state.enumeration.filter((a) => a.group === payload);
    if (e.length === 1) {
      state.enumeration[state.enumeration.indexOf(e[0])].selection = [];
      patchState({ ...state, enumeration: state.enumeration });
    }
  }

  @Action(addEnumFromInterclean)
  addEnumFromInterclean(
    { getState, patchState }: StateContext<EnumStateModel>,
    { payload }: addEnumFromInterclean
  ) {
    const state = getState();
    const enums = state.enumeration;
    let existing = false;

    for (const x of enums) {
      if (x.group === payload.group) {
        x.items = payload.items;
        existing = true;
      }
    }

    if (existing) {
      console.log('enumerations known', enums);
      patchState({ ...state, enumeration: enums });
    } else {
      enums.push(payload);
      console.log('enumerations', enums);
      patchState({ ...state, enumeration: enums });
    }
  }
}
