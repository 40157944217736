import { isContextOverlay } from './iscontextoverlay2.service';
import { ComponentsService } from '../components.service';
import { Store } from '@ngxs/store';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { LoadComponent, TrackLoading } from '../base.actions';

@Component({
  selector: 'iscontextoverlay2',
  templateUrl: './iscontextoverlay2.component.html',
  styleUrls: ['./iscontextoverlay2.component.scss'],
})
export class Iscontextoverlay2Component implements AfterViewInit {
  @ViewChild('content', { read: ViewContainerRef }) content: ViewContainerRef;

  id: number;
  childid: number;
  contextRef;
  parentElement;
  origin: number = 0;
  info: any = null;
  data: any = null;
  loadas: number;
  outlet: number;
  onclose: any;
  oncancel: any;
  menu = false;
  overlayRef;
  ischild: any;
  isparent: any;

  constructor(
    private store: Store,
    private comp: ComponentsService,
    private el: ElementRef,
    private context: isContextOverlay,
    private cd: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    console.log(`%cClass: Iscontextoverlay2Component, Function: ngAfterViewInit(): `, 'color: black;');
    if (this.menu) {
      this.context.setContextActive(this.id);

      this.context.activemenu.subscribe((activeid) => {
        console.log(`%cClass: Iscontextoverlay2Component, Function: activemenu.subscribe(): `, 'color: black;');
        if (activeid !== this.id) {
          if (this.content.length > 0) {
            this.content.detach();
          }
          this.contextRef.detach();
        }
      });
    }

    this.store.dispatch(
      new LoadComponent({ id: this.id, name: 'context', data: this.data, loadas: this.loadas, outlet: this.outlet, isparent:this.isparent })
    );

    const tmpid = this.loadas ? this.loadas : this.childid;
    if (!this.comp.widgets.get(tmpid)) {
      this.store.dispatch(new TrackLoading(tmpid));

      this.store
        .select((state) => state['outletstate'].comploaded.get(tmpid))
        .subscribe((loaded) => {
          if (loaded) {
            const cmp = this.comp.gci(tmpid);
            const cmpRef = this.comp.gcr(tmpid);
            if (this.origin) {
              cmp.setOrigin(this.origin);
            }
            if (this.info) {
              cmp.setInfo ? cmp.setInfo(this.info, this.data) : null;
            }
            if (!cmp.close) {
              cmp.close = this.close;
            }
            if (!cmp.oncancel) {
              cmp.oncancel = this.oncancel;
            }            
            if (this.onclose) {
              cmp.onclose = this.onclose;
            }
            cmp.overlayRef = this.overlayRef;
            cmp.content = this.content;
            //this.content.insert(cmpRef?.hostView);
            cmpRef ? this.content.insert(cmpRef.hostView) : null;
            this.setData(this.data);
            this.ischild = cmp;
            if (this.ischild['afteropendia']) {
              this.ischild['afteropendia'](this.data);
            }
          }
        });
    } else {
      const cmpRef = this.comp.gcr(tmpid);
      const cmp = this.comp.gci(tmpid);
      cmpRef ? this.content.insert(cmpRef.hostView) : null;

      if (this.origin) {
        if (cmp?.setOrigin){
          cmp?.setOrigin(this.origin);
        }
      }

      if (this.info) {
        if (cmp?.setInfo){
          cmp?.setInfo(this.info, this.data);
        }
      }

      if (!cmp?.close) {
        cmp.close = this.close;
      }
      if (this.onclose) {
        cmp.onclose = this.onclose;
      }
      if (this.oncancel) {
        cmp.oncancel = this.oncancel;
      }      
      cmp.overlayRef = this.overlayRef;
      cmp.content = this.content;

      this.setData(this.data);
      this.ischild = cmp;
      if (this.ischild['afteropendia']) {
        this.ischild['afteropendia'](this.data);
      }
    }
  }

  close() {
    if (this.overlayRef) {
      this.overlayRef.detach();
      //this.changeDetectorRef.detectChanges()
    }
  }

  onLoaded() {
    this.content.insert(this.comp.widgets.get(this.childid).hostView);
  }

  setData(data) {
    const compRef = this.comp.widgets.get(this.childid);
    if (compRef) {
      compRef.instance.data = data;
      if (compRef.instance.setData) {
        compRef.instance.setData(data);
      }
    } else {
      this.data = data;
    }
  }

  addClickListner() {
    const clickListener = {
      handleEvent: () => {
        if (this.content.length > 0) {
          this.content.detach(0);
        }
        this.contextRef.detach();
        this.cd.detectChanges();
        this.el.nativeElement.click();
      },
    };

    window.addEventListener('click', clickListener);
  }
}
