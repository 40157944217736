import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'lib-isicon',
  templateUrl: './isicon.component.html',
  styleUrls: ['./isicon.component.scss'],
})
export class IsiconComponent implements OnInit {
  @Input('icon') icon: string;
  constructor() {}

  ngOnInit(): void {}
}
