import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
  selector: 'iscustomselect',
  templateUrl: './iscustomselect.component.html',
  styleUrls: ['./iscustomselect.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IscustomselectComponent),
      multi: true,
    },
  ],
})
export class IscustomselectComponent
  implements OnInit, OnDestroy, ControlValueAccessor
{
  @Input() options: any[] = [];
  @HostListener('click')
  click() {
    this.open = !this.open;
    this.cd.detectChanges();
  }
  control = new FormControl();
  open: boolean = false;

  constructor(private cd: ChangeDetectorRef, private el: ElementRef) {}

  windowListener = (event: any) => {
    const select = this.el.nativeElement;
    if (!select.contains(event.target)) {
      this.open = false;
      this.cd.detectChanges();
    }
  };

  ngOnInit(): void {
    window.addEventListener('click', this.windowListener);
  }

  ngOnDestroy(): void {
    window.removeEventListener('click', this.windowListener);
  }

  select(option: any): void {
    // set value internally
    this.control.setValue(option.value ?? option);
    // let angular know the value has been changed
    this._onChange(option.value ?? option);
  }

  // ControlValueAccessor implementation
  _onChange: (_: any) => void = (_) => {};

  writeValue(value: any): void {
    this.control.setValue(value);
  }

  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    //
  }
}
