import { AfterViewInit, Component, ElementRef, Input } from '@angular/core';
import { ComponentsService } from '@ic-builder/is-base';
import { Store } from '@ngxs/store';

@Component({
  selector: 'ic-builder-isupload',
  templateUrl: './isupload.component.html',
  styleUrls: ['./isupload.component.scss'],
})
export class IsuploadComponent implements AfterViewInit {
  constructor(
    private el: ElementRef,
    private store: Store,
    private comp: ComponentsService
  ) {}

  @Input() icon: string;
  @Input() accept = '*';
  onupload: Function = null;

  ngAfterViewInit() {
    this.el.nativeElement.classList.add('material-icons');
  }

  getImg(ev) {
    const files = ev.target.files;
    const file = files[0];
    console.log(file.name);

    const reader = new FileReader();
    reader.onload = (e) => {
      if (this.onupload) {
        this.onupload(e.target.result);
      }
    };
    reader.readAsDataURL(file);
  }
}
