<div class="row">
  <div istag="label" class="content islabel" id="labeltxt">
    {{ label }}
  </div>

  <input
    istag="minVal"
    type="range"
    [min]="min"
    [max]="max"
    [value]="value"
    [step]="step"
    [formControl]="formControl"
  />
  <input
    istag="maxVal"
    type="number"
    [min]="min"
    [max]="max"
    [value]="value"
    [formControl]="formControl"
  />

  <!-- <input disabled *ngIf="!controlname" type="range"  [min]="min" [max]="max" [value]="value" [step]="step"> -->
  <!-- <input disabled *ngIf="!controlname" type="number" [min]="min" [max]="max" [value]="value"> -->
  <mat-icon istag="error" *ngIf="!valid" style="color: red">error</mat-icon>
  <mat-icon
    istag="warning"
    *ngIf="!warning"
    style="color: orange"
    (mouseenter)="openwarning($event)"
    (mouseleave)="closewarning()"
    >warning</mat-icon
  >
</div>

<ng-template #warningMes>
  <div class="warning">
    <span *ngFor="let warn of warningMessages">
      {{ warn }}
    </span>
  </div>
</ng-template>
