import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ComponentsService, IsthemingService, Outlet2Service } from '@ic-builder/is-base';
import { Store } from '@ngxs/store';
import SignaturePad from 'signature_pad';
import { IscontrolComponent } from '../iscontrol/iscontrol.component';

@Component({
  selector: 'ic-builder-issignature',
  templateUrl: './issignature.component.html',
  styleUrls: ['./issignature.component.scss']
})
export class IssignatureComponent extends IscontrolComponent implements OnInit {

  constructor(
    public outlet: Outlet2Service,
    private compserv: ComponentsService,
    public store: Store,
    public NgZone: NgZone,
    public dialog: MatDialog,
    public el: ElementRef,
    public cd: ChangeDetectorRef,
    private themingService: IsthemingService
  ) {
    super(compserv, store, outlet, NgZone, dialog, el, cd);
   }

  formControl: FormControl;
  controlid: string;
  
  @Input('initialValue') initialValue: any;
  @Input('label') label: string = '';
  translation : any | null = null;
  canvasElement: HTMLCanvasElement;
  writeValue: Function;
  signPad: any;

  ngOnInit(): void {
    this.controlid = super.initControl(this.id.toString() + '.input', this.initialValue, 'input');
    this.formControl = super.getFormControl(this.controlid); 
  }

  registerSignature({ el, writeValue }) {
    // console.log(el, writeValue);
    this.canvasElement = el;
    this.writeValue = writeValue;
  }

  afterInsertInDom() {
    // this.el.nativeElement;
    const { width, height } = this.canvasElement.getBoundingClientRect();
    
    this.canvasElement.height = height;
    this.canvasElement.width = width;

    const theming = this.themingService.getActiveComposite();
    // console.log(theming.colorTheme.properties.mobileheader);
    this.signPad = new SignaturePad(this.canvasElement, { penColor: theming.colorTheme.properties.mobileheader });
    this.cd.detectChanges();
    
    this.writeValue(this.formControl.value);

    // debugger;
  }

  translate(lang){
    if (this.translation){
      this.label = this.translation[lang];
    }
  }

}
