<div class="menu">
  <span id="left" class="material-icons" *ngIf="!enoughSpace" (click)="moveLeft2($event)">chevron_left</span>
  <span *ngIf="indesign" class="material-icons" #addbutton (click)="addMenuItem()">add</span>
  <span *ngIf="indesign" class="material-icons" #delbutton (click)="deleteMenuItem()">delete</span>
  <div istag="menucontainer" class="menucontainer" id="menucontainer" *ngIf="!formid">
    <span
      class="menu-item"
      istag="menuitem"
      *ngFor="let m of menuItemsLocal; let idx = index"
      (click)="changeView(m, idx)"
    >
      <i class="material-icons" istag="icon">{{ m.icon }}</i>
      
      <div istag="label">{{ m.label}}</div>      
      <span
        *ngIf="indesign"
        istag="tagicon"
        class="manu-close-button material-icons icon"
        (click)="closetab(m, idx)"
        >close</span
      >
    </span>
  </div>
  <div class="menucontainer" id="menucontainer" istag="menucontainer" *ngIf="formid">
    <iscompiterator
      istag="compiterator"
      class="compiterator"
      [activeData]="menuItemsLocal"
      [xxx]="xxx"
      [menuId]="id"
      [formid]="formid"
      (onCmpRendered)="cmpRendered($event)"
    ></iscompiterator>
    <span class="selected-bar" istag="selectedbar"></span>
  </div>
  
  <span id="right" class="material-icons" *ngIf="!enoughSpace" (click)="moveRight2($event)">chevron_right</span>
</div>
<div class="outlet" istag="outlet">
  <ng-template #outlet></ng-template>
</div>

<!-- <div style="display: flex; flex-direction: column; height: 100%">
  <div istag="maincustom" class="main">
    <div class="pageform">
      <div style="width: 40px">
        <mat-icon
          istag="prev"
          style="-webkit-transform: scaleX(-1); transform: scaleX(-1); width: 40px"
          *ngIf="!enoughSpace"
          (click)="moveLeft()"
          >navigate_next</mat-icon
        >
      </div>

      <div class="content">
        <div class="pageformitem" *ngFor="let item of menuItems" (click)="changeView(item.hostView)">
          <ng-template #menuitemsComp></ng-template>
        </div>
      </div>
      <div style="width: 40px" (click)="addMenuItem()">
        <span class="material-icons" istag="add">add</span>
      </div>
      <div style="width: 40px">
        <mat-icon istag="next" *ngIf="!enoughSpace" style="width: 40px" (click)="moveRight()">navigate_next</mat-icon>
      </div>
    </div>
  </div>
  <div style="flex: 1">
    <ng-template #outlet></ng-template>
  </div>
</div> -->

<ng-template #addMenu let-data>
  <div class="addMenuItem">
    <span>Omschrijving</span><isinput id="-1011" formInput="true"></isinput>
    <!--<isselect controlname="form" label="form" [options]="options"></isselect>-->
    <span>Form</span> <isenum id="-1010" enum="FORM" formInput="true" [connectionid]="10"></isenum>
    <!-- <isselect [frmGrp]="newMenu" name="form" label="form" [options]="options"></isselect> -->
    <div class="isbuttonrow" style="display: flex; flex-direction: row">
      <isbutton class="isbutton" cfg='{"text":"close"}' (click)="close()"></isbutton>
      <isbutton class="isbutton" cfg='{"text":"oke"}' (click)="save()"></isbutton>
    </div>
  </div>
</ng-template>

<ng-template #deleteMenu>
  <div class="deleteMenu">
    <isgrid
      class="isgrid"
      [id]="-1100"
      [datamode]="3"
      [hasFormGroup]="false"
      [data]="deleteData"
      [columns]="deleteColumns"
    ></isgrid>
  </div>
</ng-template>
