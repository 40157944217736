<div istag="wizard" class="wizard">
  <div istag="wiztitle">
    <islabel istag="wiztitle_label" #wiztitle class="wiztitle_label" [label]="title"></islabel>
  </div>

  <div istag="wizoutlet" class="outlet">
    <ng-template #wizardoutlet></ng-template>
  </div>

  <div istag="wizbuttons" class="wizbuttons">
    <isbutton #wizcancel istag="wizbtn_cancel" class="isbutton" text="Annuleer" *ngIf="!btnIcons" (click)="cancel()"></isbutton>
    <isbutton
      #wizback
      istag="wizbtn_back"
      class="isbutton"
      hidden
      [text]="lastpage_backtext()"
      (click)="back()"
    ></isbutton>
    <isbutton
      #wiznext
      *ngIf="!btnIcons"
      istag="wizbtn_next"
      class="isbutton"
      [text]="lastpage_text()"
      (click)="index === children.length - 1 ? save() : next()"
    >
    </isbutton>
    <span
      *ngIf="btnIcons"
      class="material-icons isbutton"
      istag="wizbtn_cancel"
      (click)="index === children.length - 1 ? closewizard() : next()"
    >
      close
    </span>
    <span
      *ngIf="btnIcons"
      class="material-icons isbutton"
      istag="wizbtn_next"
      (click)="index === children.length - 1 ? save() : next()"
    >
      save
    </span>
  </div>
</div>
