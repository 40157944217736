<input istag="input" type="text" class="trigger" [formControl]="control" />
<div
  istag="options"
  class="options"
  [style.visibility]="open ? 'visible' : 'hidden'"
>
  <ng-container *ngFor="let option of options">
    <span istag="option" (click)="select(option)" class="option">{{
      option.description ?? option
    }}</span>
  </ng-container>
</div>
