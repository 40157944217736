import {
  AfterViewInit,
  Component,
  forwardRef,
  OnInit,
  ChangeDetectorRef,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  TemplateRef,
  ViewChild,
  SimpleChanges,
  SimpleChange,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  NG_VALUE_ACCESSOR,
  SelectMultipleControlValueAccessor,
} from '@angular/forms';
import { ComponentsService, Outlet2Service } from '@ic-builder/is-base';
import { IscontrolComponent } from '../iscontrol.component';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'isinput2',
  templateUrl: './isinput2.component.html',
  styleUrls: ['./isinput2.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => Isinput2Component),
      multi: true,
    },
  ],
})
export class Isinput2Component
  extends IscontrolComponent
  implements OnInit, AfterViewInit, ControlValueAccessor
{
  @Input() label: string;
  @Input() initialValue = '';
  @Input() frmGrp: FormGroup;
  @Input() master: number = null;
  @Input() fieldname: string = null;
  @Input() disabled: boolean = false;

  controlid: number;
  public formControl: FormControl;
  notActive = false;
  multiline = false;
  @ViewChild('.content input') inputel!: ElementRef;
  @ViewChild('.input') textareael!: ElementRef;

  constructor(
    comp: ComponentsService,
    public outlet: Outlet2Service,
    private compserv: ComponentsService,
    public store: Store,
    public NgZone: NgZone,
    public dialog: MatDialog,
    public el: ElementRef,
    public cd: ChangeDetectorRef
  ) {
    super(compserv, store, outlet, NgZone, dialog, el, cd);
  }

  private _value: string;
  formControlDirective: FormControlDirective;

  //formControl:FormControl = new FormControl('a')

  get value() {
    return this._value;
  }

  ngOnInit(): void {
    this.controlid = super.initControl(
      this.id.toString() + '.input',
      this.initialValue,
      'input'
    );
    this.formControl = super.getFormControl(this.controlid);

    this.formControlDirective = new FormControlDirective(
      null,
      null,
      [this],
      null
    );
    this.formControlDirective.form = this.formControl;
    const changes = {};
    changes['form'] = new SimpleChange(null, null, true);
    this.formControlDirective.ngOnChanges(changes);
  }

  ngAfterViewInit() {
    this.el.nativeElement.setAttribute('tabindex', 0);
    if (!this.multiline) {
      this.el.nativeElement.querySelector('input').focus();
    } else {
      this.el.nativeElement.querySelector('textarea').focus();
    }
  }

  afterformReady() {
    if (this.formControl) {
      if (this['valueChanges']) {
        this.formControl.valueChanges.subscribe((item) => {
          console.log(this['name'], 'value ; ', item);
          this['valueChanges'](item);
        });
      }
    }
  }

  controlnameValueChanges(newVal) {
    this.compserv.formcontrols.get(this.controlid.toString()).name = newVal;
  }

  // ControlValueAccessor implementation
  _onChange: (_: any) => void = (_) => {};

  _onTouched = () => {};

  writeValue(value: string): void {
    if (this.el.nativeElement.querySelector('textarea')) {
      if (this.multiline) {
        const normalizedValue = value == null ? '' : value;
        this.el.nativeElement.querySelector('textarea').value = normalizedValue;
      } else {
        this.el.nativeElement.querySelector('input').nativeElement.value =
          value;
      }
      this.cd.detectChanges();

      this._onChange(value);
      this._value = value;
    }
  }

  // setDisabledState(isDisabled: boolean): void {

  // }

  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }
}
