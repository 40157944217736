import {
  WebSocketConnected,
  SetISSESSIONID,
  SendMessage,
  SetWSPayload,
  //WebSocketDisconnected,
  SubscribeChannel,
  UnSubscribeChannel,
  WebSocketRegistered,
  IsConnectWebSocket,
  IsEditorConnectWebSocket,
  DisconnectIsWebsocket,
  Ping,
  CheckwebSocketStatus,
  ChannelRegistered,
} from './iscloud.actions';
import {
  ConnectWebSocket,
  SendWebSocketMessage,
  WebsocketMessageError,
  WebSocketDisconnected,
} from '@ngxs/websocket-plugin';
import {
  Action,
  Selector,
  State,
  StateContext,
  Store,
  Select,
} from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ConfigService, IsCloudService } from '..';
import { PathLocationStrategy } from '@angular/common';

export interface IsCloudStateModel {
  type: string;
  isessioncode: string;
  wsocksession: string;
  registered: boolean;
  payload: any;
  returnmessage: string;
  lastwebsocketerror: any;
  channels: string[];
  messages: any[];
  offlinemessages: any[];
  manualdisconnect: boolean;
  disconnected: boolean;
}

@State<IsCloudStateModel>({
  name: 'iscloudstate',
  defaults: {
    type: '',
    isessioncode: '',
    wsocksession: '',
    registered: false,
    payload: null,
    returnmessage: '',
    lastwebsocketerror: '',
    channels: [],
    messages: [],
    offlinemessages: [],
    manualdisconnect: false,
    disconnected: true,
  },
})
@Injectable()
export class IsCloudState {
  usewebsockets = true;

  constructor(private store: Store, private config: ConfigService) {}

  @Selector()
  public static lastwebsocketerror(state: IsCloudStateModel) {
    return state.lastwebsocketerror;
  }

  useWebsockets() {
    const indesign = this.store.selectSnapshot(
      (state) => state['outletstate'].indesigner
    );
    return !indesign && this.usewebsockets;
  }

  @Action(SetWSPayload)
  setWSPayload(
    { getState, patchState }: StateContext<IsCloudStateModel>,
    { payload }: SetWSPayload
  ) {
    const state = getState();
    patchState({
      payload: payload,
    });
  }

  @Action(IsConnectWebSocket)
  ConnectWebSocket(
    { getState, patchState }: StateContext<IsCloudStateModel>,
    { payload }: IsConnectWebSocket
  ) {
    const { isessioncode, disconnected } = getState();
    if (isessioncode && !disconnected) return;
    patchState({ payload: payload });
    if (this.useWebsockets()) {
      this.store.dispatch({
        type: '[iMessage]',
        payload: {
          name: 'logging',
          message: 'Connect Websocket ' + JSON.stringify(payload),
          time: this.getDbStrDateTime(0),
        },
      });

      if (document.location.protocol == 'http:') {
        console.log('connect websocket ', this.config.getDbUrl('ws'));
        this.store.dispatch(
          new ConnectWebSocket({ url: this.config.getDbUrl('ws') })
        );
      } else {
        console.log('connect websocket ', this.config.getDbUrl('wss'));
        this.store.dispatch(
          new ConnectWebSocket({ url: this.config.getDbUrl('wss') })
        );
      }
    }
  }

  @Action(IsEditorConnectWebSocket)
  EditorConnectWebSocket(
    { getState, patchState }: StateContext<IsCloudStateModel>,
    { payload }: IsEditorConnectWebSocket
  ) {
    patchState({ payload: payload });
    this.store.dispatch(
      new ConnectWebSocket({ url: this.config.getDbUrl('wseditor') })
    );
  }

  @Action(SubscribeChannel)
  SubscribeChannel(
    { getState, patchState }: StateContext<IsCloudStateModel>,
    { payload }: SubscribeChannel
  ) {
    const state = getState();
    if (this.useWebsockets()) {
      const channels = [...state.channels, payload];

      patchState({
        channels: channels,
      });
      console.log('Subscribe Channel ', state);
      if (state.registered) {
        console.log('Subscribe Channel 1 ', state);
        this.store.dispatch(
          new SendMessage('[CHANNEL] Register', { channel: payload }, '')
        );
      }
    }
  }

  @Action(ChannelRegistered)
  channelRegisterd(
    { getState, patchState }: StateContext<IsCloudStateModel>,
    { payload }: ChannelRegistered
  ) {
    const state = getState();
    if (state.offlinemessages.length > 0) {
      const offlinemessages = state.offlinemessages;
      this.store.dispatch(offlinemessages);
      patchState({
        offlinemessages: [],
      });
    }
  }

  @Action(UnSubscribeChannel)
  UnSubscribeChannel(
    { getState, patchState, dispatch }: StateContext<IsCloudStateModel>,
    { payload }: UnSubscribeChannel
  ) {
    const state = getState();
    if (this.useWebsockets()) {
      const idx = state.channels.findIndex((item) => {
        return item == payload;
      });
      const channels = state.channels.splice(idx, 1);

      patchState({
        channels: channels,
      });

      this.store.dispatch(
        new SendMessage('[CHANNEL] UnRegister', { channel: payload }, '')
      );
    }
  }

  getDbStrDateTime(offset: number) {
    const dt = new Date(); //
    return dt.toISOString();
  }

  @Action(WebSocketConnected)
  WebSocketConnected(
    { getState, patchState }: StateContext<IsCloudStateModel>,
    wscode: any
  ) {
    const state = getState();
    patchState({
      type: '[WSIC] RegisterISessionID',
      wsocksession: wscode.WSOCKSESSION,
      isessioncode: wscode.WSOCKSESSION,
      disconnected: false,
    });
    const state1 = getState();
    const issessioncode = this.store.selectSnapshot(
      (state) => state['isapplicationstate'].issessioncode
    );
    console.log('WebSocketConnected : ', issessioncode);
    // Full duplex connecthandshake server connection is made

    this.store.dispatch({
      type: '[iMessage]',
      payload: {
        name: 'logging',
        message: 'Websocket connected',
        time: this.getDbStrDateTime(0),
      },
    });

    this.store.dispatch(
      new SendWebSocketMessage({
        type: state1.type,
        //wsocksession: state1.wsocksession,
        isessioncode: state1.wsocksession,
        payload: state1.payload,
        returnmessage: '[WSIC] Websocket Registered',
      })
    );
  }

  @Action(WebSocketRegistered)
  WebSocketRegistered(
    { getState, patchState, dispatch }: StateContext<IsCloudStateModel>,
    wscode: any
  ) {
    const state = getState();

    const ping = () => {
      this.store.dispatch({
        type: '[WebSocket] Send Message',
        payload: 'ping',
      });
      console.log('ping');
      setTimeout(ping, state.payload.pingtimeout);
    };

    patchState({
      registered: true,
    });

    console.log('WebSocket Registered ', wscode);
    this.store.dispatch({
      type: '[iMessage]',
      payload: {
        name: 'logging',
        message: 'Websocket registered',
        time: this.getDbStrDateTime(0),
      },
    });
    if (state.payload.useclientping) {
      setTimeout(ping, state.payload.pingtimeout);
    }

    const state1 = getState();
    if (state1.channels.length > 0) {
      let messages: any[] = [];
      console.log('Channels ', wscode);
      state1.channels.forEach((item) =>
        messages.push(
          new SendMessage('[CHANNEL] Register', { channel: item }, '')
        )
      );
      messages = [...messages, ...state1.offlinemessages];
      console.log('register channels & send buffered messages ', messages);
      this.store.dispatch(messages);
    }
  }

  @Action(DisconnectIsWebsocket)
  disConnectWebSocket(
    { getState, patchState }: StateContext<IsCloudStateModel>,
    { manual }: DisconnectIsWebsocket
  ) {
    const state = getState();
    patchState({ manualdisconnect: manual });
    console.log('Web socket disconnected manually', manual);
    this.store.dispatch({ type: '[WebSocket] Disconnect' });
  }

  @Action(Ping)
  ping() {
    this.store.dispatch({
      type: '[iMessage]',
      payload: {
        name: 'logging',
        message: 'server ping',
        time: this.getDbStrDateTime(0),
      },
    });
    this.store.dispatch(new SendWebSocketMessage('pong'));
  }

  @Action(CheckwebSocketStatus)
  checkwebSocketStatus(
    { getState, patchState }: StateContext<IsCloudStateModel>,
    { payload }: CheckwebSocketStatus
  ) {
    const state = getState();
    // this.store.dispatch({
    //   type: '[iMessage]',
    //   payload: {
    //     name: 'CheckwebSocketStatus',
    //     time: this.getDbStrDateTime(0),
    //     message: 'payload ' + JSON.stringify({ ...state.payload, disconnected: state.disconnected }),
    //   },
    // });
    if (state.disconnected && window.navigator.onLine) {
      this.store.dispatch({
        type: '[iMessage]',
        payload: {
          name: 'logging',
          message: 'CheckwebSocketStatus Reconnect',
          time: this.getDbStrDateTime(0),
        },
      });
      if (payload) {
        const offlinemessages = [...state.offlinemessages, payload];
        patchState({
          offlinemessages: offlinemessages,
        });
      }
      this.store.dispatch(new IsConnectWebSocket(state.payload));
    }
  }

  @Action(WebSocketDisconnected)
  WebSocketDisconnected(
    { getState, patchState }: StateContext<IsCloudStateModel>,
    wscode: any
  ) {
    const state = getState();
    console.log('Web socket disconnected');
    //this.store.dispatch({});

    // if (document.location.protocol == 'http:') {
    //   console.log('connect websocket ', this.config.getDbUrl('ws'));
    //   this.store.dispatch(new ConnectWebSocket({ url: this.config.getDbUrl('ws') }));
    // } else {
    //   console.log('connect websocket ', this.config.getDbUrl('wss'));
    //   this.store.dispatch(new ConnectWebSocket({ url: this.config.getDbUrl('wss') }));
    // }

    if (!state.manualdisconnect) {
      this.store.dispatch({
        type: '[iMessage]',
        payload: {
          name: 'logging',
          message: 'websocket disconnected',
          sender: wscode,
          time: this.getDbStrDateTime(0),
        },
      });
      patchState({
        //isessioncode: wscode.WSOCKSESSION,
        disconnected: true,
      });
    } else {
      patchState({
        //isessioncode: wscode.WSOCKSESSION,
        channels: [],
      });
      this.store.dispatch({
        type: '[iMessage]',
        payload: {
          name: 'logging',
          message: 'websocket manualy disconnected',
          sender: wscode,
          time: this.getDbStrDateTime(0),
        },
      });
    }
  }

  @Action(WebsocketMessageError)
  WebSocketMessageError(
    { getState, patchState }: StateContext<IsCloudStateModel>,
    error: any
  ) {
    const state = getState();
    patchState({
      lastwebsocketerror: error,
    });
    this.store.dispatch({
      type: '[iMessage]',
      payload: {
        name: 'logging',
        message: 'websocket disconnected on error ',
        sender: error,
        time: this.getDbStrDateTime(0),
      },
    });
  }

  @Action(SendMessage)
  SendMessage(
    { getState, patchState, dispatch }: StateContext<IsCloudStateModel>,
    { message, payload, returnmessage, channel }: SendMessage
  ) {
    const state = getState();
    if (this.useWebsockets()) {
      let messages = state.messages;
      console.log(
        'SendMessage ',
        messages,
        ' state.registerd : ',
        state.registered
      );
      messages = [
        ...messages,
        {
          type: message,
          payload: payload,
          returnmessage: returnmessage,
          channel: channel,
        },
      ];

      patchState({
        messages: messages,
      });

      const state1 = getState();

      if (state1.isessioncode != '' && state1.registered) {
        messages = state1.messages;

        const sendlist: any[] = [];
        messages.forEach((message) => {
          message = { ...message, isessioncode: state1.isessioncode };
          sendlist.push(new SendWebSocketMessage(message));
          messages.shift();
        });

        console.log('SendMessage ', sendlist);
        this.store.dispatch(sendlist);

        patchState({
          messages: messages,
        });
      }
    }
  }

  @Action(SetISSESSIONID)
  SetISSESSIONID(
    { getState, patchState }: StateContext<IsCloudStateModel>,
    { code }: SetISSESSIONID
  ) {
    const state = getState();
    console.log('Set SessionCode ', code);
    patchState({
      isessioncode: code,
    });
  }
}
