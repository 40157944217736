import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgZone,
  OnInit,
  Renderer2,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ComponentsService, Outlet2Service } from '@ic-builder/is-base';
import { Store } from '@ngxs/store';
import { IscontrolComponent } from '../iscontrol/iscontrol.component';
import {
  daySelectEvent,
  Ismonth2Component,
} from './ismonth2/ismonth2.component';

@Component({
  selector: 'ic-builder-isyearplanner2',
  templateUrl: './isyearplanner2.component.html',
  styleUrls: ['./isyearplanner2.component.scss'],
})
export class Isyearplanner2Component
  extends IscontrolComponent
  implements OnInit, AfterViewInit
{
  year;

  months: Month[] = [
    { description: 'jan', number: 0, comp: null },
    { description: 'feb', number: 1, comp: null },
    { description: 'mrt', number: 2, comp: null },
    { description: 'apr', number: 3, comp: null },
    { description: 'mei', number: 4, comp: null },
    { description: 'jun', number: 5, comp: null },
    { description: 'jul', number: 6, comp: null },
    { description: 'aug', number: 7, comp: null },
    { description: 'sep', number: 8, comp: null },
    { description: 'okt', number: 9, comp: null },
    { description: 'nov', number: 10, comp: null },
    { description: 'dec', number: 11, comp: null },
  ];

  config = {
    ready_to_plan: [],
    planned: [],
    inprogress: [],
    finished: [],
    tomove: [],
    moved: [],
    orgplandate: [],
  };

  controlid: number;
  formControl: FormControl;
  monthControls: Ismonth2Component[] = [
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ];

  constructor(
    comp: ComponentsService,
    public outlet: Outlet2Service,
    public store: Store,
    public NgZone: NgZone,
    public dialog: MatDialog,
    public el: ElementRef,
    public cd: ChangeDetectorRef,
    private renderer: Renderer2
  ) {
    super(comp, store, outlet, NgZone, dialog, el, cd);
  }

  ngOnInit(): void {
    const currentDate = new Date();
    this.year = currentDate.getFullYear();
    if (this.formInput) {
      this.controlid = super.initControl(
        this.id.toString() + '.year',
        this.year,
        'year'
      );
      this.formControl = super.getFormControl(this.controlid);
    }
  }

  ngAfterViewInit(): void {
    if (this.formInput) {
      this.formControl.setValue(this.year);
    }
  }

  daysDetermined({ days, elements }) {
    console.log(days, elements);

    // this.config.planned.forEach((p) => {
    //   const [day, month] = p.split('-');

    //   const idx = days.findIndex((d) => d.day === Number(day));

    //   days[idx].mode.push('planned');
    //   elements[idx].classList.add('planned');
    // });
  }

  initmonth(ev) {
    this.months[ev.month.number].comp = ev;
  }

  setData(plantask: any) {
    this.emptyCalender();
    if (plantask) {
      plantask.forEach((p) => {
        //console.log('planday : ', p);
        const [day, month, year] = p.workorderstart.split('-');
        const m: Ismonth2Component = this.months[parseInt(month) - 1].comp;
        const d = m.el.nativeElement.querySelectorAll('isday2');
        const el: Element = d[parseInt(day) - 1];
        //console.log(el);
        switch (p.workorderstate) {
          case 0:
            el.classList.add('planned');
            this.config.planned.push(el);
            //dayInfo.mode.push('ready_to_plan');
            break;
          case 1:
            el.classList.add('inprogress');
            this.config.inprogress.push(el);
            //dayInfo.mode.push('ready_to_plan');
            break;
          case 2:
          case 3:
            el.classList.add('finished');
            this.config.finished.push(el);
            //dayInfo.mode.push('ready_to_plan');
            break;
        }

        if (p.draworgplandate) {
          const [oday, omonth, oyear] = p.planorgplandate.split('-');
          const m: Ismonth2Component = this.months[parseInt(omonth) - 1].comp;
          const d = m.el.nativeElement.querySelectorAll('isday2');
          const el: Element = d[parseInt(oday) - 1];
          const elsvg = el.querySelector('svg');
          this.renderer.setStyle(elsvg, 'display', 'block');
          this.config.orgplandate.push(elsvg);
        }
      });
    }
    this.cd.detectChanges();
  }

  emptyCalender() {
    this.config.planned.forEach((el) => el.classList.remove('planned'));
    this.config.inprogress.forEach((el) => el.classList.remove('inprogress'));
    this.config.finished.forEach((el) => el.classList.remove('finished'));
    this.config.orgplandate.forEach((el) =>
      this.renderer.removeStyle(el, 'display')
    );
  }

  daySelect({ ev, el, day, month, dayInfo, keepSelect }: daySelectEvent) {
    console.log(el);
    if (keepSelect) {
      el.classList.remove('ready_to_plan');
      this.config.ready_to_plan = this.config.ready_to_plan.filter(
        (p) => p !== `${day}-${month}`
      );
      dayInfo.mode.pop('ready_to_plan');
    } else {
      el.classList.add('ready_to_plan');
      this.config.ready_to_plan.push(`${day}-${month}`);
      dayInfo.mode.push('ready_to_plan');
    }

    // let mode, prevmode;

    // if (ev.ctrlKey) {
    //   mode = 'planned';
    // } else {
    //   mode = 'canceled'
    // }

    // console.log(month);

    // Object.keys(this.config).forEach((key) => {

    //   if (this.config[key].includes(`${day}-${month}`)) {
    //     el.classList.remove(key);
    //     this.config[key] = this.config[key].filter((p) => p !==`${day}-${month}`);
    //     prevmode = key;
    //   }

    //   return;

    // })

    // if (prevmode !== mode) {
    //   el.classList.add(mode);
    //   this.config[mode].push(`${day}-${month}`);
    // }
  }

  changeYear(delta: number) {
    this.year += delta;
    if (this.formInput) {
      this.formControl.setValue(this.year);
    }
    this.cd.detectChanges();
  }
}

export interface Month {
  description: string;
  number: number;
  comp: Ismonth2Component;
}
