import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  ElementRef,
} from '@angular/core';
import { Store } from '@ngxs/store';

@Component({
  selector: 'islabel',
  templateUrl: './islabel.component.html',
  styleUrls: ['./islabel.component.scss'],
})
export class IslabelComponent implements OnInit {
  config: any = {};
  @Input() label: string;
  split: string[];

  translation: any | null = null;

  constructor(
    private store: Store,
    private cd: ChangeDetectorRef,
    private el: ElementRef
  ) {}

  ngOnInit(): void {
    //if(typeof(this.label)=="string"){
    //  this.label = this.label.split("/")
    //}

    if (this.config.path) {
      this.split = this.config.path.path.split('.');
      //console.log(split)
      const regex = new RegExp('[0-9]');

      const res = this.store.select((state) => {
        let temp = state[this.config.path.state];

        for (let s of this.split) {
          let idx = null;

          if (regex.exec(s)) {
            const first = regex.exec(s).index - 1;

            let numIdx = regex.exec(s).index;
            idx = s[regex.exec(s).index];

            while (s[numIdx + 1] != ']') {
              numIdx += 1;
              idx += s[numIdx];
            }
            idx = Number(idx);
            s = s.slice(0, first);
          }
          temp = temp[s];

          if (idx !== null) {
            temp = temp[idx];
          }
        }
        return temp;
      });

      res.subscribe((a) => (this.config.label = a));
    }
    if (!this.label) {
      this.label = '';
    }
  }

  type() {
    return !(typeof this.label == 'string');
  }

  translate(lang){
    if (this.translation){
      this.label = this.translation[lang];
    }
  }

  /*labelValueChanges(newVal){
    this.label = newVal.split("/")
    this.cd.detectChanges()
    return true
  }*/
}
