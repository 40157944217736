import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { IsgridComponent } from '../isgrid/isgrid.component';
import { IsGridRegistry } from '../isgrid/isgrid.service';
import { IsyearplannerService } from './isyearplanner.service';
import { IscontrolComponent } from '../iscontrol/iscontrol.component';
import { ComponentsService, Outlet2Service } from '@ic-builder/is-base';
import { Store } from '@ngxs/store';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'isday',
  template: `
    {{ day ? (day.name ? day.name : day.weeknr ? day.weeknr : day.day) : null }}
  `,
  styles: [
    `
      :host {
        /* width: 30px; */
        display: flex;
        height: 20px;
        cursor: pointer;
        text-align: center;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
      }
      isday {
        flex-grow: 1;
      }
    `,
  ],
})
export class IsdayComponent implements OnInit, AfterViewInit {
  constructor(
    private renderer: Renderer2,
    private elRef: ElementRef,
    private yearService: IsyearplannerService,
    private compServ: ComponentsService
  ) {}

  @Input() day: any = {};
  @Output() onSelect: EventEmitter<any>;

  @Input() multiselect = true;
  @Input() plannerID: number;

  ngOnInit(): void {
    if (this.day) {
      this.renderer.setStyle(
        this.elRef.nativeElement,
        'background-color',
        this.yearService.stateColors[this.day.color]
      );
    }
    this.updateDay();
  }

  ngAfterViewInit() {
    if (this.day) {
      this.elRef.nativeElement.addEventListener('click', () => {
        this.yearService.onClick(this.day, this.plannerID, this.multiselect);
        //this.compServ.widgets.get(this.plannerID).location.nativeElement.focus()
      });

      this.elRef.nativeElement.addEventListener('mousedown', (event) => {
        this.yearService.onMouseDown(
          this.day,
          this.plannerID,
          event,
          this.multiselect
        );
        //this.compServ.widgets.get(this.plannerID).location.nativeElement.focus()
      });

      this.elRef.nativeElement.addEventListener('mouseup', (event) => {
        this.yearService.onMouseUp(this.day, this.plannerID, event);
        //this.compServ.widgets.get(this.plannerID).location.nativeElement.focus()
      });

      this.elRef.nativeElement.addEventListener('mouseenter', (event) => {
        this.yearService.onMouseEnter(
          this.day,
          this.plannerID,
          event,
          this.multiselect
        );
        //this.compServ.widgets.get(this.plannerID).location.nativeElement.focus()
      });
    }
  }

  updateDay() {
    if (this.day) {
      const selectedDates = this.yearService.statesMap.get(this.plannerID);
      const item = selectedDates.filter(
        (item) =>
          item.day == this.day.day &&
          item.month == this.day.month &&
          item.year == this.day.year
      );
      if (item[0]) {
        // this.renderer.setStyle(this.elRef.nativeElement, "background-color", "rgb(118, 115, 247)");
        this.renderer.setStyle(
          this.elRef.nativeElement,
          'background-color',
          'var(--100)'
        );
        this.renderer.setStyle(
          this.elRef.nativeElement,
          'color',
          'var(--textcolor)'
        );
      } else {
      }
    }
  }
}

@Component({
  selector: 'ismonth',
  template: `
    <div class="header" istag="monthheader">{{ monthString }} {{ year }}</div>
    <!--isgrid [columns]="columns" [data]="datamnd" [id]="-201" [datamode]="3"-->
    <div style="display: flex; flex-direction: row;">
      <div
        style="display: flex; flex-direction: column; flex-grow: 1"
        *ngFor="let col of cols"
      >
        <isday
          [style.background-color]="col === 0 || i === 0 ? '#eeeeee' : 'white'"
          [plannerID]="plannerID"
          [multiselect]="multiselect"
          *ngFor="let row of datamnd; let i = index"
          [day]="row[col]"
        >
        </isday>
      </div>
    </div>
  `,
  styles: [
    `
      .header {
        display: flex;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 25px;
        background-color: var(--300);
        color: var(--text-color);
      }
      .flex-container {
        display: flex;
        flex-wrap: wrap;
        user-select: none;
      }
    `,
  ],
})
export class IsmonthComponent implements OnInit, AfterViewInit {
  @Input() year: number = new Date().getFullYear();
  @Input() month: number = new Date().getMonth() + 1;
  @Input('multiselect') multiselect = true;
  @Input('plannerID') plannerID: number;

  months = [];
  monthString: string;

  amountOfDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  days = [];
  cols = [0, 1, 2, 3, 4, 5, 6, 7];

  columns: any;
  datamnd: any;
  data: any = {};

  constructor(
    private isgridservice: IsGridRegistry,
    private yearService: IsyearplannerService
  ) {}

  @ViewChild(IsgridComponent, { static: true }) public isgrid: IsgridComponent;
  @ViewChildren(IsdayComponent) isdays!: QueryList<IsdayComponent>;

  ngOnInit(): void {
    this.initMonths();
    this.initDays();
    this.monthString = this.months[this.month];
    this.columns = [];
    for (let i = 0; i < 8; i++) {
      this.columns[i] = {
        name: 'col' + i.toString(),
        label: i == 0 ? 'Wk' : this.days[i - 1],
        bodycomp: 'isday',
      };
    }

    this.datamnd = [];
    this.clear();

    this.fillMonth(this.month, this.year);
  }

  initMonths() {
    this.months = [];
    const d = new Date();
    d.setFullYear(new Date().getFullYear());
    d.setDate(1);
    for (let i = 0; i < 12; i++) {
      d.setMonth(i);
      this.months.push(d.toLocaleString('default', { month: 'long' }));
    }
  }

  initDays() {
    this.days = [];
    const d = new Date();
    d.setFullYear(2020);
    d.setMonth(0);
    d.setDate(6);
    const offset = 6;
    for (let i = 0; i < 7; i++) {
      d.setDate(i + offset);
      this.days.push(
        d.toLocaleString('default', { weekday: 'short' }).substr(0, 2)
      );
    }
  }

  fillMonth(month, year) {
    const date = new Date(year, month, 1);
    const weekday = date.getDay();
    const startx = (weekday ? weekday : 7) - 1;
    let amountDays = this.amountOfDays[month];
    if (year % 4 == 0 && month == 1) {
      amountDays = 29;
    }
    for (let i = 0; i < this.days.length; i++) {
      this.datamnd[0][i + 1] = { name: this.days[i] };
    }
    for (let i = 0; i < amountDays; i++) {
      const xpos = ((startx + i) % 7) + 1;
      const ypos = Math.floor((startx + i) / 7) + 1;
      //this.datamnd[ypos]['col' + xpos.toString()] = (i+1).toString();
      this.datamnd[ypos][xpos] = {
        day: i + 1,
        month: month,
        year: year,
        // , wknr: this.getWkNumber(new Date(year, month, (i+1)))
      };
      if (!this.datamnd[ypos][0]) {
        this.datamnd[ypos][0] = {
          weeknr: this.getWkNumber(new Date(year, month, i + 1)),
          year: year,
        };
      }
    }
  }

  getWeekNumber(date) {
    const weekOne = new Date(date.getFullYear(), 0, 3);
    const numberOfDays = Math.floor(
      (date.getTime() - weekOne.getTime()) / (24 * 60 * 60 * 1000)
    );
    return Math.ceil((date.getDay() + 1 + numberOfDays) / 7);
  }

  getWkNumber(date) {
    const d = new Date(date.getTime());
    d.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    d.setDate(d.getDate() + 3 - ((d.getDay() + 6) % 7));
    // January 4 is always in week 1.
    const week1 = new Date(d.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return (
      1 +
      Math.round(
        ((d.getTime() - week1.getTime()) / 86400000 -
          3 +
          ((week1.getDay() + 6) % 7)) /
          7
      )
    );
  }

  updateMonth(month, year) {
    this.clear();
    this.month = month;
    this.year = year;
    this.monthString = this.months[this.month];
    this.fillMonth(month, year);
    this.isdays.forEach((day) => {
      day.updateDay();
    });
  }

  clear() {
    for (let i = 0; i < 7; i++) {
      this.datamnd[i] = {};
      for (let j = 0; j < 7; j++) {
        this.datamnd[i]['col' + j.toString()] = '';
      }
    }
  }

  ngAfterViewInit() {
    // console.log(this.datamnd)
    // this.isgridservice.get(this.isgrid.id).rowheight = 30;
    // this.isgrid.loadData(this.datamnd);
  }
}

@Component({
  selector: 'isplanner',
  template: ``,
  styles: [``],
})
export class IsPlanner
  extends IscontrolComponent
  implements OnInit, AfterViewInit
{
  columns: any;
  controlidFrom: number;
  controlidUntil: number;
  controlidWeek: number;
  formControlfrom: FormControl;
  formControluntil: FormControl;
  formControlweek: FormControl;

  @ViewChildren(IsmonthComponent) ismonths!: QueryList<IsmonthComponent>;

  constructor(
    public cd: ChangeDetectorRef,
    protected yearService: IsyearplannerService,
    public elRef: ElementRef,
    public comp: ComponentsService,
    public store: Store,
    public outlet: Outlet2Service,
    public NgZone: NgZone,
    public dialog: MatDialog
  ) {
    super(comp, store, outlet, NgZone, dialog, elRef, cd);
  }

  ngOnInit(): void {
    //this.columns = [{name: 'col', bodycomp: 'form11264'}]
    if (this.id) {
      this.controlidFrom = super.initControl(
        this.id.toString() + '.from',
        null,
        'from'
      );
      this.controlidUntil = super.initControl(
        this.id.toString() + '.until',
        null,
        'until'
      );
      this.controlidWeek = super.initControl(
        this.id.toString() + '.week',
        null,
        'week'
      );

      this.formControlfrom = super.getFormControl(this.controlidFrom);
      this.formControluntil = super.getFormControl(this.controlidUntil);
      this.formControlweek = super.getFormControl(this.controlidWeek);
    }
  }

  convertObjectToDate(date: any) {
    if (!date) {
      return '';
    }
    return (
      +(date.month + 1).toString() +
      '/' +
      date.day.toString() +
      '/' +
      date.year.toString()
    );
  }

  ngAfterViewInit() {
    this.yearService.updateItems.subscribe((a) => {
      this.updateMonths();

      const control = this.yearService.statesMap.get(this.id);
      if (control) {
        if (control.length > 0) {
          const from = control[0];
          const until = control[control.length - 1];
          if (this.formControlfrom) {
            const oldval = {
              from: this.formControlfrom.value,
              until: this.formControluntil.value,
            };

            if (
              oldval.from != this.convertObjectToDate(from) ||
              oldval.until != this.convertObjectToDate(until)
            ) {
              this.formControlfrom.setValue(
                from.month + 1 + '/' + from.day + '/' + from.year
              );
              this.formControluntil.setValue(
                until.month + 1 + '/' + until.day + '/' + until.year
              );
              const dt = new Date(this.convertObjectToDate(from));
              this.formControlweek.setValue(
                from.year * 100 + this.yearService.getWeek(dt)
              );
            }
            const newval = {
              from: this.formControlfrom.value,
              until: this.formControluntil.value,
            };
            const event = new CustomEvent('periodchange', {
              bubbles: true,
              detail: { old: oldval, new: newval },
            });
            this.elRef.nativeElement.dispatchEvent(event);
          }
        }
      }

      /*
      if(this.yearService['_selectItems'].length>0){
        let from = this.yearService['_selectItems'][0];
        let until = this.yearService['_selectItems'][this.yearService['_selectItems'].length-1];
        this.formControlfrom.setValue((from.month+1)+'/'+from.day+'/'+from.year);
        this.formControluntil.setValue((until.month+1)+'/'+until.day+'/'+until.year);
      }*/
    });

    this.updateMonths();
  }

  controlnameValueChanges(newVal) {
    this.comp.formcontrols.get(this.controlidFrom.toString()).name =
      newVal + '.from';
    this.comp.formcontrols.get(this.controlidUntil.toString()).name =
      newVal + '.until';
    this.comp.formcontrols.get(this.controlidUntil.toString()).name =
      newVal + '.week';
  }

  resetSelected() {
    this.yearService.clearSelectItems();
    this.updateMonths();
  }

  updateMonths() {
    this.ismonths.forEach((ismonth) => {
      ismonth.updateMonth(ismonth.month, ismonth.year);
    });
    this.cd.detectChanges();
  }

  aanmaken() {
    this.yearService.aanmaken();
  }

  deleteMovedFrom() {
    this.yearService.deleteMovedFrom();
  }
}

@Component({
  selector: 'isvariablemonthplanner',
  template: `
    <!-- <div style="z-index: 10">
    <button (click)="prev()" class="button" style="float:left"><</button>
    <button (click)="next()" class="button" style="float:right">></button>
  </div> -->

    <div class="flex-container">
      <button (click)="prev()" class="button" style="left:0"><</button>
      <button (click)="next()" class="button" style="right:0">></button>
      <ismonth
        class="month"
        [plannerID]="id"
        [multiselect]="multiselect"
        *ngFor="let month of months"
        [month]="month"
        [year]="startYear"
      ></ismonth>
    </div>
  `,
  styles: [
    `
      .flex-container {
        display: flex;
        flex-wrap: wrap;
        user-select: none;
        position: relative;
      }
      .button {
        border-radius: var(--borderradius);
        box-shadow: var(--boxshadow);
        background-color: var(--300);
        color: var(--textcolor);
        border: var(--border);
        position: absolute;
        top: 0;
      }
      .button:hover {
        cursor: pointer;
      }
      .button:focus {
        outline: 0;
      }
      .month {
        flex-grow: 1;
      }
    `,
  ],
})
export class IsVariablemonthPlanner
  extends IsPlanner
  implements OnInit, AfterViewInit
{
  constructor(
    public cd: ChangeDetectorRef,
    protected yearService: IsyearplannerService,
    public elRef: ElementRef,
    public comp: ComponentsService,
    public store: Store,
    public outlet: Outlet2Service,
    public NgZone: NgZone,
    public dialog: MatDialog
  ) {
    super(cd, yearService, elRef, comp, store, outlet, NgZone, dialog);
  }

  @Input() monthsAmount = 12;
  @Input() startMonth = 0;
  @Input() startYear = 2020;
  @Input() multiselect = true;
  initmode: 'None' | 'CurrentDay' | 'CurrentWeek' | 'CurrentMonth' = 'None';

  months = [];

  currentMonth: number;
  currentYear: number;
  currentWeek: number;

  getNumberOfWeek(date): number {
    const day = new Date(date);
    const firstDayOfYear = new Date(day.getFullYear(), 0, 1);
    const pastDaysOfYear =
      (day.valueOf() - firstDayOfYear.valueOf()) / 86400000;
    return /*day.getFullYear()*100+*/ Math.ceil(
      (pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7
    );
  }

  getWkNumber(date) {
    const d = new Date(date.getTime());
    d.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    d.setDate(d.getDate() + 3 - ((d.getDay() + 6) % 7));
    // January 4 is always in week 1.
    const week1 = new Date(d.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return (
      1 +
      Math.round(
        ((d.getTime() - week1.getTime()) / 86400000 -
          3 +
          ((week1.getDay() + 6) % 7)) /
          7
      )
    );
  }

  ngOnInit(): void {
    if (typeof this.startYear === 'string') {
      this.startYear = parseInt(this.startYear);
    }
    super.ngOnInit();
    this.currentMonth = this.startMonth;
    this.currentYear = this.startYear;

    this.currentMonth = new Date().getMonth();
    this.startMonth = this.currentMonth;
    for (let i = 0; i < this.monthsAmount; i++) {
      this.months[i] = (i + this.startMonth) % 12;
    }

    this.currentWeek = this.getWkNumber(new Date());
    this.yearService.initState(this.id);

    this.yearService.setCurrentWeek();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.elRef.nativeElement.setAttribute('tabindex', -1);
    this.elRef.nativeElement.addEventListener('keydown', (event) => {
      // this.yearService.keydownEvent(event)
      this.yearService.onKeydown(this.id, event);
    });
    if (this.initmode == 'CurrentWeek') {
      this.yearService.onClick(
        { weeknr: this.currentWeek, year: this.currentYear },
        this.id,
        this.multiselect
      );
    } else if (this.initmode == 'CurrentDay') {
      this.yearService.onClick(
        {
          day: new Date().getDate(),
          month: new Date().getMonth(),
          year: this.currentYear,
        },
        this.id,
        this.multiselect
      );
    }
  }

  resetSelected() {
    super.resetSelected();
  }

  updateMonths() {
    this.ismonths.forEach((ismonth) => {
      ismonth.updateMonth(ismonth.month, ismonth.year);
    });
    this.cd.detectChanges();
  }

  aanmaken() {
    super.aanmaken();
  }

  deleteMovedFrom() {
    super.deleteMovedFrom();
  }

  next() {
    // this.currentYear += 1;
    this.ismonths.forEach((ismonth) => {
      const addYear = Math.floor(
        (ismonth.month + Number(this.monthsAmount)) / 12
      );
      ismonth.updateMonth(
        (ismonth.month + Number(this.monthsAmount)) % 12,
        ismonth.year + addYear
      );
    });
    this.cd.detectChanges();
    // this.updateMonths()
  }

  prev() {
    this.ismonths.forEach((ismonth) => {
      const subYear = Math.floor((ismonth.month - this.monthsAmount) / 12);
      ismonth.updateMonth(
        ismonth.month - this.monthsAmount >= 0
          ? ismonth.month - this.monthsAmount
          : 12 + (ismonth.month - this.monthsAmount),
        ismonth.year + subYear
      );
    });
    this.cd.detectChanges();
  }
}

@Component({
  selector: 'isyearplanner',
  templateUrl: './isyearplanner.component.html',
  styleUrls: ['./isyearplanner.component.scss'],
})
export class IsyearplannerComponent
  extends IsPlanner
  implements OnInit, AfterViewInit
{
  constructor(
    public cd: ChangeDetectorRef,
    protected yearService: IsyearplannerService,
    public elRef: ElementRef,
    public comp: ComponentsService,
    public store: Store,
    public outlet: Outlet2Service,
    public NgZone: NgZone,
    public dialog: MatDialog
  ) {
    super(cd, yearService, elRef, comp, store, outlet, NgZone, dialog);
  }

  /*@ViewChild(IsgridComponent, { static: true }) public isgrid: IsgridComponent;*/

  /*@ViewChildren(IsmonthComponent) ismonths!: QueryList<IsmonthComponent>;*/

  months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  year = 2020;
  month = 1;

  planner = true;

  //columns: any;
  /*
  data:any = [
    {maand: 1, label: 'Januari', dagen: 31},
    {maand: 2, label: 'Februari', dagen: 28},
    {maand: 3, label: 'Maart', dagen: 31},
    {maand: 4, label: 'April', dagen: 30},
    {maand: 5, label: 'Mei', dagen: 31},
    {maand: 6, label: 'Juni', dagen: 30},
    {maand: 7, label: 'Juli', dagen: 31},
    {maand: 8, label: 'Augustus', dagen: 31},
    {maand: 9, label: 'September', dagen: 30},
    {maand: 10, label: 'Oktober', dagen: 31},
    {maand: 11, label: 'November', dagen: 30},
    {maand: 12, label: 'December', dagen: 31}
  ];

  offsets = [
    {x: 0, y: 1, month: 0},
    {x: 8, y: 1, month: 1},
    {x: 16, y: 1, month: 2},
    {x: 24, y: 1, month: 3},
    {x: 0, y: 9, month: 4},
    {x: 8, y: 9, month: 5},
    {x: 16, y: 9, month: 6},
    {x: 24, y: 9, month: 7},
    {x: 0, y: 17, month: 8},
    {x: 8, y: 17, month: 9},
    {x: 16, y: 17, month: 10},
    {x: 24, y: 17, month: 11},
  ]
  days = ['Ma', 'Di', 'Wo', 'Do', 'Vr','Za', 'Zo']
  */
  ngOnInit(): void {
    // this.columns = [];
    // for (let i = 0; i < 4; i++) {
    //   this.columns[i] = {name: 'col' + i.toString(), bodycomp: 'ismonth', width: '30px'}
    // }

    //this.columns = [{name: 'col', bodycomp: 'form11264'}]
    super.ngOnInit();
  }

  resetSelected() {
    //this.yearService.clearSelectItems();
    //this.updateMonths()
    super.resetSelected();
  }

  updateMonths() {
    this.ismonths.forEach((ismonth) => {
      ismonth.updateMonth(ismonth.month, this.year);
    });
    this.cd.detectChanges();
  }

  nextMonth() {
    this.month += 1;
    this.updateMonths();
  }

  prevMonth() {
    this.month -= 1;
    this.updateMonths();
  }

  nextYear() {
    this.year += 1;
    this.updateMonths();
  }

  prevYear() {
    this.year -= 1;
    this.updateMonths();
  }

  aanmaken() {
    this.yearService.aanmaken();
  }

  deleteMovedFrom() {
    this.yearService.deleteMovedFrom();
  }

  /*drawWeekdays() {
    for (let i = 0; i < 3; i++) {
      for (let j = 0; j < 4; j++) {
        for (let day = 0; day < this.days.length; day++) {
          this.data[i*8]['col' + (j*8 + day).toString()] = this.days[day];
        }
      }
    }
  }*/

  ngAfterViewInit() {
    super.ngAfterViewInit();

    this.elRef.nativeElement.addEventListener('keydown', (event) => {
      this.yearService.keydownEvent(event);
    });

    /*this.yearService.updateItems.subscribe(a => {
      this.updateMonths();
      if(this.yearService['_selectItems'].length>0){
        let from = this.yearService['_selectItems'][0];
        let until = this.yearService['_selectItems'][this.yearService['_selectItems'].length-1];
        this.formControlfrom.setValue((from.month+1)+'/'+from.day+'/'+from.year);
        this.formControluntil.setValue((until.month+1)+'/'+until.day+'/'+until.year);
        console.log('daterange : ',from,' ',until)
      }
    })

    this.elRef.nativeElement.addEventListener('keydown', event => {
      this.yearService.keydownEvent(event)
    })

    this.updateMonths()
    */
  }
}
