import { IscontrolComponent } from '../iscontrol.component';
import { FormControl, FormGroup } from '@angular/forms';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { ComponentsService, Outlet2Service } from '@ic-builder/is-base';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'isselect',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent extends IscontrolComponent implements OnInit {
  @Input() options: any[];
  @Input() frmGrp: FormGroup;

  @Input() initialValue: string;

  @Input() selected: string;
  @Input() label: string;
  @Input() enum: string;
  @Input() valueOnly = false;
  @Input() objectList = true;
  @Input() disabled = false;
  //enumerationlist$: Observable<EnumerationItem[]>

  controlSelect = false;
  controlid: number;
  formControl: FormControl;

  constructor(
    public store: Store,
    comp: ComponentsService,
    private cmp: ComponentsService,
    public outlet: Outlet2Service,
    public NgZone: NgZone,
    public dialog: MatDialog,
    public el: ElementRef,
    public cd: ChangeDetectorRef
  ) {
    super(comp, store, outlet, NgZone, dialog, el, cd);
  }

  ngOnInit(): void {
    if (this.formInput) {
      this.controlid = super.initControl(
        this.id.toString() + '.select',
        this.initialValue,
        'select'
      );
      this.formControl = super.getFormControl(this.controlid);
    } else {
      this.formControl = new FormControl(this.initialValue);
    }
  }

  afterformReady() {
    if (this.formControl) {
      if (this['valueChanges']) {
        this.formControl.valueChanges.subscribe((item) => {
          this['valueChanges'](this.formControl.value);
        });
      }
    }
  }
}

export class SelectItem {
  value: string;
  description: string;
}
