import { ComponentsService } from '../components.service';
import {
  Component,
  OnInit,
  ViewChildren,
  ViewContainerRef,
  QueryList,
  NgZone,
  Input,
  ChangeDetectorRef,
  ViewChild,
  Renderer2,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { FormGroup, FormControl } from '@angular/forms';
import { map, debounceTime } from 'rxjs/operators';
//simport { UpdateProperty } from '../base.actions';
import { formbrowsestate, formeditstate } from '../symbols';
import { getLocaleDateTimeFormat } from '@angular/common';
import { LoadComponent, Outlet2Service, PreLoadComponents } from '@ic-builder/is-base';
import * as _ from 'lodash';
import { debounce, split } from 'lodash';
import { FormGroupListItem, IsFormGroup } from '../formgroupdef.model';
import { IsCloudService } from '@ic-builder/data-access-iscloud';
@Component({
  selector: 'isform',
  templateUrl: './isform2.component.html',
  styleUrls: ['./isform2.component.scss'],
})
export class Isform2Component implements OnInit, OnDestroy, IsFormGroup {
  sizes: Array<any> = [];
  _account: string;
  @Input('directon') direction = 'vertical';
  @Input('gutterSize') gutterSize: number;
  @Input('unit') unit: string;
  @Input('id') id: number;
  @Input('account') set account(val: string) {
    this._account = val;
  }

  //editmode:boolean=false;
  parentid = -999999;
  rootlevel = false;
  overlayRef: any;
  content: any;

  @ViewChildren('children', { read: ViewContainerRef })
  public children: QueryList<ViewContainerRef> | undefined;
  @ViewChild('noChildren', { read: ViewContainerRef })
  public nochildren: ViewContainerRef | undefined;

  FormControl = FormControl;

  formFormGroup: FormGroup = new FormGroup({});
  saveFormGroup: FormGroup = new FormGroup({});
  saveFormGroups: Array<FormGroupListItem> = [];

  dataFormGroup: FormGroup = new FormGroup({});

  data: any;
  definition: any = {};
  loadFunction = '';
  saveFunction = '';
  //browsemode: formbrowsestate;
  //operationmode: formeditstate;
  afterformgroupCreated: Function = null;
  beforeformDestroy: any = null;
  preloadcomponents:Array<number>=[];

  rxjsMap = map;
  rxjsDebounceTime = debounceTime;

  @Input('formgroupdef') formgroupdef: any = [];
  @Input() definitions: any = [];

  constructor(
    private cd: ChangeDetectorRef,
    private comp: ComponentsService,
    private store: Store,
    private renderer: Renderer2,
    private el: ElementRef,
    private outlet: Outlet2Service,
    private ic:IsCloudService,
    private ngZone:NgZone
  ) {}

  ngOnInit(): void {
    if (this.unit == null) {
      this.unit = 'percent';
    }
    
    // if (this.preloadcomponents.length>0){
    //   this.store.dispatch(new PreLoadComponents(this.preloadcomponents));
    // }
  }

  ngOnDestroy() {
    if (this.beforeformDestroy) {
      this.beforeformDestroy();
    }
  }

  dragEnd(ev) {
    const widget1 = this.comp.gci(this.sizes[ev.gutterNum - 1].id);
    const widget2 = this.comp.gci(this.sizes[ev.gutterNum].id);

    //console.log(widget1, widget2);

    // if (widget1.name == 'isform' || widget2.name == 'isform') {
    // }

    if (widget1.dragListener) {
      widget1.dragListener();
    }

    if (widget2.dragListener) {
      widget2.dragListener();
    }
  }

  getformfields(compid?: number) {
    const data = {};
    const fields = this.formgroupdef.filter((item) => {
      return item.controlname.split('.')[0] == compid;
    });
    fields.forEach((item) => {
      const val = this.comp.fc(item['key'])?.value;
      const paramname = item.controlname.split('.')[1];
      data[paramname] = val;
    });
    return data;
  }

  getformfieldsbyname(name: string) {
    const path = name.split('.');
    let i = 0;
    let c = this;
    while (i < path.length) {
      c = c[path[i]];
      i++;
    }
    return this.getformfields(c.id);
  }


  newDataGroup(items, load?: boolean) {
    this.dataFormGroup = new FormGroup({});

      items.forEach((item) => {
        const control = this.comp.formcontrols.get(item.controlname);
        if (this.data && control) {
          control.formcontrol.setValue(this.data[item.key]);
        }
        if (control) {
          this.dataFormGroup.addControl(item.key, control.formcontrol);
          control.formcontrol['iscontrol'] = control;
        }
      });

      this.definition = items;
      if (this.data) {
        this.setData(this.data);
      }
    

    // const enumitems = this.loadenumfields(items);
    // this.store.dispatch(new LoadEnumlist(enumitems));

    //const subscription = sub.subscribe((item) => {});

    if (!load) {
      this.definition = items;
      // this.store.dispatch({
      //   type: '[FormDefinition.UpdateWidgetPath]',
      //   payload: { id: this.id, path: ['config', 'definition'], value: items },
      // });
      // this.store.dispatch(new UpdateProperty({ compid: this.id, path: 'config.definition', value: this.definition }));
    }
  }

  onLoaded() {
    /* create here a form onloadevent to program runtime dependend properties*/
    /* populate this.data before fromgroup is created then on initial load right data wil be shown */
    if (Array.isArray(this.formgroupdef) && this.formgroupdef.length) {
      this.comp.newFormGroup(this, true);
    }

    if (Array.isArray(this.definitions) && this.definitions.length) this.newDataGroup(this.definitions)

    if (this.children.length > 0) {
      this.changeVal(0);
    }
  }

  setData(data) {
    this.data = data;
    if (this.definition && this.data && this.saveFormGroup) {
      Object.values(this.definition).map((val) => {
        const control = this.comp.formcontrols.get((val as any).controlname);
        if (control) {
          control.formcontrol.setValue(this.data[(val as any).key]);
          // if(control.formcontrol['owner']){
          //   (control.formcontrol['owner'] as any).setDesc(this.data[(val as any).key])
          // }
        }
      });
    }
    if (this.children.length > 0) {
      //this.changeVal(0)
    }
  }

  changeVal(idx) {
    this.cd.detectChanges();
  }

  /* use save to sent formGroup to server */
  save() {
    const val = this.formFormGroup.value;
    console.log(val);
    const json = {};
    this.definition.map((item) => {
      const splitted = item.key.split('.');
      let temp = json;
      for (let i = 0; i < splitted.length - 1; i++) {
        if (!temp[splitted[i]]) {
          temp[splitted[i]] = {};
        }
        temp = temp[splitted[i]];
      }
      temp[splitted[splitted.length - 1]] = val[item.controlname];
    });
    console.log(json);
  }

  closeform() {
    this.ngZone.run(() => {
      if (this.content.length > 0) {
        this.content.detach();
      }
      //this.resetcontrols();
      //this.contextRef.detach();
      this.overlayRef.detach();
    });
  }

  releaseform() {
    this.ngZone.run(() => {
      if (this.content.length > 0) {
        this.content.remove();
      }
      //this.resetcontrols();
      //this.contextRef.detach();
      this.overlayRef.detach();
    });
  }
}
