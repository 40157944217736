import { IsGridService, IsGridRegistry } from './../isgrid/isgrid.service';
import { IsColumnDef } from './../isgrid/isbody/iscolumn/iscolumn.component';
import { Overlay } from '@angular/cdk/overlay';
import { WidgetsRegistryService } from '@ic-builder/widgets-registry';
import { produce } from 'immer';
import {
  ComponentsService,
  EventMap,
  IIsComponent,
  IsMenuItem,
  LoadComponent,
  SetMenuItem,
  Outlet2Service,
} from '@ic-builder/is-base';
import { Select, Store } from '@ngxs/store';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import { SubSink } from 'subsink';
import { filter, take, tap } from 'rxjs/operators';
import { ChildActivationEnd } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'ispageformtest',
  templateUrl: './ispageformtest.component.html',
  styleUrls: ['./ispageformtest.component.scss'],
})
export class IspageformtestComponent implements OnInit, AfterViewInit, OnDestroy {
  private subs = new SubSink();
  public menucmp: number;
  currentpageidx: number = -2;

  constructor(
    private cd: ChangeDetectorRef,
    private el: ElementRef,
    private compService: ComponentsService,
    private dialog: MatDialog,
    private ngZone: NgZone,
    private store: Store,
    private outletservice: Outlet2Service,
    public registry: WidgetsRegistryService,
    public overlay: Overlay,
    private gridservice: IsGridRegistry,
    private renderer:Renderer2
  ) {}

  @ViewChild('addMenu', { read: TemplateRef }) addMenu: TemplateRef<any>;
  @ViewChild('deleteMenu', { read: TemplateRef }) deleteMenu: TemplateRef<any>;

  @ViewChild('outlet', { read: ViewContainerRef }) outlet: ViewContainerRef;
  @ViewChild('addbutton') addbutton: ElementRef<HTMLInputElement>;
  @ViewChild('delbutton') delbutton: ElementRef<HTMLInputElement>;

  @ViewChildren('#menucontainer', { read: ViewContainerRef }) menuitems: QueryList<ViewContainerRef>;
  @ViewChild('compiterator', { read: ViewContainerRef }) compiterator: ViewContainerRef;

  menuItems: any = [];
  menuItemsLocal: any = [];
  activeDialog: MatDialogRef<any>;

  enoughSpace = true;
  id: number;

  leftoffset:number=0;
  selectedElement:any;
  parentElement: Element;
  first = true;
  parentid: number;
  indesign: boolean;
  currentlyLoaded: any = null;

  newMenu: FormGroup = new FormGroup({});

  movedLeft = 0;
  designmode = true;
  deletetabs = true;

  formid: number = null;

  deleteColumns = [
    { name: 'id', label: 'ID', bodycomp: 'ischeckboxcel', width: '50px' }, //bodycompconfig:{gridid:-1100}
    { name: 'description', label: 'Omschrijving', width: 'calc(100% - 50px)' },
  ];
  deleteData = [];

  dragListener = () => {
    const menu = document.querySelectorAll('.pageformitem');
    const available = this.el.nativeElement.getBoundingClientRect().width;

    let totalWidth = 80;

    for (let i = 0; i < menu.length; i++) {
      totalWidth += menu[i].clientWidth;
    }

    this.enoughSpace = totalWidth < available;
    this.cd.detectChanges();
  };

  ngOnInit() {
    this.currentpageidx = -1;
    this.menuItemsLocal = [...this.menuItems];
    this.loadTabs();

  }

  translate(lang){
    // this.menuItems.forEach(item => {if (item.translation){
    //   item.label = item.translation[lang]}
    // })
    this.menuItems = this.menuItems
      .map((item) => {
        if (item.translation) {
          return { ...item, label: item.translation[lang] }
        }

        return item;
      })
  }

  handleResize(menu: any) {
    const menuwidth = menu.getBoundingClientRect().width;

    const menuitems = menu.querySelectorAll('.menu-item');

    let totalWidth = 0;

    //console.log(menuwidth,)

    for (let m of menuitems) {
      totalWidth += m.getBoundingClientRect().width;
    }

    if (menuwidth < totalWidth) {
      this.enoughSpace = false;
      //menu.style.overflow='hidden'
      this.cd.detectChanges();

      const leftarrow = this.el.nativeElement.querySelector('#left');
      leftarrow.classList.add('disabled');
           
    } else {
      this.enoughSpace = true;
      menu.style.overflow = '';
    }
    
    this.cd.detectChanges();
    if (this.selectedElement){
      this.leftoffset = this.el.nativeElement.getBoundingClientRect().left;
      this.setSelectedbar(this.selectedElement);  
    }
    
  }

  moveRight2(ev: MouseEvent) {
    const menu = this.el.nativeElement.querySelector('#menucontainer');

    const menuitems = menu.querySelectorAll('.menu-item');

    let toScroll = 0;

    const menuright = menu.getBoundingClientRect().right;
    const mostright = menuitems[menuitems.length - 1].getBoundingClientRect().right;

    if (this.movedLeft < menuitems.length && menuright < mostright) {
      this.movedLeft += 1;

      const leftarrow = this.el.nativeElement.querySelector('#left');
      leftarrow.classList.remove('disabled');
             
    }

    for (let i = 0; i < menuitems.length; i++) {
      if (i == this.movedLeft) {
        menu.scrollLeft = toScroll;
        break;
      }

      toScroll += menuitems[i].getBoundingClientRect().width;
    }

    if (menu.getBoundingClientRect().right >= menuitems[menuitems.length - 1].getBoundingClientRect().right) {
      const rightarrow = this.el.nativeElement.querySelector('#right');
      rightarrow.classList.add('disabled');
    }
  }

  moveLeft2(ev: MouseEvent) {
    const menu = this.el.nativeElement.querySelector('#menucontainer');

    const menuitems = menu.querySelectorAll('.menu-item');

    let toScroll = 0;

    if (this.movedLeft > 0) {
      this.movedLeft -= 1;
    }

    if (this.movedLeft == 0) {
      const leftarrow = this.el.nativeElement.querySelector('#left');
      leftarrow.classList.add('disabled');
    
    }

    for (let i = 0; i < menuitems.length; i++) {
      if (i == this.movedLeft) {
        menu.scrollLeft = toScroll;
        break;
      }

      toScroll += menuitems[i].getBoundingClientRect().width;
    }

    if (menu.getBoundingClientRect().right < menuitems[menuitems.length - 1].getBoundingClientRect().right) {
      const rightarrow = this.el.nativeElement.querySelector('#right');
      rightarrow.classList.remove('disabled');
    }
  }

  ngAfterViewInit(): void {
    
    
    this.indesign = this.store.selectSnapshot((state) => state['outletstate'].indesigner);
    // if (!this.indesign){
    //   this.renderer.setStyle(this.addbutton.nativeElement,'display','none'); 
    //   this.renderer.setStyle(this.delbutton.nativeElement,'display','none'); 
    // }
    
    if (this.formid) {
      const comp = this.el.nativeElement.querySelector('iscompiterator');
      
      comp.addEventListener('onCmpRendered', () => console.log('cmp rendered'));
    }

    const menu = this.el.nativeElement.querySelector('#menucontainer');
    new ResizeObserver((ev) => {
      this.handleResize(menu);
    }).observe(menu);

    this.dragListener();    
    
  }

  setSelectedbar(el:any){
    const compiterator =  this.el.nativeElement.querySelector('.compiterator');
    const selectedBar = this.el.nativeElement.querySelector('.selected-bar');
    const { width, left } = el.getBoundingClientRect();
    this.selectedElement = el;

    selectedBar.style.setProperty('left', `${left - this.leftoffset}px`);
    selectedBar.style.setProperty('width', `${width}px`);
  }

  cmpRendered({detail}) {
    
    const selectedBar = this.el.nativeElement.querySelector('.selected-bar');
    
    const firstEl = detail[0].location.nativeElement;
    // const { width, left } = firstEl.getBoundingClientRect();

    this.leftoffset = this.el.nativeElement.getBoundingClientRect().left;

    // selectedBar.style.setProperty('left', `${left - info.left}px`);
    // selectedBar.style.setProperty('width', `${width}px`);
    this.setSelectedbar(detail[0].location.nativeElement);

    for (let i = 0; i < detail.length; i+=1) {
      const el = detail[i].location.nativeElement;

      detail[i].location.nativeElement.addEventListener('click', (ev) => {
        this.changeView(this.menuItemsLocal[i], i);

        // const { width, left } = ev.currentTarget.getBoundingClientRect();

        // selectedBar.style.setProperty('left', `${left - info.left}px`);
        // selectedBar.style.setProperty('width', `${width}px`);
        this.setSelectedbar(ev.currentTarget);

      });  
    }

  }

  moveRight() {
    const content = document.querySelector('.content');

    const conleft = content.getBoundingClientRect().left;
    const conwidth = content.getBoundingClientRect().width;

    for (let i = 0; i < content.children.length; i++) {
      const left = content.children[i].getBoundingClientRect().left;
      const width = content.children[i].getBoundingClientRect().width;

      if (left + width > conleft + conwidth) {
        content.scrollLeft += left + width - conleft - conwidth + 1;
        break;
      }
    }

    //content.scrollLeft+=30;
  }

  moveLeft() {
    const content = document.querySelector('.content');

    //console.log(content.getBoundingClientRect().left)
    const conleft = content.getBoundingClientRect().left;

    for (let i = content.children.length - 1; i >= 0; i--) {
      const left = content.children[i].getBoundingClientRect().left;
      if (left - conleft < 0) {
        const left = content.children[i].getBoundingClientRect().left;

        content.scrollLeft -= -1 * (left - conleft);
        break;
      }
    }
  }

  addMenuItem() {
    this.ngZone.run(() => {
      this.activeDialog = this.dialog.open(this.addMenu);
    });
  }

  deleteMenuItem() {
    let count = 0;
    this.deleteData = this.menuItems.map((m) => {
      return { id: count++, description: m.label };
    });

    this.ngZone.run(() => {
      this.activeDialog = this.dialog.open(this.deleteMenu);
    });

    //let grid = this.gridservice.get(-1100)
    //grid.datasets.set('selected',[])

    this.cd.detectChanges();
  }

  close() {
    this.activeDialog.close();
  }

  save() {
    const formId = Number(this.compService.formcontrols.get('-1010.id')?.formcontrol?.value);
    const formName: FormControl = this.compService.formcontrols.get('-1011.input').formcontrol;
    const name = formName.value ?? this.compService.formcontrols.get('-1010.description').formcontrol.value;

    const newMenuItem = { label: name, id: formId, translation: {nl:name,fr:name,en:name} };
    this.menuItems.push(newMenuItem);
    this.menuItemsLocal.push({ ...newMenuItem });

    this.store.dispatch({
      type: '[FormDefinition.UpdateWidgetPath]',
      payload: {
        id: this.id,
        path: ['config', 'menuItems'],
        value: this.menuItems.map((m) => {
          return { label: m.label, id: m.id };
        }),
      },
    });

    const newId = this.outletservice.uniqueId;
    this.LoadComponent(formId, newId, this.menuItems.length - 1);
    this.close();
    //});
  }

  private createMenuParent() {
    // this.store.dispatch(new SetMenuItem({ add: comp, formid: 1000000, parentid: this.id }));
    // this.menuItems.push({ label: name, hostView: hostView });
  }

  LoadComponent(formId: number, tabId: number, index: number) {
    this.menuItemsLocal[index] = { ...this.menuItemsLocal[index] , shadowId: tabId };

    let cmpRef = this.compService.widgets.get(Number(formId) + 1);
    if (!cmpRef) {
      const dispatch$ = this.store.dispatch([new LoadComponent({ id: formId, name: '', isparent:this })]);
      this.subs.sink = dispatch$.subscribe((d) => {
        if (d){
        cmpRef = this.compService.widgets.get(Number(formId) + 1);
        if (cmpRef){  
        if (this.menuItems[index].config) {
          Object.entries(this.menuItems[index].config).map((val) => {
            cmpRef.instance[val[0]] = val[1];
          });
        }}

        this.createTab(formId, tabId, index);
        }
      });
    } else {
      this.createTab(formId, tabId, index);
    }
  }

  // @FIXME: tabs are not menu items
  private addmenuitem(payload: IIsComponent, render: boolean, parentid: number) {
    this.store.dispatch(new SetMenuItem({ add: payload, formid: 1_000_000, parentid: parentid }, render));
  }

  // @FIXME: this should create tabs, not labels and not menuitems
  async createTab(formId: number, tabId: number, index: number) {
    const sub = this.outletservice.getComponentById$(formId).pipe(
      filter((component) => Boolean(component)),
      take(1)
    );
    let first = true;

    const sub_real = sub.subscribe((d) => {
      if (!this.menuItems[index].reusable) {
        const cmpRef = this.compService.gcr(formId+1);
        if (cmpRef){
        cmpRef.instance['isparent'] = this;
        this.menuItemsLocal[index]['shadowId'] = cmpRef.instance.id;
        this.changeView(
          { shadowId: cmpRef.instance.id },
          index,
          true
        );
        }
      } else {
        this.outletservice
          .copyComponent(formId, tabId, this.id, () => {
            return this.menuItems[index].config;
          })
          .then((cmpRef) => {
            if (cmpRef) {
              this.compService.widgets.set(
                cmpRef.renderedtree.instance.id,
                cmpRef.renderedtree
              );
              cmpRef.renderedtree.instance['isparent'] = this;
              this.menuItemsLocal[index]['shadowId'] =
                cmpRef.renderedtree.instance.id;
              this.changeView(
                { shadowId: cmpRef.renderedtree.instance.id },
                index,
                true
              );
            }
          });
        if (!first) {
          sub_real?.unsubscribe();
        }
      }

      first = false;
      this.cd.detectChanges();
    });
  }

  reload(){
    if(!this.menuItemsLocal[this.currentpageidx]) return;
    this.changeView(this.menuItemsLocal[this.currentpageidx],this.currentpageidx,true);
  }

  onclose(){
    if ((this.outlet.length > 0) && (this.currentlyLoaded)) {
      this.childBeforeDetachDom(this.currentlyLoaded)      
      this.currentlyLoaded = null;
    }    
  }

  closetab(menu: any, idx: number) {
    this.changeView(menu, idx);
    if (this.outlet.length > 0) {
      this.outlet.remove(0);
    }
    const id = this.menuItemsLocal.indexOf(menu);
    this.menuItemsLocal.splice(id, 1);
    this.menuItems.splice(id, 1);
    this.cd.detectChanges();
    const menuitems = this.el.nativeElement.querySelectorAll('.menu-item');
    if (this.menuItemsLocal.length > 0) {
      idx = idx > 0 ? idx - 1 : idx;
      menuitems[idx]?.classList.add('selected');
      menu = this.menuItemsLocal[idx];
      const compRef = this.compService.widgets.get(menu.shadowId);
      if (compRef) {
        this.outlet.insert(compRef.hostView);
        this.childInsertInDom(compRef)
        this.currentlyLoaded = compRef
      }
      this.currentpageidx = idx;
    } else {
      this.currentpageidx = -1;
    }

    this.cd.detectChanges();
  }

  childInsertInDom(compRef){
    const insertInDom = (cmp: ComponentRef<any>) => {
      if (cmp.instance.afterInsertInDom) cmp.instance.afterInsertInDom();

      if (cmp['ischildren']) {
        cmp['ischildren'].forEach((c) => insertInDom(c));
      }
    } 
    insertInDom(compRef);
  }

  childBeforeDetachDom(compRef){
    const beforeDetachDom = (cmp: ComponentRef<any>) => {
      if (cmp.instance.beforeDetachDom) cmp.instance.beforeDetachDom();

      if (cmp['ischildren']) {
        cmp['ischildren'].forEach((c) => beforeDetachDom(c));
      }
    } 
    beforeDetachDom(compRef);
  }

  changeView(menu: any, idx: number, fromLoad: boolean = false) {
    if (this.currentpageidx != idx || fromLoad) {
      if (this.outlet.length > 0) {
        if (this.currentlyLoaded)
          this.childBeforeDetachDom(this.currentlyLoaded)
        this.outlet.detach(0);                
      }

      this.cd.detectChanges();

      const currentlySelected = this.el.nativeElement.querySelector('.selected');
      currentlySelected?.classList.remove('selected');

      if (!this.formid) {
        const menuitems = this.el.nativeElement.querySelectorAll('.menu-item');
        menuitems[idx].classList.add('selected');
      } else {
        const comp = this.el.nativeElement.querySelector('iscompiterator');
        console.log('iterator', comp.children);
      }

      if (!menu?.shadowId) {
        this.LoadComponent(menu.id, this.outletservice.uniqueId, idx);
      } else {
        //console.log('reached', menu);

        const compRef = this.compService.widgets.get(menu.shadowId);
        //debugger;
        if (compRef) {
          this.outlet.insert(compRef.hostView);
          compRef.instance['isparent'] = this;
          this.childInsertInDom(compRef);
          this.currentlyLoaded = compRef;
        }
      }
      this.cd.detectChanges();
      this.currentpageidx = idx;
    }
  }

  private loadTabs() {
    this.menuItems;

    if (this.menuItems.length > 0) {
      //const unique = this.outletservice.uniqueId;
      this.LoadComponent(this.menuItems[0].id, this.outletservice.uniqueId, 0);
      this.currentpageidx = -1;
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
