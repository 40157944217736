import { IscontrolComponent } from '../iscontrol.component';
import { FormControl, FormGroup } from '@angular/forms';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { EnumState } from '../../isenum/enum.state';
import { EnumerationItem } from '../../isenum/enum.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ComponentsService, Outlet2Service } from '@ic-builder/is-base';
import { LoadEnumlist } from '../../isenum/enum.action';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'isenumselect',
  templateUrl: './isenumselect.component.html',
  styleUrls: ['./isenumselect.component.scss'],
})
export class IsenumselectComponent extends IscontrolComponent implements OnInit, AfterViewInit {
  @Input() frmGrp: FormGroup;
  @Input() initialValue: string;
  @Input() label: string;
  @Input() enum: string;
  enumerationlist$: Observable<EnumerationItem[]>;

  controlid: number;
  @Input('control') formControl: FormControl;
  controlidID: number;

  @Input('account') account=null;

  // formControlID: FormControl;
  // formControlDesc: FormControl;

  constructor(
    comp: ComponentsService,
    public outlet: Outlet2Service,
    private compserv: ComponentsService,
    public store: Store,
    public NgZone: NgZone,
    public dialog: MatDialog,
    public el: ElementRef,
    public cd: ChangeDetectorRef
  ) {
    super(comp, store, outlet, NgZone, dialog, el, cd);
  }

  ngOnInit(): void {
    
    if (this.formInput) {
      this.controlid = super.initControl(this.id.toString() + '.enumid', this.initialValue, 'enumid');
      this.formControl = super.getFormControl(this.controlid);
    } else if(!this.formControl) {
      this.formControl = new FormControl(this.initialValue);
    } 

    if (this.enum) {
      this.store.dispatch(new LoadEnumlist(this.enum,null,this.account));
    }

    this.enumerationlist$ = this.store.select(EnumState.getenumlist).pipe(
      map((filterFn) => {
        return filterFn(this.enum);
      })
    );
  }

  afterformReady() {
    if (this.formControl) {
      if (this['valueChanges']) {
        this.formControl.valueChanges.subscribe((item) => {
          this['valueChanges'](this.formControl.value);
        });
      }
    }
  }

  enumValueChanges(val) {
    this.enumerationlist$ = this.store.select(EnumState.getenumlist).pipe(
      map((filterFn) => {
        return filterFn(val);
      })
    );
  }

  controlnameValueChanges(newVal) {
    this.compserv.formcontrols.get(this.controlid.toString()).name = newVal;
  }

  ngOnDestroy() {
    // super.destroyControlsNew([{controlname: this.controlname, initialValue: (this.initialValue ? this.initialValue : null), disabled: false}]);
  }

  ngAfterViewInit() {}
}
