import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TreeNodeChildrenComponent } from '@circlon/angular-tree-component';
import {
  ComponentsService,
  IIsComponent,
  Outlet2Service,
} from '@ic-builder/is-base';
import { Store } from '@ngxs/store';
import { values } from 'lodash';
import { IscontrolComponent } from '../iscontrol.component';

@Component({
  selector: 'ischeckboxpanel',
  templateUrl: './ischeckboxpanel.component.html',
  styleUrls: ['./ischeckboxpanel.component.scss'],
})
export class IscheckboxpanelComponent {
  #data: any = {};

  @ViewChild('children', { read: ViewContainerRef })
  public children: ViewContainerRef;

  key = 'params';
  set data(value) {
    this.#data = value;
    this.input = this.#data[this.key];
    if (this.childcomps.length == 0) {
      this.setInput(value[this.key]);
    }
  }
  get data() {
    return this.#data;
  }

  @Input() input = '';

  id: number;
  uniqueid: number = this.outletservice.uniqueId;
  mode: 'TIsRPRadioGroup' | 'TisRpCheckBox';
  frmGroup: FormGroup = null;
  childcomps: any[] = [];
  values: any[] = [];

  constructor(
    private outletservice: Outlet2Service,
    private store: Store,
    private el: ElementRef,
    private compserv: ComponentsService,
    private cd: ChangeDetectorRef
  ) {}

  async setInput(value) {
    const elements = value?.split(`\n`);
    const childs: any[] = [];
    this.childcomps = [];
    /* elements are push in array for async nature await calls are only allowed on top level */
    elements?.forEach((element) => {
      const vals = element.split(';');
      const namevalue = vals[0].split('=');
      switch (namevalue[1]) {
        case 'TIsRPRRadioGroup':
          childs.push(this.createRadio(namevalue[0], this.id));
          break;
        case 'TisRpCheckBox': {
          childs.push({
            name: namevalue[0],
            iiscomp: this.createCheckbox(
              namevalue[0],
              vals[1].includes('True'),
              this.id
            ),
          });

          const s = { name: namevalue[0], value: vals[1].includes('True') };
          this.values.push(s);
          break;
        }
        default:
          break;
      }
    });
    if (childs.length > 0) {
      for (const child of childs) {
        const compRef = await this.compserv.createWidget(
          child.iiscomp,
          true,
          null,
          null
        );
        this.childcomps.push({
          name: child.name,
          comp: (compRef.instance as any).controlid,
        });
        this.children.insert(compRef.hostView);
      }
      this.makeFormGroup();
      this.cd.detectChanges();
    }
  }

  makeFormGroup(load?: boolean) {
    this.frmGroup = new FormGroup({});
    this.childcomps.forEach((item) => {
      const control = this.compserv.formcontrols.get(item.comp);
      if (control) {
        const value = this.values.find((value) => value.name == item.name);
        control.formcontrol.setValue(value.value);
      }
      if (control) {
        this.frmGroup.addControl(item.controlname, control.formcontrol);
        control.formcontrol['iscontrol'] = control;
      }
    });
  }
  // click(ev){

  // }

  createCheckbox(label, checked, parentid) {
    const id = this.outletservice.uniqueId;
    return {
      parentid: parentid,
      id: id,
      name: 'checkbox',
      type: 'ischeckbox',
      flexsize: 1,
      flexorder: 1,
      config: {
        label: label,
        initialValue: checked,
        controlname: id.toString() + '.checkbox',
      },
      attribs: {
        style: { static: { '': { display: 'flex', 'align-items': 'center' } } },
      },
    };
  }

  createRadio(label, parentid) {
    const id = this.outletservice.uniqueId;
    return {
      parentid: parentid,
      id: id,
      name: 'radiobutton',
      type: 'isradio',
      flexsize: 1,
      flexorder: 1,
      config: {
        label: label,
      },
      attribs: { style: { static: { '': {} } } },
    };
  }
}
