import { IIsComponent } from './iis-component.interface';
import { LoadComponentMessage } from './load-component-message.interface';

export class AddWidget {
  static readonly type = '[AddWidget]';

  constructor(
    public payload: {
      id: number;
      info: any;
      parentid?: number;
      container?: string;
    }
  ) {}
}

export class ClearCanvas {
  static readonly type = '[ClearCanvas]';
}

export class ClearOutlet {
  static readonly type = '[ClearOutlet]';
  constructor(public payload: number) {}
}

export class DelComponent {
  static readonly type = '[DelComponent]';
  constructor(public payload: { id: number }) {}
}

export class DeleteWidgetOutlet {
  static readonly type = '[DeleteWidget]';

  constructor(public payload: number) {}
}

export class InsertComponent {
  static readonly type = '[SetNavigator]';
  constructor(public payload: IIsComponent) {}
}

export class LoadComponent {
  static readonly type = '[LoadComponent]';
  constructor(public payload: LoadComponentMessage) {}
}

export class NewComponent {
  static readonly type = '[NewComponent]';
  constructor(
    public payload: {
      id: number;
      type: string;
      parentid?: number;
      config?: any;
      widgets?: IIsComponent[];
    }
  ) {}
}

export class NewId {
  static readonly type = '[NewId]';
}

export class SetInDesigner {
  static readonly type = '[SetInDesigner]';
  constructor(public payload: boolean) {}
}


export class StoreAssetLocal {
  static readonly type = '[StoreAssetLocal]';
  constructor(public payload:{path:string, hash: string, content:string}){}
}

export class NewMenu {
  static readonly type = '[NewMenu]';

  constructor(
    public payload: { id: number; type: string; parentid?: number }
  ) {}
}

export class NewOutletId {
  static readonly type = '[NewOutletId]';
  constructor(public payload: number) {}
}

export class PreLoadComponents {
  static readonly type = '[PreLoadComponents]';
  constructor(public payload: Array<number>) {}
}

export class RefreshComponent {
  static readonly type = '[RefreshComponent]';
  constructor(public payload: number) {}
}

export class RefreshAllComponents {
  static readonly type = '[RefreshAllComponents]'; 
  constructor(public payload: number) {}
}

export class RemoveComponent {
  static readonly type = '[RemoveComponent]';
  constructor(public payload: { id: number }) {}
}

export class ExportToClipBoard {
  static readonly type = '[ExportToClipBoard]';
  constructor(public payload: IIsComponent) {}
}

export class PasteFromClipBoard {
  static readonly type = '[PasteFromClipBoard]';
  constructor(public payload: { afterpaste: any; pastename?: string }) {}
}

export class SaveTree {
  static readonly type = '[SaveTree]';
  constructor(public payload: IIsComponent) {}
}
/**
 * @deprecated replaced by saveTree
 */
export class SaveWizard {
  static readonly type = '[SaveWizard]';
  constructor(public payload: any[]) {}
}

export class SetComponent {
  static readonly type = '[SetComponent]';
  constructor(
    public payload: {
      componentlist: Array<IIsComponent>;
      outlet: number;
      id?: number;
      loadas?: number;
      baseid?: number;
    }
  ) {}
}

export class SetComponentSaveState {
  static readonly type = '[SetComponentSaveState]';

  constructor(public payload: number) {}
}

export class SetEventHandler {
  static readonly type = '[SetEventHandler]';
  constructor(
    public payload: { events: IIsComponent[]; formid: number; compid: number }
  ) {}
}

export class SetFormConnectionId {
  static readonly type = '[SetFormConnectionId]';
  constructor(public payload: { connectionid: number }) {}
}

export class SetMenuItem {
  static readonly type = '[SetMenuItem]';
  constructor(
    public payload: { add: IIsComponent; formid: number; parentid: number },
    public render: boolean = true
  ) {}
}

export class SetNavigator {
  static readonly type = '[SetNavigator]';
  constructor(public payload: number) {}
}

export class TrackLoading {
  static readonly type = '[TrackLoading]';

  constructor(public payload: number) {
    //console.trace(`%cClass: TrackLoading, Function: constructor(payload): `, 'color: black;', payload);
  }
}

export class UnLoadComponent {
  static readonly type = '[UnLoadComponent]';
  constructor(public payload: number) {}
}

export class UpdateProperty {
  static readonly type = '[UpdateProperty]';
  constructor(public payload: { compid: any; path: string; value: any }) {}
}

export class UpdateView {
  static readonly type = '[UpdateView]';
}

export class SaveReport {
  static readonly type = '[SaveReport]';
  constructor(public payload: any) {}
}

export class LoadFormInEditor {
  static readonly type = '[LoadFormInEditor]';
  constructor(public payload: any) {}
}

export class LoadMenuInEditor {
  static readonly type = '[LoadMenuInEditor]';
  constructor(public payload: IIsComponent) {}
}
